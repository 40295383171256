import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PageHeaderComponent} from "./page-header/page-header.component";
import {FooterPageComponent} from "./footer-page/footer-page.component";
import {ButtonModule} from "primeng/button";
import {PageDividedComponent} from './page-divided/page-divided.component';
import {ScrollPanelModule} from "primeng/scrollpanel";
import {RouterModule} from "@angular/router";

@NgModule({
  declarations: [
      PageHeaderComponent,
      FooterPageComponent,
      PageDividedComponent,
  ],
    imports: [      
        CommonModule,
        ButtonModule,
        ScrollPanelModule,
        RouterModule,
    ],
    exports: [
        PageHeaderComponent,
        FooterPageComponent,
        PageDividedComponent,
    ]
})
export class LayoutsModule { }
