import { Component, OnInit } from '@angular/core';
import {ConfirmationService, MessageService} from "primeng/api";
import {NetworkService} from "../../services/network.service";
import {Router} from "@angular/router";
import {URL_BASE, qtdLinhas} from "../../controller/staticValues";
import {DadosDefaultService} from "../../services/dados-default.service";

@Component({
  selector: 'app-cep',
  templateUrl: './cep.component.html',
  styleUrls: ['./cep.component.css']
})
export class CepComponent implements OnInit {

    public first: number = 0
    public top: number = qtdLinhas()
    qtdLinhas = qtdLinhas();
    public totalItens: number
    public lista: any[] = []
    public dadosFixos: any[] = []
    filtro = '';

    opcoesTable = [
        {label: 'Editar', icon: 'fa fa-plus', command: (e) => {
            this.router.navigate([`editar-cep/${e.Id}`])
        }},
        {label: 'Incluir', icon: 'fa fa-plus', command: (e) => {
            this.router.navigate(['incluir-cep'])
        }},
    ]

    constructor(private networkService: NetworkService, private router: Router, private messageService: MessageService, private dadosDefaultService: DadosDefaultService) {

    }

    ngOnInit() {
        this.networkService.exibirLoader.next(true);
        this.networkService.getSimples(URL_BASE, 'Cep?$top=0&$inlinecount=allpages').subscribe((v: any) => {
            this.totalItens = v['@xdata.count']
            this.networkService.getSimples(URL_BASE, 'Cep?$top=7').subscribe((value: any) => {
                this.lista = value.value
                this.dadosFixos = value.value
            })
        }).add(() => this.networkService.exibirLoader.next(false))
    }

    get usuarios() {
        return this.lista.filter(v => {
            if (v.Cep === null) {
                v.Cep = ''
            }
            return  v.Cep.toLowerCase().includes(this.filtro.toLowerCase())
        })
    }

    pagina(page){
        this.networkService.getSimples(URL_BASE, `Cep?$skip=${page.first}&$top=7`).subscribe((value: any) => {
            this.lista = value.value
            this.dadosFixos = value.value
        })
    }

    filtrarCep(){
        this.networkService.getSimples(URL_BASE, `Cep?$top=7&$filter=(contains(lower(Cep), '${this.filtro}') or contains(lower(Logradouro), '${this.filtro}') or contains(lower(Cidade), lower('${this.filtro}')) or contains(lower(Bairro), lower('${this.filtro}')))`).subscribe((v: any) => {
            this.lista = v.value
            this.dadosFixos = v.value
        })
    }

    incluir(){
        this.router.navigate(['incluir-cep'])
    }
}
