<div class="p-grid" style="display: flex;justify-content: center;">
    <div  style="margin-top: 30px;">
        <h1 style="margin: 0xp;font-size: 24px;">Editar Holding</h1>
        <div class="p-fluid p-formgrid p-grid container-grid" [formGroup]="form">
            <div class="p-field p-col-12 p-md-12">
                <label for="nome">NOME</label>
                <input id="nome" formControlName="Nome" type="text" pInputText> 
            </div>
            <div class="p-field p-col-12 p-md-6 container-button" style="margin-bottom: 0px;height: 40px;margin-top: 20px;">
                <button style="width: 150px;height: 40px;background-color: #ee736c;border: 0px;"  pButton pRipple type="button" label="Cancelar" class="p-button-danger" (click)="cancelar()"></button>
                <button style="width: 150px;margin-left: 15px;height: 40px;background-color: #91c02d;border: 0px;" pButton pRipple type="button" label="Salvar" class="p-button-success" (click)="processarFormulario()"></button>
            </div>
        </div>
    </div>
</div>