import {Component, OnDestroy, OnInit} from '@angular/core';
import {NetworkService} from "../../services/network.service";
import {Router} from "@angular/router";
import {
    VERSAO_SISTEMA,
    getUrlImplante,
    PERMISSOES,
    API_AUTH,
    URL_API_STORAGE_KEY,
    URL_BANCO_STORAGE_KEY,
    qtdLinhas,
    EMPRESA_STORAGE_KEY,
    TOKEN_STORAGE_KEY, TOKEN_TEMP_STORAGE_KEY, EMPRESA_COMPLETA_STORAGE_KEY, USUARIO_STORAGE_KEY
} from "../../controller/staticValues";
import {MessageService} from "primeng/api";
import {Subscription} from "rxjs";
import {DadosDefaultService} from "../../services/dados-default.service";
import {map} from "rxjs/operators";
import { ignoreElements } from 'rxjs-compat/operator/ignoreElements';

@Component({
  selector: 'app-lista-cliente-toqsys',
  templateUrl: './lista-cliente-toqsys.component.html',
  styleUrls: ['./lista-cliente-toqsys.component.css']
})
export class ListaClienteToqsysComponent implements OnInit{

  public first: number = 0
  public top: number = qtdLinhas()
  qtdLinhas = qtdLinhas();
  public totalItens: number
  public lista: any[] = []
  public dadosFixos: any[] = []
  usuario;

  modalVisible = false

  $subscription1: Subscription;
  $subscription2: Subscription;

  status: any[] = [
    {label: 'Inativo', value: "0"},
    {label: 'Ativo', value: "1"},
    {label: 'Expirado', value: "2"},
    {label: 'Bloqueado', value: "3"},
    {label: 'Cancelado', value: "4"}
  ];

  opcoesTable = [
      {label: 'Editar', icon: 'fa fa-plus', command: (e) => {
        this.router.navigate([`editar-cliente-toqsys/${e.ID}`]);
      }},
      {label: 'Bloquear', icon: 'fa fa-plus', command: (e) => {}},
      {label: 'Cancelar', icon: 'fa fa-plus', command: (e) => {}},
  ];

  filtro = '';
  rowsSearch = 7

  selectedRevenda;
  selectedRegimeTributario;
  selectedHolding;
  selectedContador;

  valueRevenda = undefined;
  valueContador = undefined;
  valueHolding = undefined;
  valueStatus = undefined;


  constructor(private networkService: NetworkService, private router: Router, private messageService: MessageService, private dadosDefaultService: DadosDefaultService) {

  }

  ngOnInit() {
    this.$subscription1 = this.dadosDefaultService.cliente().subscribe(values => {
      const delautvalue = { label: '-', value: undefined}
      this.selectedRevenda = values[0];
      this.selectedRevenda.unshift(delautvalue)
      this.selectedRegimeTributario = values[1];
      this.selectedRegimeTributario.unshift(delautvalue)
      this.selectedHolding = values[2];
      this.selectedHolding.unshift(delautvalue)
      this.selectedContador = values[3];
      this.status.unshift(delautvalue)

      this.networkService.getSimples(getUrlImplante(), `CLIENTE_TOQSYS?$inlinecount=allpages&$top=0`).pipe(map((res: Response) => res['@xdata.count'])).subscribe(items => {
        this.totalItens = Number(items)
        this.usuario = JSON.parse(sessionStorage.getItem(USUARIO_STORAGE_KEY))
        this.networkService.getSimples(getUrlImplante(), 'CLIENTE_TOQSYS?$top=7&$skip=0').subscribe((e: any )=> {
          this.lista = e.value
        })
      })
    })
  }

  filtrar(){

  }

  revenda(v){
    this.valueRevenda = v;
  }

  contador(v){
    this.valueContador = v;
  }

  holding(v){
    this.valueHolding = v;
  }

  statuss(v){
    this.valueStatus = v;
  }

  filtroLista() {
    this.lista = this.dadosFixos.filter(v => {
      return v.ID_HOLDING == this.valueHolding || v.STATUS == this.valueStatus || v.IdContador == this.valueContador || v.ID_REVENDA == this.valueRevenda
    })
    console.log(this.lista);
  }

  paginator(event){
    let filter = `&$filter=(`
    if(this.valueRevenda != undefined) filter = filter + `(ID_REVENDA eq ${this.valueRevenda}) and `
    if(this.valueContador != undefined) filter = filter + `(IdContador eq ${this.valueContador}) and `
    if(this.valueHolding != undefined) filter = filter + `(ID_HOLDING eq ${this.valueHolding}) and `
    if(this.valueStatus != undefined) filter = filter + `(STATUS eq '${this.valueStatus}') and `
    filter = filter + `contains(lower(RAZAO_SOCIAL), lower('${this.filtro}')) or contains(CPF_CNPJ, '${this.filtro}') or contains(lower(NOME_FANTASIA), lower('${this.filtro}')))`
    this.rowsSearch = event.rows
    this.networkService.getSimples(getUrlImplante(), `CLIENTE_TOQSYS?$top=${event.rows}&$skip=${event.first}${filter}`).subscribe((v: any) => {
      this.lista = v.value
    })
  }

  filter(){
    let filter = `&$filter=(`
    if(this.valueRevenda != undefined) filter = filter + `(ID_REVENDA eq ${this.valueRevenda}) and `
    if(this.valueContador != undefined) filter = filter + `(IdContador eq ${this.valueContador}) and `
    if(this.valueHolding != undefined) filter = filter + `(ID_HOLDING eq ${this.valueHolding}) and `
    if(this.valueStatus != undefined) filter = filter + `(STATUS eq '${this.valueStatus}') and `
    filter = filter + `contains(lower(RAZAO_SOCIAL), lower('${this.filtro}')) or contains(CPF_CNPJ, '${this.filtro}') or contains(lower(NOME_FANTASIA), lower('${this.filtro}')))`
    this.networkService.getSimples(getUrlImplante(), `CLIENTE_TOQSYS?$top=${this.rowsSearch}&$skip=0${filter}`).subscribe((v: any) => {
      this.lista = v.value
    })
  }

  empresas() {
      this.lista = this.dadosFixos.filter(v => {
          
          if (v.RAZAO_SOCIAL === null) {
              v.RAZAO_SOCIAL = ''
          }
          if (v.NOME_FANTASIA === null) {
              v.NOME_FANTASIA = ''
          }
          if (v.CPF_CNPJ === null) {
              v.CPF_CNPJ = ''
          }
          return  v.RAZAO_SOCIAL.toLowerCase().includes(this.filtro.toLowerCase()) || 
          v.NOME_FANTASIA.toLowerCase().includes(this.filtro.toLowerCase()) || 
          v.CPF_CNPJ.toString().includes(this.filtro)
      })
  }

  fecharModal() {
      this.modalVisible = false
  } 
}
