import {Component, Input, OnInit, EventEmitter, Output} from '@angular/core';
import {SelectItem} from "primeng/api";
import {FormBuilder, FormGroup} from "@angular/forms";
import {NetworkService} from "../../services/network.service";
import {DadosDefaultService} from "../../services/dados-default.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MessageService} from "primeng/api";
import {Formulario} from "../../controller/Formulario";
import {getUrlCad, EMPRESA_COMPLETA_STORAGE_KEY, URL_BASE} from "../../controller/staticValues";
import {Util} from "../../controller/Util";
import {Validators} from "@angular/forms";
import {Subscription} from 'rxjs';
import { RotinaToqWeb } from '../../model/rotina-toqweb.model';


@Component({
  selector: 'app-modal-update-produto',
  templateUrl: './modal-update-produto.component.html',
  styleUrls: ['./modal-update-produto.component.css']
})
export class ModalUpdateProdutoComponent implements OnInit {

    @Input() modalVisible = false;
    @Input() typeUpdate = ''
    @Input() listProduto = []
    @Input() selectGrupo = []
    @Input() selectMarca = []
    @Input() selectSubCategoria = []
    @Input() selectDepartamento = []
    @Output() closeModal = new EventEmitter()
    @Output() dadosSalvos = new EventEmitter()   

    entidade = 'Cadastrar Rotina';
    entObj = new RotinaToqWeb();
    form: FormGroup;

    grupo = 0
    marca = 0
    subCategoria = 0
    departamento = 0

    constructor(public networkService: NetworkService, public dadosDefault: DadosDefaultService, public router: Router, private route: ActivatedRoute, private fb: FormBuilder, public messageService: MessageService) {
        this.form = Formulario.createForm(this.entObj, this.fb, RotinaToqWeb.validacoes())
    }

    ngOnInit() {

    }

    salvar() {
        let inv = false
        if(this.form.invalid) {
            Object.keys(this.form.controls).forEach(c => {
                if(this.form.get(c).invalid) {
                    let v = RotinaToqWeb.validacoes().filter(x => x.campo === c)
                    this.messageService.add(Util.pushErrorMsg(`O campo ${v.length && v[0].nome ? v[0].nome : c} é obrigatorio`))
                    inv = true
                }
            })
            if (inv) return
        }

        const data = Formulario.parseForm(this.entObj, Object.assign(this.form.value), null, null, null, null, null, null);
        this.dadosSalvos.emit(true);
        this.closeModal.emit({Modal: false, Nome: 'Gabriel'});
        console.log(Object.assign({}, data));

        this.networkService.salvarPost(URL_BASE, 'RotinaToqweb', data).subscribe((v: any) => {
            let dados = {label: v.Nome, value: v.Id, modal: false}
            this.closeModal.emit(dados);
            this.messageService.add(Util.pushSuccessMsg("Rotina cadastrada"))
        })
    }

    updateGrupo(){        
        let body = {
            lista: this.listProduto.map(v => v.Id.toString()),
            value: this.grupo.toString()
        }
        this.networkService.exibirLoader.next(true)
        this.networkService.salvarPost(URL_BASE, 'Produtos/UpdateGrupo', body).subscribe(v => {
            this.messageService.add(Util.pushSuccessMsg('Atualização realizada com sucesso!'))
            this.fecharModal()
        }).add(this.dadosDefault.exibirLoader.next(false))
        
    }
    
    updateMarca(){
        let body = {
            lista: this.listProduto.map(v => v.Id.toString()),
            value: this.grupo.toString()
        }
        this.networkService.exibirLoader.next(true)
        this.networkService.salvarPost(URL_BASE, 'Produtos/UpdateMarca', body).subscribe(v => {
            this.messageService.add(Util.pushSuccessMsg('Atualização realizada com sucesso!'))
            this.fecharModal()
        }).add(this.dadosDefault.exibirLoader.next(false))
    }

    updateOutros(){
        let body = {
            lista: this.listProduto.map(v => v.Id.toString()),
            value: this.grupo.toString()
        }
        this.networkService.exibirLoader.next(true)
        this.networkService.salvarPost(URL_BASE, 'Produtos/UpdateProduto', body).subscribe(v => {
            this.messageService.add(Util.pushSuccessMsg('Atualização realizada com sucesso!'))
            this.fecharModal()
        }).add(this.dadosDefault.exibirLoader.next(false))
    }

    fecharModal() {
        this.closeModal.emit({modal: false})        
        this.form.reset()
        this.grupo = 0
        this.marca = 0
        this.departamento = 0
        this.subCategoria = 0
        // this.modalVisible = false
    }
}