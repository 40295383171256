<div class="content">
    <p-card [header]="'Produtos'">
        <div class="p-fluid p-formgrid p-grid" style="margin-bottom: 25px;">
            <app-painel [opened]="false" title="Filtros Avançados" class="p-col-12">
                <div class="p-fluid p-formgrid p-grid p-col-12">
                    <div class="p-col-3 p-fluid">
                        <app-select [label]="'Grupo'" [options]="selectGrupo" [(ngModel)]="grupo"></app-select>
                    </div>
                    <div class="p-col-3 p-fluid">
                        <app-select [label]="'Categoria'" [options]="selectCategoria" [(ngModel)]="categoria">
                        </app-select>
                    </div>
                    <div class="p-col-3 p-fluid">
                        <app-select [label]="'SubCategoria'" [options]="selectSubCategoria" [(ngModel)]="subCategoria">
                        </app-select>
                    </div>
                    <div class="p-col-3 p-fluid">
                        <app-select [label]="'Marca'" [options]="selectMarca" [(ngModel)]="marca"></app-select>
                    </div>
                    <div class="p-col-3">
                        <app-input [label]="'NCM'" [(ngModel)]="ncm"></app-input>
                    </div>
                    <div class="p-col-3">
                        <app-input [label]="'Cst PIS'" [(ngModel)]="cstPis"></app-input>
                    </div>
                    <div class="p-col-3">
                        <app-input [label]="'Cst IPI'" [(ngModel)]="cstIpi"></app-input>
                    </div>
                    <div class="p-col-3">
                        <app-input [label]="'Nat. PIS'" [(ngModel)]="natPis"></app-input>
                    </div>
                </div>
            </app-painel>
        </div>
        <div class="p-fluid p-formgrid p-grid" style="display: flex;justify-content: space-between;height: 70px;">
            <div class="p-col-2 p-fluid">
                <!-- <div class="p-inputgroup"> -->
                    <input #inputPesquisa (keypress)="pressionaEnter($event)" id="pesquisa" pInputText
                        [(ngModel)]="textoPesquisa"
                        placeholder="Digite parte do nome do produto, código de barras ou código interno">
                    <!-- <button style="width: 30px;" pButton type="button" icon="fa fa-search" (click)="carregarLista(0)"
                        class="p-button-warn"></button> -->
                <!-- </div> -->
            </div>
            <div class="p-col-2 p-fluid" style="margin-top: -17px;">
                <app-input [label]="'Inicia Com'" [(ngModel)]="iniciaCom"></app-input>
            </div>
            <div class="p-col-2 p-fluid" style="margin-top: -17px;">
                <app-input [label]="'Contém'" [(ngModel)]="contem"></app-input>
            </div>
            <div class="p-col-2 p-fluid" style="margin-top: -17px;">
                <app-input [label]="'Não Contém'" [(ngModel)]="naoContem"></app-input>
            </div>
            <div class="p-col-1">
                <button pButton icon="fa fa-search" (click)="carregarLista()"></button>
            </div>
        </div>
        <div class="p-fluid p-formgrid p-grid" style="margin-bottom: 20px; margin-right: 55px;">
            <div class="p-offset-6 p-col-2">
                <button pButton label="Atualizar Grupo" (click)="updateGrupo()"></button>
                <!-- <p-splitButton label="Atualizações" [model]="items"></p-splitButton> -->
            </div>
            <div class="p-col-2">
                <button pButton label="Atualizar Marca" (click)="updateMarca()"></button>
            </div>
            <div class="p-col-2">
                <button pButton label="Atualizar Outros" (click)="updateOutros()"></button>
            </div>
        </div>

        <p-table class="table" [paginator]="true" [(rows)]="qtdLinhas" [totalRecords]="totalItens" [value]="lista" [lazy]="true"
            (onLazyLoad)="lazyLoad($event)" [rowsPerPageOptions]="[100,200,300,400,500,1000]"
            [(selection)]="selected" dataKey="Id">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th style="width: 3%">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th [ngStyle]="{width: '16%'}"> Gtin </th>
                    <th [ngStyle]="{width: '35%'}"> Nome </th>
                    <th [ngStyle]="{width: '14%'}"> Grupo </th>
                    <th [ngStyle]="{width: '10%'}"> NCM </th>
                    <!-- <th [ngStyle]="{width: '10%'}"> Descrição do NCM </th> -->
                    <th [ngStyle]="{width: '10%'}"> CST Pis </th>
                    <th [ngStyle]="{width: '10%'}"> Taxa IPI </th>
                    <th [ngStyle]="{width: '8%'}"> CEST </th>
                    <th [ngStyle]="{width: '7%'}"> Ações </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr>
                    <td>
                        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                    </td>
                    <td>{{rowData.Gtin}}</td>
                    <td>{{rowData.Nome}}</td>
                    <td>{{rowData.Grupo}}</td>
                    <td>{{rowData.Ncm}}</td>
                    <!-- <td>{{rowData.DescricaoNcm}}</td> -->
                    <td>{{rowData.CstPis}}</td>
                    <td>{{rowData.TaxaIpi}}</td>
                    <td>{{rowData.Cest}}</td>
                    <td>
                        <app-opcoes-table [value]="rowData" [opcoes]="opcoesTable"></app-opcoes-table>
                    </td>
                </tr>
            </ng-template>
        </p-table>

    </p-card>
</div>


<p-confirmDialog header="Confirmacao" icon="pi pi-exclamation-triangle" width="425"></p-confirmDialog>

<div style="position: fixed; top: -200%">
    <button pButton type="button" label="" #produtocadastro></button>
</div>

<app-modal-update-produto [modalVisible]="modalUpdateProduto" [listProduto]="selected" [typeUpdate]="type"
    [selectGrupo]="selectGrupo" [selectMarca]="selectMarca" [selectSubCategoria]="selectSubCategoria" [selectDepartamento]="selectDepartamento"
    (closeModal)="modal()"></app-modal-update-produto>
