import { SubCategoriaListaComponent } from './pages/subcategoria/subcategoria-lista/subcategoria-lista.component';
import { DepartamentoListaComponent } from './pages/departamento/departamento-lista/departamento-lista.component';
import { CategoriaListaComponent } from './pages/categoria/categoria-lista/categoria-lista.component';
import { GrupoListaComponent } from './pages/grupo/grupo-lista/grupo-lista.component';
import { ProdutoListaComponent } from './pages/produto/produto-lista/produto-lista.component';
import { VersionamentoComponent } from './pages/versionamento/versionamento.component';
import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import { LoginComponent } from './auth/login/login.component';
import { IntermediariaComponent } from './auth/intermediaria/intermediaria.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginGuard } from './auth/service/login.guard';
import { ContratarComponent } from './auth/contratar/contratar.component';
import { ListaEmpresasComponent } from './pages/lista-empresas/lista-empresas.component';
import { ListaClienteToqsysComponent } from './pages/lista-cliente-toqsys/lista-cliente-toqsys.component';
import { EditarClienteToqsysComponent } from './pages/lista-cliente-toqsys/editar-cliente-toqsys/editar-cliente-toqsys.component';
import { RevendaComponent } from './pages/revenda/revenda.component';
import { EditarRevendaComponent } from './pages/revenda/editar-revenda/editar-revenda.component';
import { HoldingComponent } from './pages/holding/holding.component';
import { EditarHoldingComponent } from './pages/holding/editar-holding/editar-holding.component';
import { UsuarioWebComponent } from './pages/usuario-web/usuario-web.component';
import { EditarUsuarioWebComponent } from './pages/usuario-web/editar-usuario-web/editar-usuario-web.component';
import { AuthGuard } from './auth/service/auth-guard';
import { PainelPageComponent } from './pages/painel-page/painel-page.component';
import { CfopComponent } from './pages/cfop/cfop.component';
import { EditarCfopComponent } from './pages/cfop/editar-cfop/editar-cfop.component';
import { NcmComponent } from './pages/ncm/ncm.component';
import { EditarNcmComponent } from './pages/ncm/editar-ncm/editar-ncm.component';
import { MotivoDesoneracaoComponent } from './pages/motivo-desoneracao/motivo-desoneracao.component';
import { EditarMotivoDesoneracaoComponent } from './pages/motivo-desoneracao/editar-motivo-desoneracao/editar-motivo-desoneracao.component';
import { CestComponent } from './pages/cest/cest.component';
import { EditarCestComponent } from './pages/cest/editar-cest/editar-cest.component';
import { BancosComponent } from './pages/bancos/bancos.component';
import { EditarBancosComponent } from './pages/bancos/editar-bancos/editar-bancos.component';
import { CidadesComponent } from './pages/cidades/cidades.component';
import { CepComponent } from './pages/cep/cep.component';
import { EditarCepComponent } from './pages/cep/editar-cep/editar-cep.component';
import { CadastrarTutorialVideoComponent } from './pages/cadastrar-tutorial-video/cadastrar-tutorial-video.component';
import { ListaTutorialVideoComponent } from './pages/cadastrar-tutorial-video/lista-tutorial-video/lista-tutorial-video.component';
import { ContadorComponent } from './pages/contador/contador.component';
import { EditarContadorComponent } from './pages/contador/editar-contador/editar-contador.component';
import { VersionamentoCadastroComponent } from './pages/versionamento/versionamento-cadastro/versionamento-cadastro.component';
import { EmailListaComponent } from './pages/email/email-lista/email-lista.component';
import { EmailCadastroComponent } from './pages/email/email-cadastro/email-cadastro.component';


const routes: Routes = [

    {path: `login`, component: LoginComponent, canActivate: [LoginGuard]},
    {path: `selecao-empresa`, component: IntermediariaComponent},
    {path: `contratar`, component: ContratarComponent},
    {path: '',   redirectTo: '/login', pathMatch: 'full'},

    {
        path: '', component: PainelPageComponent, canActivate: [AuthGuard], children: [
            {path: 'home', component: HomeComponent},
            {path: 'lista-empresas', component: ListaEmpresasComponent},
            {path: 'lista-cliente-toqsys', component: ListaClienteToqsysComponent},
            {path: 'editar-cliente-toqsys/:id', component: EditarClienteToqsysComponent},
            {path: 'revenda', component: RevendaComponent},
            {path: 'incluir-revenda', component: EditarRevendaComponent},
            {path: 'editar-revenda/:id', component: EditarRevendaComponent},
            {path: 'holding', component: HoldingComponent},
            {path: 'incluir-holding', component: EditarHoldingComponent},
            {path: 'editar-holding/:id', component: EditarHoldingComponent},
            {path: 'usuario-web', component: UsuarioWebComponent},
            {path: 'incluir-usuario-web', component: EditarUsuarioWebComponent},
            {path: 'editar-usuario-web/:id', component: EditarUsuarioWebComponent},
            {path: 'cfop', component: CfopComponent},
            {path: 'editar-cfop/:id', component: EditarCfopComponent},
            {path: 'incluir-cfop', component: EditarCfopComponent},
            {path: 'ncm', component: NcmComponent},
            {path: 'editar-ncm/:id', component: EditarNcmComponent},
            {path: 'incluir-ncm', component: EditarNcmComponent},
            {path: 'motivo-desoneracao', component: MotivoDesoneracaoComponent},
            {path: 'editar-motivo-desoneracao/:id', component: EditarMotivoDesoneracaoComponent},
            {path: 'incluir-motivo-desoneracao', component: EditarMotivoDesoneracaoComponent},
            {path: 'cest', component: CestComponent},
            {path: 'editar-cest/:id', component: EditarCestComponent},
            {path: 'incluir-cest', component: EditarCestComponent},
            {path: 'bancos', component: BancosComponent},
            {path: 'editar-bancos/:id', component: EditarBancosComponent},
            {path: 'incluir-bancos', component: EditarBancosComponent},
            {path: 'cidades', component: CidadesComponent},
            {path: 'cep', component: CepComponent},
            {path: 'editar-cep/:id', component: EditarCepComponent},
            {path: 'incluir-cep', component: EditarCepComponent},
            {path: 'cadastrar-tutorial', component: CadastrarTutorialVideoComponent},
            {path: 'cadastrar-tutorial/:id', component: CadastrarTutorialVideoComponent},
            {path: 'lista-tutorial', component: ListaTutorialVideoComponent},
            {path: 'contador', component: ContadorComponent},
            {path: 'cadastrar-contador', component: EditarContadorComponent},
            {path: 'cadastrar-contador/:id', component: EditarContadorComponent},
            {path: 'versionamento', component: VersionamentoComponent},
            {path: 'versionamento/cadastro', component: VersionamentoCadastroComponent},
            {path: 'versionamento/cadastro/:id', component: VersionamentoCadastroComponent},
            {path: 'produtos', component: ProdutoListaComponent},
            {path: 'grupos', component: GrupoListaComponent},
            {path: 'categorias', component: CategoriaListaComponent},
            {path: 'departamentos', component: DepartamentoListaComponent},
            {path: 'subcategorias', component: SubCategoriaListaComponent},
            {path: 'email', component: EmailListaComponent},
            {path: 'email/cadastro', component: EmailCadastroComponent},
            {path: 'email/cadastro/:id', component: EmailCadastroComponent}

        ]
    }
]    

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
