import {Component, OnDestroy, OnInit} from '@angular/core';
import {NetworkService} from "../../../services/network.service";
import {ClienteToqsys} from '../../../model/cliente-toqsys.model';
import {ActivatedRoute ,Router} from "@angular/router";
import {getUrlImplante} from "../../../controller/staticValues";
import {MessageService} from "primeng/api";
import {Endereco, ErroCep, NgxViacepService} from "@brunoc/ngx-viacep";
import { Subscription } from 'rxjs'
import {Formulario} from "../../../controller/Formulario";
import {DadosDefaultService} from "../../../services/dados-default.service";
import { FormGroup, FormBuilder } from '@angular/forms';
import { Util } from 'src/app/controller/Util';

@Component({
  selector: 'app-editar-cliente-toqsys',
  templateUrl: './editar-cliente-toqsys.component.html',
  styleUrls: ['./editar-cliente-toqsys.component.css']
})
export class EditarClienteToqsysComponent implements OnInit{

  entObj = new ClienteToqsys()
  public lista: any[] = []
  form: FormGroup;
  usuario;
  id;

  $subscription1: Subscription;
  $subscription2: Subscription;

  status: any[] = [
    {label: 'Inativo', value: '0'},
    {label: 'Ativo', value: '1'},
    {label: 'Expirado', value: '2'},
    {label: 'Bloqueado', value: '3'},
    {label: 'Cancelado', value: '4'}
  ];

  selectedRevenda;
  selectedRegimeTributario;
  selectedHolding;
  selectedContador;
  selectedVersaoSistema;

  modalVisible = false

  stateOptions: any[];
  value1: string = "off";
  value2: string = "on";

    constructor(private fb: FormBuilder,public route: ActivatedRoute, private networkService: NetworkService, private router: Router, private messageService: MessageService, private dadosDefaultService: DadosDefaultService) {
      this.form = Formulario.createForm(this.entObj, this.fb, ClienteToqsys.validacoes())

      this.stateOptions = [{label: 'Não', value: 'N'}, {label: 'Sim', value: 'S'}];

      this.selectedVersaoSistema = [
        {value: '0', label: '0'},
        {value: '1', label: '1'},
        {value: '2', label: '2'},
        {value: '3', label: '3'},
        {value: '4', label: '4'},
        {value: '5', label: '5'},
        {value: '6', label: '6'},
      ]

    }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
          this.id = params.get('id');
          this.$subscription1 = this.dadosDefaultService.cliente().subscribe(values => {
            const delautvalue = { label: '-', value: null }            
            this.selectedRevenda = values[0];
            this.selectedRegimeTributario = values[1];
            this.selectedRegimeTributario.unshift(delautvalue)
            this.selectedHolding = values[2];
            this.selectedHolding.unshift(delautvalue)
            this.selectedContador = values[3];

            if (this.id) {
              this.$subscription2 = this.networkService.buscar('CLIENTE_TOQSYS', this.id, '',getUrlImplante()).subscribe(value => {                
                const data = Formulario.prepareValueToForm(this.entObj, value, ClienteToqsys.datas(), null, null, null)                
                Object.keys(data).forEach(key => this.form.controls[key].setValue(data[key]))
              })
            }
          })
        })
    }

    processarFormulario(){      
      let inv = false
      if(this.form.invalid) {
        Object.keys(this.form.controls).forEach(c => {
          if (this.form.get(c).invalid) {
            let v = ClienteToqsys.validacoes().filter(x => x.campo === c)
            this.messageService.add(Util.pushErrorMsg(`O campo ${v.length && v[0].nome ? v[0].nome : c} é obrigatorio`))
            inv = true
          }
        })
        if (inv) return
      }

      this.dadosDefaultService.exibirLoader.next(true)
      const data = Formulario.parseForm(this.entObj, Object.assign(this.form.value), null, ClienteToqsys.mascaras(), ClienteToqsys.datas(), null, null, ClienteToqsys.checkbox() );      
      this.networkService.salvarPost(getUrlImplante(), 'adm/Cliente', data).subscribe(value => {
        if(value) this.router.navigate(['lista-cliente-toqsys'])
      }).add(() => this.dadosDefaultService.exibirLoader.next(false))
    }

    cancelar(){
      this.router.navigate(['lista-cliente-toqsys']);
    }
}
