import {Component, OnDestroy, OnInit} from '@angular/core'
import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import {AuthService} from '../service/auth.service'
import {MessageService} from 'primeng/api'
import {Router} from '@angular/router'
import {NetworkService} from "../../services/network.service";
import {
    TOKEN_STORAGE_KEY,
    TOKEN_TEMP_STORAGE_KEY,
    USUARIO_STORAGE_KEY,
    URL_API_STORAGE_KEY,
    URL_BANCO_STORAGE_KEY
} from "../../controller/staticValues";
import {Subscription} from "rxjs";
import {DadosDefaultService} from "../../services/dados-default.service";
import { Util } from 'src/app/controller/Util'

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

    public form: FormGroup
    private loginUser = {}

    modalExperimentarVisible = false
    modalAtivarVisible = false

    $usuarioLogadoSubscribe: Subscription;
    $primeiraEtapaSubscription: Subscription;
    $segundaEtapaSubscription: Subscription;
    $terceiraEtapaSubscription: Subscription;

    constructor(private authService: AuthService, private fb: FormBuilder, private router: Router, private networkService: NetworkService, private messageService: MessageService, private dadosDefault: DadosDefaultService) {
        this.form = fb.group({
            User: ['', Validators.compose([Validators.required])],
            Password: ['', Validators.compose([Validators.required])],
        })
    }

    logarUsuario() {
            this.primeiraEtapa()
    }

    primeiraEtapa() {
        sessionStorage.clear()
        this.loginUser = {User: this.form.controls['User'].value, Password: this.form.controls['Password'].value}
        this.$primeiraEtapaSubscription = this.authService.primeiraAuthenticacao(this.loginUser).subscribe(res1 => {
            sessionStorage.setItem(TOKEN_TEMP_STORAGE_KEY, res1['value'])
            this.$segundaEtapaSubscription = this.authService.segundaAuthenticacao(this.loginUser).subscribe((res2: any) => {
                if(res2.TIPO !== "S"){
                    this.messageService.add(Util.pushErrorMsg("Esta conta não tem permissão para acesso."))
                    return;
                }
                sessionStorage.setItem(TOKEN_STORAGE_KEY, res1['value'])
                sessionStorage.setItem(USUARIO_STORAGE_KEY,JSON.stringify({...res2, SENHA: null}))
                sessionStorage.setItem(URL_API_STORAGE_KEY, 'https://api.toqweb.com.br:2004/');
                sessionStorage.setItem(URL_BANCO_STORAGE_KEY, 'ToqWeb');
                setTimeout(()=> {
                    this.authService.usuarioLogado.next(true)
                    this.router.navigate(['home'], {replaceUrl: true})
                }, 500)
            })
        })
    }

    ngOnInit(): void {

    }

    abrirModalCadastro() {
        this.router.navigate(['/contratar'])
    }

    abrirModalExperimentar() {
        this.modalExperimentarVisible = true
    }

    fecharModalExperimentar() {
        this.modalExperimentarVisible = false
    }

    abrirModalAtivar() {
        this.modalAtivarVisible = true
    }

    fecharModalAtivar() {
        this.modalAtivarVisible = false
    }

    contratoEfetuado(e) {
        this.form.controls['User'].setValue(e.Usuario)
        this.form.controls['Password'].setValue(e.Senha)
        this.primeiraEtapa()
    }

    ngOnDestroy(): void {
        if(this.$primeiraEtapaSubscription) this.$primeiraEtapaSubscription.unsubscribe()
        if(this.$segundaEtapaSubscription) this.$segundaEtapaSubscription.unsubscribe()
        if(this.$terceiraEtapaSubscription) this.$terceiraEtapaSubscription.unsubscribe()
    }
}
