import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-painel-page',
    templateUrl: './painel-page.component.html',
    styleUrls: ['./painel-page.component.css']
})
export class PainelPageComponent implements OnInit {

    constructor() {

    }

    ngOnInit() {
        
    }

}