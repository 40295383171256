import { SubCategoriaListaComponent } from './pages/subcategoria/subcategoria-lista/subcategoria-lista.component';
import { DepartamentoListaComponent } from './pages/departamento/departamento-lista/departamento-lista.component';
import { CategoriaListaComponent } from './pages/categoria/categoria-lista/categoria-lista.component';
import { GrupoListaComponent } from './pages/grupo/grupo-lista/grupo-lista.component';
import { ProdutoListaComponent } from './pages/produto/produto-lista/produto-lista.component';
import { ModalUploadArquivoComponent } from './pages/versionamento/versionamento-cadastro/modal-upload-arquivo/modal-upload-arquivo.component';
import { InputDoubleComponent } from './components/input-double/input-double.component';
import { modalVersionamentoCadastroComponent } from './modais/modal-versionamento-cadastro/modal-versionamento-cadastro.component';
import { InputComponent } from './components/input/input.component';
import { ComponentsModule } from './components/components.module';
import { VersionamentoComponent } from './pages/versionamento/versionamento.component';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import { ProgressSpinnerModule } from "primeng/progressspinner";
import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
import {CarouselModule} from 'primeng/carousel';
import {ChartModule} from 'primeng/chart';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipsModule} from 'primeng/chips';
import {CodeHighlighterModule} from 'primeng/codehighlighter';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ColorPickerModule} from 'primeng/colorpicker';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {FieldsetModule} from 'primeng/fieldset';
import {FileUploadModule} from 'primeng/fileupload';
import {FullCalendarModule} from 'primeng/fullcalendar';
import {GalleriaModule} from 'primeng/galleria';
import {InplaceModule} from 'primeng/inplace';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputMaskModule} from 'primeng/inputmask';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {LightboxModule} from 'primeng/lightbox';
import {ListboxModule} from 'primeng/listbox';
import {MegaMenuModule} from 'primeng/megamenu';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {MultiSelectModule} from 'primeng/multiselect';
import {OrderListModule} from 'primeng/orderlist';
import {OrganizationChartModule} from 'primeng/organizationchart';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PaginatorModule} from 'primeng/paginator';
import {PanelModule} from 'primeng/panel';
import {PanelMenuModule} from 'primeng/panelmenu';
import {PasswordModule} from 'primeng/password';
import {PickListModule} from 'primeng/picklist';
import {ProgressBarModule} from 'primeng/progressbar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {RatingModule} from 'primeng/rating';
import {RippleModule} from 'primeng/ripple';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SidebarModule} from 'primeng/sidebar';
import {SlideMenuModule} from 'primeng/slidemenu';
import {SliderModule} from 'primeng/slider';
import {SplitButtonModule} from 'primeng/splitbutton';
import {StepsModule} from 'primeng/steps';
import {TabMenuModule} from 'primeng/tabmenu';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {TerminalModule} from 'primeng/terminal';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {ToastModule} from 'primeng/toast';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {TreeTableModule} from 'primeng/treetable';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import {ConfirmationService, MessageService} from "primeng/api";
import { OpcoesTableControlDirective } from './components/opcoes-table/opcoes-table-control.directive'
import { OpcoesTableComponent } from './components/opcoes-table/opcoes-table.component'
import { HomeComponent } from './pages/home/home.component';
import { ListaEmpresasComponent } from './pages/lista-empresas/lista-empresas.component';
import { ListaClienteToqsysComponent } from './pages/lista-cliente-toqsys/lista-cliente-toqsys.component';
import { EditarClienteToqsysComponent } from './pages/lista-cliente-toqsys/editar-cliente-toqsys/editar-cliente-toqsys.component';
import { SelectComponent } from './components/select/select.component';
import { DateComponent } from './components/date/date.component';
import { InputTelefoneComponent } from './components/input-telefone/input-telefone.component';
import { InputCpfCnpjComponent } from './components/input-cpf-cnpj/input-cpf-cnpj.component';
import { PipesModule } from './pipes/pipes.module';
import { LayoutsModule } from './layout/layouts.module';
import { InputMoneyComponent } from './components/input-money/input-money.component';
import { PainelComponent } from './components/painel/painel.component';
import { ToogleComponent } from './components/toogle/toogle.component';

// Application Components
import {AppComponent} from './app.component';
import {AppMenuComponent} from './app.menu.component';
import {AppMenuitemComponent} from './app.menuitem.component';
import {AppTopBarComponent} from './app.topbar.component';
import {AppFooterComponent} from './app.footer.component';
import { LoginComponent } from './auth/login/login.component';
import { IntermediariaComponent } from './auth/intermediaria/intermediaria.component';
import { ContratarComponent } from './auth/contratar/contratar.component';
import { RevendaComponent } from './pages/revenda/revenda.component';
import { EditarRevendaComponent } from './pages/revenda/editar-revenda/editar-revenda.component';
import { HoldingComponent } from './pages/holding/holding.component';
import { EditarHoldingComponent } from './pages/holding/editar-holding/editar-holding.component';
import { UsuarioWebComponent } from './pages/usuario-web/usuario-web.component';
import { EditarUsuarioWebComponent } from './pages/usuario-web/editar-usuario-web/editar-usuario-web.component';
import { PainelPageComponent } from './pages/painel-page/painel-page.component';
import { CfopComponent } from './pages/cfop/cfop.component';
import { EditarCfopComponent } from './pages/cfop/editar-cfop/editar-cfop.component';
import { NcmComponent } from './pages/ncm/ncm.component';
import { EditarNcmComponent } from './pages/ncm/editar-ncm/editar-ncm.component';
import { MotivoDesoneracaoComponent } from './pages/motivo-desoneracao/motivo-desoneracao.component';
import { EditarMotivoDesoneracaoComponent } from './pages/motivo-desoneracao/editar-motivo-desoneracao/editar-motivo-desoneracao.component';
import { CestComponent } from './pages/cest/cest.component';
import { InputIconComponent } from './components/input-icon/input-icon.component'
import { EditarCestComponent } from './pages/cest/editar-cest/editar-cest.component';
import { BancosComponent } from './pages/bancos/bancos.component';
import { EditarBancosComponent } from './pages/bancos/editar-bancos/editar-bancos.component';
import { CidadesComponent } from './pages/cidades/cidades.component';
import { CepComponent } from './pages/cep/cep.component';
import { EditarCepComponent } from './pages/cep/editar-cep/editar-cep.component';
import { CadastrarTutorialVideoComponent } from './pages/cadastrar-tutorial-video/cadastrar-tutorial-video.component';
import {DiretivasModule} from "./diretivas/diretivas.module";
import {ModaisModule} from "./modais/modais.module";
import {ModalRotinaCadastroComponent} from "./modais/modal-rotina-cadastro/modal-rotina-cadastro.component"
import { CommonModule } from '@angular/common';
import { ListaTutorialVideoComponent } from './pages/cadastrar-tutorial-video/lista-tutorial-video/lista-tutorial-video.component'
import { ContadorComponent } from './pages/contador/contador.component';
import { EditarContadorComponent } from './pages/contador/editar-contador/editar-contador.component';

// Application services
import {NgxViacepModule} from "@brunoc/ngx-viacep";
import {MenuService} from './app.menu.service';
import { AuthInterceptor } from './auth/service/auth-interceptor';
import { ErrorInterceptor } from './auth/service/error-interceptor';
import { VersionamentoCadastroComponent } from './pages/versionamento/versionamento-cadastro/versionamento-cadastro.component';
import { TemaModule } from './tema.module';
import { ModalUpdateProdutoComponent } from './modais/modal-update-produto/modal-update-produto.component';
import { EmailCadastroComponent } from './pages/email/email-cadastro/email-cadastro.component';
import { EmailListaComponent } from './pages/email/email-lista/email-lista.component';

import {EditorModule} from 'primeng/editor';
import { SelectButtonComponent } from './components/select-button/select-button.component';

@NgModule({
    imports: [
        // ChartsModule,
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        NgxViacepModule,
        ProgressSpinnerModule,
        ComponentsModule,
        DiretivasModule,
        PipesModule,
        ModaisModule,
        LayoutsModule,
        // NfeModule,
        TemaModule,
        // NgModule,
        // ImageCropperModule,
        // GaugeChartModule,
        // ModaisModule,
        // DiretivasModule,
        // BrowserModule,
        // FormsModule,
        // ReactiveFormsModule,
        // AppRoutingModule,
        // HttpClientModule,
        // BrowserAnimationsModule,
        // AccordionModule,
        // AutoCompleteModule,
        // BreadcrumbModule,
        // ButtonModule,
        // CalendarModule,
        // CardModule,
        // CarouselModule,
        // ChartModule,
        // CheckboxModule,
        // ChipsModule,
        // CodeHighlighterModule,
        // ConfirmDialogModule,
        // ColorPickerModule,
        // ContextMenuModule,
        // DataViewModule,
        // DialogModule,
        // DropdownModule,
        // FieldsetModule,
        // FileUploadModule,
        // FullCalendarModule,
        // GalleriaModule,
        // InplaceModule,
        // InputNumberModule,
        // InputMaskModule,
        // InputSwitchModule,
        // InputTextModule,
        // InputTextareaModule,
        // LightboxModule,
        // ListboxModule,
        // MegaMenuModule,
        // MenuModule,
        // MenubarModule,
        // MessageModule,
        // MessagesModule,
        // MultiSelectModule,
        // OrderListModule,
        // OrganizationChartModule,
        // OverlayPanelModule,
        // PaginatorModule,
        // PanelModule,
        // PanelMenuModule,
        // PasswordModule,
        // PickListModule,
        // ProgressBarModule,
        // RadioButtonModule,
        // RatingModule,
        // RippleModule,
        // ScrollPanelModule,
        SelectButtonModule,
        // SidebarModule,
        // SlideMenuModule,
        // SliderModule,
        // SplitButtonModule,
        // StepsModule,
        // TableModule,
        // TabMenuModule,
        // TabViewModule,
        // TerminalModule,
        // TieredMenuModule,
        // ToastModule,
        // ToggleButtonModule,
        // ToolbarModule,
        // TooltipModule,
        // TreeModule,
        // TreeTableModule,
        // VirtualScrollerModule,
        // ProgressSpinnerModule,
        // PipesModule,
        // LayoutsModule,
        // NgxViacepModule,
        // NgModule,
        EditorModule

    ],
    declarations: [
        AppComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppTopBarComponent,
        AppFooterComponent,
        LoginComponent,
        IntermediariaComponent,
        OpcoesTableControlDirective,
        OpcoesTableComponent,
        DateComponent,
        InputTelefoneComponent,
        InputCpfCnpjComponent,
        HomeComponent,
        ContratarComponent,
        ListaEmpresasComponent,
        ListaClienteToqsysComponent,
        EditarClienteToqsysComponent,
        RevendaComponent,
        EditarRevendaComponent,
        HoldingComponent,
        EditarHoldingComponent,
        UsuarioWebComponent,
        EditarUsuarioWebComponent,
        PainelPageComponent,
        CfopComponent,
        EditarCfopComponent,
        NcmComponent,
        EditarNcmComponent,
        InputMoneyComponent,
        PainelComponent,
        ToogleComponent,
        MotivoDesoneracaoComponent,
        EditarMotivoDesoneracaoComponent,
        CestComponent,
        InputIconComponent,
        EditarCestComponent,
        BancosComponent,
        EditarBancosComponent,
        CidadesComponent,
        CepComponent,
        EditarCepComponent,
        CadastrarTutorialVideoComponent,
        ModalRotinaCadastroComponent,
        ListaTutorialVideoComponent,
        ContadorComponent,
        EditarContadorComponent,
        VersionamentoComponent,
        VersionamentoCadastroComponent,
        InputComponent,
        modalVersionamentoCadastroComponent,
        InputDoubleComponent,
        ModalUploadArquivoComponent,
        ProdutoListaComponent,
        ModalUpdateProdutoComponent,
        GrupoListaComponent,
        CategoriaListaComponent,
        DepartamentoListaComponent,
        SubCategoriaListaComponent,
        EmailCadastroComponent,
        EmailListaComponent,
        SelectButtonComponent

    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        MenuService, MessageService, ConfirmationService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
