import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DoisCliquesDirective} from "./dois-cliques.directive";
import {AlinharCentroDirective} from "./alinhar-centro.directive";
import {AlinharDireitaDirective} from "./alinhar-direita.directive";
import {ModalOpenOnClickDirective} from "./modal-open-on-click.directive";
import {CpfCnpjMaskDirective} from "./cpf-cnpj-mask.directive";
import {DecimalDirective} from "./decimal.directive";
import {OpcoesTableControlDirective} from "../components/opcoes-table/opcoes-table-control.directive";
import {ModalOpenOnClickPortalDirective} from "./modal-open-on-click-portal.directive";

@NgModule({
  declarations: [
      DoisCliquesDirective,
      AlinharCentroDirective,
      AlinharDireitaDirective,
      ModalOpenOnClickDirective,
      ModalOpenOnClickPortalDirective,
      CpfCnpjMaskDirective,
      DecimalDirective,
  ],
  imports: [
    CommonModule
  ],
    exports: [
        DoisCliquesDirective,
        AlinharCentroDirective,
        AlinharDireitaDirective,
        ModalOpenOnClickDirective,
        ModalOpenOnClickPortalDirective,
        CpfCnpjMaskDirective,
        DecimalDirective,
    ]
})
export class DiretivasModule { }
