import { Component, OnInit } from '@angular/core';
import {ConfirmationService, MessageService} from "primeng/api";
import {NetworkService} from "../../services/network.service";
import {Router} from "@angular/router";
import {URL_BASE, qtdLinhas} from "../../controller/staticValues";
import {DadosDefaultService} from "../../services/dados-default.service";

@Component({
  selector: 'app-bancos',
  templateUrl: './bancos.component.html',
  styleUrls: ['./bancos.component.css']
})
export class BancosComponent implements OnInit {

    public first: number = 0
    public top: number = qtdLinhas()
    qtdLinhas = qtdLinhas();
    public totalItens: number
    public lista: any[] = []
    public dadosFixos: any[] = []
    filtro = '';

    opcoesTable = [
        {label: 'Editar', icon: 'fa fa-plus', command: (e) => {
            this.router.navigate([`editar-bancos/${e.Id}`])
        }},
        {label: 'Incluir', icon: 'fa fa-plus', command: (e) => {
            this.router.navigate(['incluir-bancos'])
        }},
    ]

    constructor(private networkService: NetworkService, private router: Router, private messageService: MessageService, private dadosDefaultService: DadosDefaultService) {

    }

    ngOnInit() {
        this.networkService.exibirLoader.next(true);
        this.networkService.getSimples(URL_BASE, 'Banco').subscribe((v: any) => {
            this.lista = v.value
            this.dadosFixos = v.value
            this.totalItens = v.length
        }).add(() => this.networkService.exibirLoader.next(false))
    }

    get usuarios() {
        return this.lista.filter(v => {
            if (v.Nome === null) {
                v.Nome = ''
            }
            if (v.Codigo === null) {
                v.Codigo = ''
            }
            return  v.Nome.toLowerCase().includes(this.filtro.toLowerCase()) || v.Codigo.toLowerCase().includes(this.filtro.toLowerCase())
        })
    }

    incluir(){
        this.router.navigate(['incluir-bancos'])
    }
}
