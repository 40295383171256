import { Component, OnInit } from '@angular/core';
import {ConfirmationService, MessageService} from "primeng/api";
import {NetworkService} from "../../services/network.service";
import {Router} from "@angular/router";
import {URL_BASE, qtdLinhas} from "../../controller/staticValues";
import {DadosDefaultService} from "../../services/dados-default.service";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-cest',
  templateUrl: './cest.component.html',
  styleUrls: ['./cest.component.css']
})
export class CestComponent implements OnInit {

    public first: number = 0
    public top: number = qtdLinhas()
    qtdLinhas = qtdLinhas();
    public totalItens: number
    public lista: any[] = []
    public dadosFixos: any[] = []
    filtro = '';
    row = 10

    opcoesTable = [
        {label: 'Editar', icon: 'fa fa-plus', command: (e) => {
            this.router.navigate([`editar-cest/${e.Id}`])
        }},
        {label: 'Excluir', icon: 'fa fa-plus', command: (e) => {
            this.networkService.deletar(URL_BASE, 'Cest', e.Id).subscribe(v => {
                
            })
        }},
    ]

    constructor(private networkService: NetworkService, private router: Router, private messageService: MessageService, private dadosDefaultService: DadosDefaultService) {

    }

    ngOnInit() {
        this.networkService.getSimples(URL_BASE, `Cest?$inlinecount=allpages&$top=0`).pipe(map((res: Response) => res['@xdata.count'])).subscribe(items => {
            this.totalItens = Number(items)
            this.networkService.getSimples(URL_BASE, 'Cest?$top=7&$skip=0').subscribe((e: any )=> {
              this.lista = e.value
            })
        })
    }

    filtrar(){
        this.networkService.getSimples(URL_BASE, `Cest?$top=10&$skip=0&$filter=(contains(lower(Ncms), lower('${this.filtro}')) or contains(lower(Descricao), lower('${this.filtro}')))`).subscribe((v: any) => {
            this.lista = v.value
        })
    }

    paginate(event){
        this.networkService.getSimples(URL_BASE, `Cest?$top=${event.rows}&$skip=${event.first}`).subscribe((e: any) => {
            this.lista = e.value;
            this.row = event.rows;
        })
    }

    get usuarios() {
        return this.lista.filter(v => {
            if (v.Descricao === null) {
                v.Descricao = ''
            }
            if (v.CodigoCest === null) {
                v.CodigoCest = ''
            }
            return  v.Descricao.toLowerCase().includes(this.filtro.toLowerCase()) || v.CodigoCest.toLowerCase().includes(this.filtro.toLowerCase())
        })
    }

    incluir(){
        this.router.navigate(['incluir-cest'])
    }
}
