import {Component, OnDestroy, OnInit} from '@angular/core';
import {NetworkService} from "../../services/network.service";
import {Router} from "@angular/router";
import {
    getUrlImplante,
    qtdLinhas,
    EMPRESA_LOGIN_SYSTEM,
    TOKEN_STORAGE_KEY,
    USUARIO_STORAGE_KEY,
    opcoesLinhas
} from "../../controller/staticValues";
import {MessageService} from "primeng/api";
import {Subscription} from "rxjs";
import {DadosDefaultService} from "../../services/dados-default.service";
import { environment } from '../../../environments/environment.prod';
import { AuthService } from '../../auth/service/auth.service';
import {map} from "rxjs/operators";

@Component({
  selector: 'app-lista-empresas',
  templateUrl: './lista-empresas.component.html',
  styleUrls: ['./lista-empresas.component.css']
})
export class ListaEmpresasComponent implements OnInit {

  public first: number = 0
  public top: number = qtdLinhas()
  qtdLinhas = qtdLinhas();
  opcoesLinhas = opcoesLinhas()
  rowsSearch = 7
  public totalItens: number
  public lista: any[] = []
  usuario;

  modalVisible = false

  EMPRESA;
  $carregarDadosSubscribe: Subscription;

  opcoesTable = [
      {label: 'Mais Opcoes', icon: 'fa fa-plus', command: (e) => {}},
  ]
  filtro = '';

  constructor(private authService: AuthService, private networkService: NetworkService, private router: Router, private messageService: MessageService, private dadosDefaultService: DadosDefaultService) {

  }

  ngOnInit() {
    this.networkService.getSimples(getUrlImplante(), `CLIENTE_TOQSYS?$inlinecount=allpages&$top=0`).pipe(map((res: Response) => res['@xdata.count'])).subscribe(items => {
      this.totalItens = Number(items)
      this.usuario = JSON.parse(sessionStorage.getItem(USUARIO_STORAGE_KEY))
      this.networkService.getSimples(getUrlImplante(), 'CLIENTE_TOQSYS?$top=7&$skip=0').subscribe((e: any )=> {
        this.lista = e.value
      })
    })
  }

  filtrar(){
    this.networkService.getSimples(getUrlImplante(), `CLIENTE_TOQSYS?$top=${this.rowsSearch}&$skip=0&$filter=(contains(lower(RAZAO_SOCIAL), lower('${this.filtro}')) or contains(lower(CPF_CNPJ), lower('${this.filtro}')))`).subscribe((v: any) => {
      this.lista = v.value
    })
  }

  acessarToqWeb(v) {
    console.log(v);
    this.EMPRESA = v;
    this.EMPRESA.URI = this.EMPRESA.URI.replace(/['/']/g, '+')
    sessionStorage.setItem(EMPRESA_LOGIN_SYSTEM, JSON.stringify(this.EMPRESA));
    window.location.href = `${environment.urlToqWeb}${sessionStorage.getItem(TOKEN_STORAGE_KEY)}` +
      `/${JSON.stringify(sessionStorage.getItem(USUARIO_STORAGE_KEY))}` +
          `/${sessionStorage.getItem(EMPRESA_LOGIN_SYSTEM)}`;
  }

  paginator(event){
    this.rowsSearch = event.rows
    this.networkService.getSimples(getUrlImplante(), `CLIENTE_TOQSYS?$top=${event.rows}&$skip=${event.first}&$filter=(contains(lower(RAZAO_SOCIAL), lower('${this.filtro}')) or contains(lower(CPF_CNPJ), lower('${this.filtro}')))`).subscribe((v: any) => {
      this.lista = v.value
    })
  }

  fecharModal() {
      this.modalVisible = false
  }
}
