import { saveAs } from 'file-saver';
import { Versionamento } from './../../../model/cadastro/versionamento.model';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {NetworkService} from "../../../services/network.service";
import { UsuarioWeb } from '../../../model/usuario-web/usuario-web.model';
import {ActivatedRoute ,Router} from "@angular/router";
import {getUrlImplante} from "../../../controller/staticValues";
import {MessageService} from "primeng/api";
import {Endereco, ErroCep, NgxViacepService} from "@brunoc/ngx-viacep";
import { Subscription } from 'rxjs'
import {Formulario} from "../../../controller/Formulario";
import {DadosDefaultService} from "../../../services/dados-default.service";
import { FormGroup, FormBuilder } from '@angular/forms';
import { Util } from 'src/app/controller/Util';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-versionamento-cadastro',
  templateUrl: './versionamento-cadastro.component.html',
  styleUrls: ['./versionamento-cadastro.component.css']
})
export class VersionamentoCadastroComponent implements OnInit{

  entObj = new Versionamento()
  public lista: any[] = []
  form: FormGroup;
  usuario;
  id;

  $subscription1: Subscription;
  $subscription2: Subscription;
  
  confirmarSenha = '';
  selectedRevenda;
  selectedRegimeTributario;
  selectedHolding;
  selectedContador;


  modalVisible = false
  modalUpload = false

  selectSolucao = []

  temLink = false
  
    constructor(private fb: FormBuilder,public route: ActivatedRoute, private networkService: NetworkService, private router: Router, private messageService: MessageService, private dadosDefault: DadosDefaultService) {
      this.form = Formulario.createForm(this.entObj, this.fb);
    }

    ngOnInit() {      
      this.dadosDefault.versao().subscribe(v => {        
        this.selectSolucao = v[0]
      })
      this.route.paramMap.subscribe(params => {
        this.id = params.get('id');
          if (this.id) {            
            this.$subscription2 = this.networkService.buscar('versionamento', this.id, null, getUrlImplante()).subscribe(value => {                
                const data = Formulario.prepareValueToForm(this.entObj, value, null, null, Versionamento.checkbox(), null );                
                Object.keys(data).forEach(key => this.form.controls[key].setValue(data[key]))                
                if(data.Link) this.temLink = true
            })
          }
      })

      // this.route.paramMap.subscribe(params => {
      //   this.id = params.get('id');
      //     if (this.id) {
      //       this.$subscription2 = this.networkService.buscar('Ncm', this.id, '', URL_BASE).subscribe(value => {
      //         const data = Formulario.prepareValueToForm(this.entObj, value, Ncm.datas(), null, Ncm.checkbox(), null);
      //         Object.keys(data).forEach(key => this.form.controls[key].setValue(data[key]))
      //       })
      //     }
      // })

    }

    senha(v){
      
    }

    processarFormulario(){      
      if(this.form.get('IdSolucao').value === '' || this.form.get('VersaoNumero').value === '' || this.form.get('VersaoString').value === '' || this.form.get('Descricao').value === '') {
        this.messageService.add(Util.pushInfoMessage('Favor preencher os campos em branco.'))
        return
      }

      if(this.form.get('Link').value === ''){
        this.messageService.add(Util.pushInfoMessage('Favor selecionar um Arquivo para UPLOAD!'))
        return
      }      
      const versionamento = {...Formulario.parseForm(this.entObj, Object.assign(this.form.value),null,null,null,null,Versionamento.checkbox())};
      this.dadosDefault.exibirLoader.next(true)
      this.networkService.salvar(getUrlImplante(), 'adm/AtualizacaoApp', versionamento).subscribe(v => {
        this.messageService.add(Util.pushSuccessMsg('Operação Realizada com Sucesso!'))
        this.cancelar()
      }).add(() => this.dadosDefault.exibirLoader.next(false))
      
    }

    receberLink(e){
      if(!e) return
      this.form.get('Link').setValue(e)
      this.temLink = true      
    }

    baixarArquivo(){
      this.dadosDefault.exibirLoader.next(true)
      this.networkService.baixarArquivoS3(this.form.get('Link').value).subscribe(v => {
        saveAs(v, this.form.get('Link').value.replace("https://toqwebtemp.s3.amazonaws.com/", ''))
      }).add(() => this.dadosDefault.exibirLoader.next(false))
      // https://toqwebtemp.s3.amazonaws.com/AtualizadortoqBLEND3.2.1.51-2.exe
    }

    cancelar(){
      this.form.reset()
      this.temLink = false
      this.router.navigate(['versionamento']);
    }
}
