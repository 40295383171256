<div class="p-grid">
    <div  style="margin-top: 30px;">
        <div class="p-col-6 p-offset-3">
            <div style="display: flex;justify-content: space-between;">
                <h1 style="margin: 0xp;font-size: 24px;">Lista de Revendas</h1>
                <button style="width: 125px;margin-left: 15px;height: 30px;background-color: #91c02d;border: 0px;" pButton pRipple type="button" label="Incluir" class="p-button-success" (click)="incluir()"></button>
            </div>
            <p-table [paginator]="true" [rows]="qtdLinhas || 7" [totalRecords]="lista.length" [value]="lista" [lazy]="false">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th [ngStyle]="{width: '50%'}">Nome</th>
                        <th [ngStyle]="{width: '38%'}">Nome Banco</th>
                        <th [ngStyle]="{width: '12%'}">Ações</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td>{{rowData.NOME}}</td>
                        <td>{{rowData.NOME_BANCO}}</td>
                        <td>
                            <app-opcoes-table [value]="rowData"
                                            [opcoes]="opcoesTable"></app-opcoes-table>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
