import {ConfirmationService, MessageService} from "primeng/api";
import {NetworkService} from "../services/network.service";
import {Router} from "@angular/router";
import {Util} from "./Util";
import { OnDestroy, ViewChild, Directive, OnInit } from "@angular/core";
import {getUrlCad, opcoesLinhas, qtdLinhas} from "./staticValues";
import {Subscription} from "rxjs";

export class BaseListComTodosSortEData implements OnDestroy{

    entidade
    jaPesquisou = false
    modalOpen = false
    public first: number = 0
    public loading: boolean
    public top: number = qtdLinhas()
    qtdLinhas = qtdLinhas();
    opcoesLinhas = opcoesLinhas();
    public totalItens: number
    public lista: any[] = []
    @ViewChild('inputPesquisa') public inputPesquisa
    public sortField: string = 'Nome'
    onlyInteger = false
    atributoFiltroComData = undefined
    @ViewChild('dataInicial') public dataInicial
    @ViewChild('dataFinal') public dataFinal
    filtroFixo = undefined
    camposFiltro
    exibirLoader = false
    paginaNumber;

    opcoesTable = [
        {label: 'Alterar', icon: 'fa fa-edit', command: (e) => this.editar(e)},
        {label: 'Excluir', icon: 'fa fa-close', command: (e) => this.deletar(e)},
    ]
    private expanded;

    $subscriptionQtdSemData: Subscription;
    $subscriptionQtdComData: Subscription;
    $subscriptionListaSemData: Subscription;
    $subscriptionListaComData: Subscription;
    $subscriptionDeletar: Subscription;


    constructor(public messageService: MessageService, public confirmationService: ConfirmationService, public networkService: NetworkService, public router: Router, entidad, public url = getUrlCad(), expanded = null, camposFiltro) {
        this.expanded = expanded
        this.entidade = entidad
        this.camposFiltro = camposFiltro
        console.log(this.dataInicial)
    }

    public lazyLoad(event): void {
        if (!this.jaPesquisou) return
        this.loading = true
        if (this.lista) {
            if (this.top !== event.rows && event.rows !== undefined) {
                this.top = event.rows
                event.first = 0
            }
            this.carregarDados(event.first)
            this.loading = false
        }
    }

    addZero = v => v.toString().length === 1 ? '0' + v : v

    public carregarLista(): void {
        let v = this.inputPesquisa === undefined ? '' : this.inputPesquisa.nativeElement.value
        if(this.onlyInteger) {
            if(v) v = v.match(/\d+/).join('').replace(',', '')
            if(v) v = Number(v)
            else v = null
        }
        setTimeout(() => {
            try {
                const dataInicial = new Date(this.dataInicial.value)
                const dataFinal = new Date(this.dataFinal.value)
                const dataFinalParsed = dataFinal.getFullYear() + '-' + this.addZero(dataFinal.getMonth() + 1) + '-' + this.addZero(dataFinal.getDate()) + 'T23:59:59'
                this.$subscriptionQtdComData = this.networkService.quantidadeParaPaginarComDataETodosOsCampos(this.url, this.entidade, this.atributoFiltroComData, dataInicial.getFullYear() + '-' + this.addZero(dataInicial.getMonth() + 1) + '-' + this.addZero(dataInicial.getDate()),
                    dataFinalParsed, this.camposFiltro, v, this.filtroFixo).subscribe(itens => {
                    this.totalItens = itens
                    this.carregarDados(0)
                    this.loading = false
                    this.jaPesquisou = true

                    this.aposPesquisar()
                })
            } catch (e) {
                this.$subscriptionQtdSemData = this.networkService.quantidadeParaPaginarSemDataETodosOsCampos(this.url, this.entidade, this.camposFiltro, v, this.filtroFixo).subscribe(itens => {
                    this.totalItens = itens
                    this.carregarDados(0)
                    this.loading = false
                    this.jaPesquisou = true

                    this.aposPesquisar()
                })
            }
        }, 500)
    }

    public recarregarLista(): void {
        this.carregarDados(this.paginaNumber);
    }

    public carregarDados(skip): void {
        this.paginaNumber = skip;
        const v = this.inputPesquisa === undefined ? '' : this.inputPesquisa.nativeElement.value
        try {
            const dataInicial = new Date(this.dataInicial.value)
            const dataFinal = new Date(this.dataFinal.value)
               const dataFinalParsed = dataFinal.getFullYear() + '-' + this.addZero(dataFinal.getMonth() + 1) + '-' + this.addZero(dataFinal.getDate()) + 'T23:59:59'
            this.$subscriptionListaComData = this.networkService.listarComDataETodosOsCampos(this.url, this.entidade, skip, this.top, this.atributoFiltroComData, dataInicial.getFullYear() + '-' + this.addZero(dataInicial.getMonth() + 1) + '-' + this.addZero(dataInicial.getDate()),
                dataFinalParsed, this.expanded, this.camposFiltro, v, this.filtroFixo, this.sortField)
                .subscribe(listaSec => {
                    this.lista = listaSec
                })
           } catch (e) {
               if(this.exibirLoader) {
                   this.networkService.exibirLoader.next(true)
               }
               this.$subscriptionListaSemData = this.networkService.listarSemDataETodosOsCampos(this.url, this.entidade, skip, this.top, this.expanded, this.camposFiltro, v, this.filtroFixo, this.sortField)
                   .subscribe(listaSec => {
                       this.lista = listaSec
                   }).add(()=> this.networkService.exibirLoader.next(false))
           }



    }

    public deletar(rowData) {
        this.confirmationService.confirm({
            message: `Voce tem certeza que deseja deletar?`,
            acceptLabel: `Sim`,
            rejectLabel: `Nao`,
            accept: () => {
                this.$subscriptionDeletar = this.networkService.deletar(this.url, this.entidade, rowData.Id).subscribe(res => {
                        this.carregarLista()
                    })
            }
        })
    }

    public editar(rowData) {
        this.router.navigate([`/${this.entidade}/${Util.cadastroRoute()}/${rowData.Id}`])
    }

    public navegar(entidade?) {
        if (entidade) {
            this.modalOpen = true
        } else {
            this.router.navigate([`/${this.entidade}/${Util.cadastroRoute()}`])
        }
    }

    pressionaEnter(e?: KeyboardEvent) {
        if(e && e.key !== 'Enter') return
        this.carregarLista()
    }

    ngOnDestroy(): void {
        if(this.$subscriptionQtdSemData) this.$subscriptionQtdSemData.unsubscribe();
        if(this.$subscriptionQtdComData) this.$subscriptionQtdComData.unsubscribe();
        if(this.$subscriptionListaSemData) this.$subscriptionListaSemData.unsubscribe();
        if(this.$subscriptionListaComData) this.$subscriptionListaComData.unsubscribe();
        if(this.$subscriptionDeletar) this.$subscriptionDeletar.unsubscribe();
    }

    dataInit = Util.getDateComUmMesAntes();
    dataFim = Util.getLastDayDate();

    alterouData(e) {
        this.dataInit = new Date(e.dataInicial.getFullYear(), e.dataInicial.getMonth(), e.dataInicial.getDate())
        this.dataFim = new Date(e.dataFinal.getFullYear(), e.dataFinal.getMonth(), e.dataFinal.getDate())
        this.carregarLista()

        // @ts-ignore
        if (this.mudouDataLocal) {
            // @ts-ignore
            this.mudouDataLocal()
        }
    }

    OnInit(){

    }

    aposPesquisar() {
        // @ts-ignore
        if(this.selectedRow) {
            // @ts-ignore
            this.selectedRow = []
        }

        // @ts-ignore
        if(this.selectedRows) {
            // @ts-ignore
            this.selectedRows = []
        }
    }

}
