<p-dialog [modal]="true" [responsive]="true" [contentStyle]="{'width':'500px', 'overflow':'visible'}"
    [maximizable]="true" [header]="'Atualizar Produto'" [(visible)]="modalVisible" (onHide)="fecharModal()">
        <div *ngIf="typeUpdate === 'Grupo'" class="p-grid">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-12">                    
                    <app-input [label]="'Grupo'" [(ngModel)]="grupo"></app-input>
                    <!-- <app-select [label]="'Grupo'" [options]="selectGrupo" [(ngModel)]="grupo"></app-select> -->
                </div>
                <div class="p-offset-11 p-field p-col-12 p-md-12" style="margin-bottom: 0px;height: 30px;margin-top: 20px;display: flex;">
                    <button style="margin-right: 15px;width: 80px;height: 30px;" pButton pRipple type="button" label="Cancelar" class="p-button-danger" (click)="fecharModal()"></button>
                    <button style="width: 80px;height: 30px;" pButton pRipple type="button" label="Confirmar" class="p-button-success" (click)="updateGrupo()"></button>
                </div>
            </div>
        </div>


        <div *ngIf="typeUpdate === 'Marca'" class="p-grid">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-12">
                    <app-input [label]="'Marca'" [(ngModel)]="marca"></app-input>
                    <!-- <app-select [label]="'Marca'" [options]="selectMarca" [(ngModel)]="marca"></app-select> -->
                </div>
                <div class="p-offset-11 p-field p-col-12 p-md-12" style="margin-bottom: 0px;height: 30px;margin-top: 20px;display: flex;">
                    <button style="margin-right: 15px;width: 80px;height: 30px;" pButton pRipple type="button" label="Cancelar" class="p-button-danger" (click)="fecharModal()"></button>
                    <button style="width: 80px;height: 30px;" pButton pRipple type="button" label="Confirmar" class="p-button-success" (click)="updateMarca()"></button>
                </div>
            </div>
        </div>


        <div *ngIf="typeUpdate === 'Outros'" class="p-grid">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-12">                    
                    <app-select [label]="'Grupo'" [options]="selectGrupo" [(ngModel)]="grupo"></app-select>
                </div>
                <div class="p-field p-col-12 p-md-12">                    
                    <app-select [label]="'Marca'" [options]="selectMarca" [(ngModel)]="marca"></app-select>
                </div>
                <div class="p-field p-col-12 p-md-12">                    
                    <app-select [label]="'SubCategoria'" [options]="selectSubCategoria" [(ngModel)]="subcategoria"></app-select>
                </div>
                <div class="p-field p-col-12 p-md-12">                    
                    <app-select [label]="'Departamento'" [options]="selectDepartamento" [(ngModel)]="departamento"></app-select>
                </div>
                <div class="p-offset-7 p-field p-col-12 p-md-12" style="margin-bottom: 0px;height: 30px;margin-top: 20px;display: flex;">
                    <button style="margin-right: 15px;width: 80px;height: 30px;" pButton pRipple type="button" label="Cancelar" class="p-button-danger" (click)="fecharModal()"></button>
                    <button style="width: 80px;height: 30px;" pButton pRipple type="button" label="Confirmar" class="p-button-success" (click)="updateOutros()"></button>
                </div>
            </div>
        </div>
</p-dialog>

