<div class="container">
    <div [formGroup]="form">
        <div class="p-fluid p-formgrid p-grid container-grid">
            <h1 class="p-field p-col-12 p-md-12">Email > Cadastro</h1>
            <div class="p-field p-col-12 p-md-6" style="padding-right: 0px;">
                <!--
                <label for="firstname">CPF ou CNPJ</label>
                -->
                <!--
                <app-input-cpf-cnpj [row]="true" formControlName="CPF_CNPJ"></app-input-cpf-cnpj>
                -->
            </div>
            <div class="p-field p-col-12 p-md-3" style="padding-left: 0px;">
                <app-date [label]="'Data Hora para Disparo'" formControlName="DataEnvio"></app-date>
                <!-- <app-input [label]="'Data Hora para Disparo'" formControlName="DadosEnvio"></app-input> -->
                <!-- <label>Data para disparo</label>
                <input placeholder="Data Hora para Disparo" type="text" pInputText> -->
            </div>
            <div class="p-field p-col-12 p-md-3" style="margin-top: 25px; margin-left: 5px;">
                <p-checkbox label="Base de Usuarios" binary="true" inputId="binary" formControlName="EnviaUsuario">
                </p-checkbox>
            </div>
            <div class="p-field p-col-12 p-md-3" style="margin-top: 25px; margin-left: -5px;">
                <p-checkbox label="Base de Empresa" binary="true" inputId="binary" formControlName="EnviaCliente">
                </p-checkbox>
            </div>
            <div class="p-field p-col-12 p-md-3" style="margin-top: 25px; margin-left: -5px;">
                <p-checkbox label="Apenas Contadores" binary="true" inputId="binary" formControlName="EnviaContador">
                </p-checkbox>
            </div>
            <div class="p-field p-col-12 p-md-3" style="margin-top: 25px; margin-left: -5px;">
                <p-checkbox label="Envio Imediato" binary="true" inputId="binary">
                </p-checkbox>
            </div>
            <!--
            <div class="p-field p-col-12 p-md-6">
                <label for="email">Email</label>
                <input id="email"  type="text" pInputText formControlName="EMAIL">
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="login">Login</label>
                <input id="login"  type="text" pInputText formControlName="LOGIN">
            </div>
            -->
            <div class="p-field p-col-12 p-md-3" style="margin-right: 30px;">
                <app-input [label]="'Assunto'" formControlName="Assunto"></app-input>
                <!-- <label>Assunto</label>
                <input id="assunto" placeholder="Assunto" type="text" pInputText > -->
            </div>
            <div class="p-field p-col-12 p-md-3">
                <app-input [label]="'Título'" formControlName="Titulo"></app-input>
                <!-- <label for="titulo">Titulo</label>
                <input id="titulo" placeholder="Titulo" type="text" pInputText > -->
                <!--
                    [(ngModel)]="confirmarSenha" [ngModelOptions]="{standalone: true}"
                -->
            </div>
            <div class="p-field p-col-12 p-md-9">
                <p-editor formControlName="TextoBlob" [style]="{'height':'320px'}"></p-editor>
            </div>
            <!--
            <div class="p-field p-col-12 p-md-6">
                <label for="celular">Celular</label>

                <app-input-telefone formControlName="CELULAR"></app-input-telefone>

            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="acesso">Acesso</label>
                <input id="acesso"  type="text" pInputText formControlName="ACESSO">
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="chaveativacao">Chave ativação</label>
                <input id="chaveativacao"  type="text" pInputText formControlName="CHAVE_ATIVACAO">
            </div>
            <div class="p-field p-col-12 p-md-4">
                <label for="tipo">Tipo</label>

                <app-select [icon]="true" [options]="tipoSelect" formControlName="TIPO"></app-select>

            </div>
            -->
            <div class="p-field p-col-12 p-md-3 container-button" style="margin-bottom: 0px;height: 40px;">
                <button style="width: 150px;height: 40px;background-color: #ee736c;border: 0px;"  pButton pRipple type="button" label="Cancelar" class="p-button-danger" (click)="cancelar()"></button>
                <button style="width: 150px;margin-left: 15px;height: 40px;background-color: #91c02d;border: 0px;" pButton pRipple type="button" label="Salvar" class="p-button-success" (click)="salvarEmail()"></button>
            </div>
        </div>
    </div>
</div>
