export class Cep {
    Id: number = 0;
    Cep: string = '';
    Logradouro: string = '';
    Complemento: string = '';
    Bairro: string = '';
    Cidade: string = '';
    Uf: string = '';
    Ibge: number = 0;
}
