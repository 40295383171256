import { DateNavigatorComponent } from './date-navigator/date-navigator.component';
import { LayoutsModule } from './../layout/layouts.module';
import { PipesModule } from './../pipes/pipes.module';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { InputComponent } from './input/input.component';
import { InputDoubleComponent } from './input-double/input-double.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SelectComponent} from "./select/select.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {DiretivasModule} from "../diretivas/diretivas.module";
import { SelectIconContainerComponent } from '../components/select-icon-container/select-icon-container.component'

@NgModule({
  declarations: [
      SelectIconContainerComponent,        
      SelectComponent,   
      DateNavigatorComponent,   
  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        // LayoutsModule,        
        DiretivasModule,
        PipesModule, 
        // CommonModule,
        // FormsModule,
        // ReactiveFormsModule,
        // RouterModule,
        // DiretivasModule, 
        DropdownModule,  
        // CalendarModule,     
        
        
    ], exports: [
        SelectIconContainerComponent,          
        SelectComponent,
        DateNavigatorComponent,
    ]
})
export class ComponentsModule { }
