import {Component, OnDestroy, OnInit} from '@angular/core';
import {NetworkService} from "../../../services/network.service";
import {Router, ActivatedRoute} from "@angular/router";
import {getUrlImplante} from "../../../controller/staticValues";
import {MessageService} from "primeng/api";
import {Subscription} from "rxjs";
import {Validators} from "@angular/forms";
import {DadosDefaultService} from "../../../services/dados-default.service";
import { FormGroup, FormBuilder } from '@angular/forms';
import {Formulario} from "../../../controller/Formulario";
import { Util } from 'src/app/controller/Util';

@Component({
  selector: 'app-editar-holding',
  templateUrl: './editar-holding.component.html',
  styleUrls: ['./editar-holding.component.css']
})
export class EditarHoldingComponent implements OnInit{

    entObj = new Holding()
    form: FormGroup;
    id;

    constructor(public route: ActivatedRoute ,private fb: FormBuilder, private networkService: NetworkService, private router: Router, private messageService: MessageService, private dadosDefaultService: DadosDefaultService) {
        this.form = Formulario.createForm(this.entObj, this.fb, Holding.validacoes())
    }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            this.id = params.get('id');
            if(this.id) {
                this.networkService.buscar('HOLDING', this.id, '', getUrlImplante()).subscribe(value => {
                    const data = Formulario.prepareValueToForm(this.entObj, value, null, null, null, null);
                    Object.keys(data).forEach(key => this.form.controls[key].setValue(data[key]));
                })
            }
        })
    }

    processarFormulario(){
        let inv = false
        if(this.form.invalid) {
            Object.keys(this.form.controls).forEach(c => {
                if(this.form.get(c).invalid) {
                    let v = Holding.validacoes().filter(x => x.campo === c)
                    this.messageService.add(Util.pushErrorMsg(`O campo ${v.length && v[0].nome ? v[0].nome : c} é obrigatorio`))
                    inv = true
                }
            })
            if (inv) return
        }

        this.dadosDefaultService.exibirLoader.next(true)
        const data = Formulario.parseForm(this.entObj, Object.assign(this.form.value), null, null, null, null, null, null);
        this.networkService.salvarPost(getUrlImplante(), 'adm/GravarHolding', data).subscribe(value => {
            if(value) this.router.navigate(['holding'])
        }).add(() => this.dadosDefaultService.exibirLoader.next(false))
    }

    cancelar(){
        this.router.navigate(['holding'])
    }
}

class Holding {
    Id: number = 0;
    Nome: string = '';

    static validacoes() {
        return [
            {campo: 'NOME', nome: 'Nome', validation: Validators.compose([Validators.required])},
        ]
    }
}
