<app-page-header [urlName]="'Banco'" urlPath="bancos"></app-page-header>
<div class="container">   
    <div class="p-fluid p-formgrid p-grid container-grid" [formGroup]="form">
        <h1 class="p-field p-col-12 p-md-12">Editar/Incluir Banco</h1>
        <div class="p-field p-col-12 p-md-4">
            <label for="codigo">Código</label>
            <input id="codigo" type="text" formControlName="Codigo" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-8">
            <label for="nomebanco">Nome do Banco</label>
            <input id="nomebanco" type="text" formControlName="Nome" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-12">
            <label for="url">URL</label>
            <input id="url" type="text" formControlName="Url" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-12 container-button" style="margin-bottom: 0px;height: 40px;margin-top: 20px;">
            <button style="width: 150px;height: 40px;background-color: #ee736c;border: 0px;"  pButton pRipple type="button" label="Cancelar" class="p-button-danger" (click)="cancelar()"></button>
            <button style="width: 150px;margin-left: 15px;height: 40px;background-color: #91c02d;border: 0px;" pButton pRipple type="button" label="Salvar" class="p-button-success" (click)="processarFormulario()"></button>
        </div>
    </div>
</div> 