import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Util} from "../../controller/Util";
import {SelectItem} from "primeng/api";
import {defaultColSize} from "../../controller/staticValues";

const FIELD_VALUE_ACESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectButtonComponent),
    multi: true
}

@Component({
  selector: 'app-select-button',
  templateUrl: './select-button.component.html',
  styleUrls: ['./select-button.component.css'],
    providers: [FIELD_VALUE_ACESSOR]
})
export class SelectButtonComponent implements ControlValueAccessor {

    @Input() label: string;
    @Input() id: string = Util.randomId();
    @Input() size = defaultColSize
    @Input() isReadOnly = false;
    @Input() options: SelectItem[] = []
    @Input() center = true
    @Input() row = false;

    private innerValue: any;

    get value() {
        return this.innerValue;
    }

    set value(v: any) {
        if(v !== this.innerValue) {
            this.innerValue = v;
            this.onChange(v)
        }
    }

    constructor() { }

    onChange: (_: any) => void = () => {}
    onTouched: (_: any) => void = () => {}

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isReadOnly = isDisabled;
    }

    writeValue(v: any): void {
        this.value = v;
    }

    get gridClass() {
        return Util.defaultInputClass(this.size)
    }

    changeValue($event: any) {
        this.value = $event.value
    }
}
