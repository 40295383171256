import { Component, OnInit } from '@angular/core';
import {ConfirmationService, MessageService} from "primeng/api";
import {NetworkService} from "../../services/network.service";
import {Router} from "@angular/router";
import {URL_BASE, qtdLinhas, opcoesLinhas, getUrlImplante} from "../../controller/staticValues";
import {DadosDefaultService} from "../../services/dados-default.service";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-contador',
  templateUrl: './contador.component.html',
  styleUrls: ['./contador.component.css']
})
export class ContadorComponent implements OnInit {

    public first: number = 0
    public top: number = qtdLinhas()
    qtdLinhas = qtdLinhas();
    public opcoesLinha = opcoesLinhas()
    public totalItens: number
    public lista: any[] = []
    public dadosFixos: any[] = []
    filtro = '';

    opcoesTable = [
        {label: 'Editar', icon: 'fa fa-plus', command: (e) => {
            this.router.navigate([`cadastrar-contador/${e.ID}`])
        }},
        {label: 'Excluir', icon: 'fa fa-plus', command: (e) => {
            this.networkService.deletar(getUrlImplante(), 'CONTADOR_TOQSYS', e.ID)
        }},
    ]

    constructor(private networkService: NetworkService, private router: Router, private messageService: MessageService, private dadosDefaultService: DadosDefaultService) {

    }

    ngOnInit() {
        this.networkService.exibirLoader.next(true);
        this.networkService.getSimples(getUrlImplante(), `CONTADOR_TOQSYS?$inlinecount=allpages&$top=0`).subscribe((itens: any) => {
            this.totalItens = itens;
            this.networkService.getSimples(getUrlImplante(), 'CONTADOR_TOQSYS?$top=10&$skip=0').subscribe((v: any) => {
                this.lista = v.value
                this.dadosFixos = v.value
            })
        }).add(() => this.networkService.exibirLoader.next(false))
    }

    filtrar(){
        this.networkService.getSimples(getUrlImplante(), `Contador?$top=10&$skip=0&$filter=(contains(lower(NOME), lower('${this.filtro}')) or contains(lower(CPF_CNPJ), lower('${this.filtro}')))`).subscribe((v: any) => {
            this.lista = v.value
        })
    }

    paginate(event){
        this.networkService.getSimples(getUrlImplante(), `Contador?$top=${event.rows}&$skip=${event.first}&$filter=(contains(lower(NOME), lower('${this.filtro}')) or contains(lower(CPF_CNPJ), lower('${this.filtro}')))`).subscribe((v: any) => {
            this.lista = v.value
            this.dadosFixos = v.value
        })
    }

    incluir(){
        this.router.navigate(['cadastrar-contador'])
    }
}
