
import { BaseListComTodosSortEData } from 'src/app/controller/BaseListComTodosSortEData';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NetworkService } from 'src/app/services/network.service';
import { Router } from "@angular/router";

import { getUrlImplante, qtdLinhas } from 'src/app/controller/staticValues';

import { ConfirmationService, MessageService } from "primeng/api";
import { Subscription } from "rxjs";

import { DadosDefaultService } from 'src/app/services/dados-default.service';

import { Util } from 'src/app/controller/Util';

@Component({
    selector: 'app-email-lista',
    templateUrl: './email-lista.component.html',
    styleUrls: ['./email-lista.component.css']
})
export class EmailListaComponent extends BaseListComTodosSortEData implements OnInit {

    public first: number = 0
    public top: number = qtdLinhas()
    public totalItens: number
    public lista: any[] = []
    // dataInit = Util.getDateComUmMesAntes();
    // dataFim = Util.getLastDayDate();
    pagina = 0;
    filtro: Filtro = {}

    jaPesquisou = false
    public loading: boolean

    modalVisible = false

    $subscription1: Subscription;
    $subscription2: Subscription;

    opcoesTable = [
        {
            label: 'Incluir Novo', icon: 'fa fa-plus', command: (e) => {
                this.router.navigate([`email-lista${e.ID}`]);
            }
        },
        {
            label: 'Alterar', icon: 'fa fa-plus', command: (e) => {
                this.dadosDefaultService.exibirLoader.next(true)
                this.networkService.getSimples(getUrlImplante(), 'Email').subscribe((e: any) => {
                    this.lista = e.value
                }).add(() => this.dadosDefaultService.exibirLoader.next(false))

                this.router.navigate([`email-lista${e.ID}`]);
            }
        }

    ]
    // filtro = '';

    constructor(public networkService: NetworkService, public router: Router, public messageService: MessageService, private dadosDefaultService: DadosDefaultService, public confirmationService: ConfirmationService) {
        super(messageService, confirmationService, networkService, router, 'email', getUrlImplante(), null, [
        //     {campo: 'Descricao', tipo: 'string'}
        ])
        this.sortField = 'DataEnvio'
        this.atributoFiltroComData = 'DataEnvio'
        // this.filtroFixo = " and Operacao eq 'E'"
    }

    ngOnInit() {
        this.carregarLista()
    }

    pressionaEnter(e) {
        if (e.key === 'Enter') this.carregarLista(0)
    }

    carregarLista(pag?): void {
        this.dadosDefaultService.exibirLoader.next(true)
        this.networkService.getSimples(getUrlImplante(), 'Email').subscribe((e: any) => {
            this.lista = e.value
        }).add(() => this.dadosDefaultService.exibirLoader.next(false))

        this.loading = false
        let v;

        const filtro: Filtro = {}

        this.filtro = filtro

        this.carregarDados(pag)
    }

    public lazyLoad(event): void {
        this.pagina = event.first / event.rows
        if (!this.jaPesquisou) return
        this.loading = true
        if (this.lista) {
            if (this.top !== event.rows && event.rows !== undefined) {
                this.top = event.rows
                event.first = 0
            }
            this.carregarLista(this.pagina + 1)
            this.loading = false
        }
    }

    public carregarDados(pag): void {
        this.networkService.getSimples(getUrlImplante(), 'Email').subscribe((listaSec: any) => {
            this.totalItens = listaSec.value.length
            this.lista = listaSec.value
            this.jaPesquisou = true
        }, error1 => {
            this.messageService.add(Util.pushErrorMsg(error1))
        });

    }

    incluir() {
        this.router.navigate([`email/cadastro`]);
        // this.modalVisible = true
    }
}

interface Filtro {
    Texto?: string;
}
