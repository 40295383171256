<div class="p-grid">
    <h1 style="font-size: 24px;margin: 0px;">Lista de Clientes TOQSYS</h1>
    <div  style="margin-top: 30px;">
        <div class="p-fluid p-formgrid p-grid" style="display: flex;justify-content: space-between;height: 70px;">
            <div class="p-field p-col-12 p-md-3">
                <label for="revenda">Revenda</label>
                <app-select [icon]="true" (onValueChange)="revenda($event)" [options]="selectedRevenda"></app-select>
            </div>
            <div class="p-field p-col-12 p-md-3">
                <label for="contador">Contador</label>
                <app-select [icon]="true" (onValueChange)="contador($event)" [options]="selectedContador"></app-select>
            </div>
            <div class="p-field p-col-12 p-md-3">
                <label for="holding">Holding</label>
                <app-select [icon]="true" (onValueChange)="holding($event)" [options]="selectedHolding"></app-select>
            </div>
        </div>
        <div class="p-fluid p-formgrid p-grid" style="display: flex;justify-content: space-between;height: 70px;">
            <div class="p-field p-col-12 p-md-3">
                <label for="state">Pesquisar Empresa</label>
                <div style="display: flex;justify-content: start;">
                    <input  pInputText (keydown.enter)="filter()" [(ngModel)]="filtro">
                    <button pButton icon="fa fa-search" (click)="filter()"></button>
                </div>
            </div>
            <div class="p-field p-col-12 p-md-3">
                <label for="state">Status</label>
                <app-select class="p-col-3" [icon]="true" (onValueChange)="statuss($event)" [options]="status"></app-select>
            </div>
            <div class="p-field p-col-12 p-md-3" style="font-size: 18px; margin-top: 25px;display: flex;justify-content: flex-end;">
                <i class="fa fa-file-excel" style="cursor: pointer;" ></i>
                <i class="fa fa-file-pdf" style="margin-left: 15px; cursor: pointer;" ></i>
            </div>
        </div>
        <p-table [paginator]="true" [rows]="qtdLinhas || 7" [totalRecords]="totalItens" (onPage)="paginator($event)" [value]="lista" [lazy]="true">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th [ngStyle]="{width: '20%'}" > Empresa</th>
                    <th [ngStyle]="{width: '10%'}" > Cnpj</th>
                    <th [ngStyle]="{width: '10%'}" > Revenda</th>
                    <th [ngStyle]="{width: '10%'}" > Telefone</th>
                    <th [ngStyle]="{width: '15%'}" > email</th>
                    <th [ngStyle]="{width: '5%'}" >Status</th>
                    <th [ngStyle]="{width: '3%'}" > Ações</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr>
                    <td>{{rowData.RAZAO_SOCIAL}}</td>
                    <td>{{rowData.CPF_CNPJ | cpfCnpj}}</td>
                    <td>{{rowData.NOME_BANCO}}</td>
                    <td>{{rowData.TELEFONE | telefone}}</td>
                    <td>{{rowData.EMAIL}}</td>
                    <td>{{rowData.STATUS}}</td>
                    <td>
                        <app-opcoes-table [value]="rowData"
                                        [opcoes]="opcoesTable"></app-opcoes-table>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>