import {Component, OnDestroy, OnInit} from '@angular/core';
import {NetworkService} from "../../../services/network.service";
import {Cep} from '../../../model/cep.model';
import {ActivatedRoute ,Router} from "@angular/router";
import {getUrlImplante,URL_BASE, getEstados} from "../../../controller/staticValues";
import {MessageService} from "primeng/api";
import {Endereco, ErroCep, NgxViacepService} from "@brunoc/ngx-viacep";
import { Subscription } from 'rxjs'
import {Formulario} from "../../../controller/Formulario";
import {DadosDefaultService} from "../../../services/dados-default.service";
import { FormGroup, FormBuilder } from '@angular/forms';
import { Util } from 'src/app/controller/Util';

@Component({
  selector: 'app-editar-cep',
  templateUrl: './editar-cep.component.html',
  styleUrls: ['./editar-cep.component.css']
})
export class EditarCepComponent implements OnInit{

  entObj = new Cep()
  public lista: any[] = []
  form: FormGroup;
  usuario;
  id;
  modalCepVisible = false;
  selectEstado = getEstados()

  $subscription1: Subscription;
  $subscription2: Subscription;

    constructor(private fb: FormBuilder,public route: ActivatedRoute, private networkService: NetworkService, private router: Router, private messageService: MessageService, private dadosDefaultService: DadosDefaultService, private viaCep: NgxViacepService) {
      this.form = Formulario.createForm(this.entObj, this.fb)
    }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
          this.id = params.get('id');
            if (this.id) {
              this.$subscription2 = this.networkService.buscar('Cep', this.id, '', URL_BASE).subscribe(value => {
                const data = Formulario.prepareValueToForm(this.entObj, value);
                Object.keys(data).forEach(key => this.form.controls[key].setValue(data[key]))
              })
            }
        })
    }

    verificaCepValido(event) {
        if (event.key === 'Enter' || event.type === 'blur') {
            let result = {};
            let cep = this.form.get('Cep').value.toString().match(/\d/g).join('');
            if (cep.length === 8) {
                this.viaCep.buscarPorCep(cep)
                    .then((endereco: Endereco) => {
                        result = endereco;
                        this.form.get('Cep').setValue(cep)
                        this.form.get('Logradouro').setValue(endereco.logradouro);
                        this.form.get('Complemento').setValue(endereco.complemento);
                        this.form.get('Bairro').setValue(endereco.bairro);
                        this.form.get('Cidade').setValue(endereco.localidade);
                        this.form.get('Uf').setValue(endereco.uf)
                    }).catch((error: ErroCep) => {
                    this.messageService.add(Util.pushErrorMsg(error))
                })
            }
        }
    }

    processarFormulario(){
      /*let inv = false
      if(this.form.invalid) {
        Object.keys(this.form.controls).forEach(c => {
          if (this.form.get(c).invalid) {
            let v = Cfop.validacoes().filter(x => x.campo === c)
            this.messageService.add(Util.pushErrorMsg(`O campo ${v.length && v[0].nome ? v[0].nome : c} é obrigatorio`))
            inv = true
          }
        })
        if (inv) return
      }*/
      const data = Formulario.parseForm(this.entObj, Object.assign(this.form.value), null, null, null, null, null, null, null);
      this.route.url.subscribe(v => {
        if(v[0].path == 'editar-cep'){
          this.networkService.atualizar(URL_BASE, 'Cep', data).subscribe(value => {
            this.router.navigate(['cep'])
          })
        }
        if(v[0].path == 'incluir-cep'){
          this.networkService.salvarPost(URL_BASE, 'Cep', data).subscribe(value => {
            this.router.navigate(['cep'])
          })
        }
      })
    }

    cancelar(){
      this.router.navigate(['cep']);
    }
}
