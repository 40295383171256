<div class="testee">
    <div class="imagem">
        <div class="login-panel ui-fluid">
            <div class="ui-g logo-container">
                <!-- <div class="ui-g-12 logo-container">
                <img src="../../../../assets/layout/images/logo-dark.png" alt="logo-dark"/> -->
                <!-- <h1>ToqWeb ADM</h1> -->
                <!-- <h2>Bem Vindo a ToqWeb</h2>
             </div> -->
                <form [formGroup]="form" (submit)="logarUsuario()">
                    <div class="ui-g-12 ">
                        <div class="input-wrapper">
                            <input style="width: 100%;" type="text" autocomplete="off" placeholder="Usuario"
                                formControlName="User" pInputText>
                        </div>
                    </div>

                    <div class="ui-g-12">
                        <div class="input-wrapper">
                            <input style="width: 100%;" type="password" autocomplete="off" placeholder="Senha"
                                formControlName="Password" pInputText>
                        </div>
                    </div>

                    <div class="ui-g-12 button-container">
                        <button pButton pRipple type="submit" style="width: 100%;" label="Entrar"
                            class="p-button-rounded p-button-success"></button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>

<!--<app-modal-experimentar [modalVisible]="modalExperimentarVisible" (closeModal)="fecharModalExperimentar()"></app-modal-experimentar>

<app-modal-ativar-usuario [modalVisible]="modalAtivarVisible" (closeModal)="fecharModalAtivar()"></app-modal-ativar-usuario>-->