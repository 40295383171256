import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { InputMaskModule } from 'primeng/inputmask';
import { PickListModule } from 'primeng/picklist';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { AppModule } from './../app.module';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DialogModule} from "primeng/dialog";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TableModule} from "primeng/table";
import {LayoutsModule} from "../layout/layouts.module";
import {ComponentsModule} from "../components/components.module";
import {DiretivasModule} from "../diretivas/diretivas.module";
import {PipesModule} from "../pipes/pipes.module";
import {ModalRotinaCadastroComponent} from './modal-rotina-cadastro/modal-rotina-cadastro.component'
import { modalVersionamentoCadastroComponent } from './modal-versionamento-cadastro/modal-versionamento-cadastro.component';

@NgModule({
  declarations: [
      //ModalRotinaCadastroComponent,
    //   modalVersionamentoCadastroComponent,
  ],
    imports: [
        CommonModule,
        DialogModule,
        FormsModule,
        ReactiveFormsModule,
        TableModule,
        ProgressSpinnerModule,
        ComponentsModule,
        LayoutsModule,
        ToggleButtonModule,
        DiretivasModule,
        // SpinnerModule,
        CheckboxModule,
        ButtonModule,
        AutoCompleteModule,
        CalendarModule,
        PipesModule,
        MessageModule,
        MessagesModule,
        PickListModule,
        CardModule,
        InputMaskModule,
        InputTextModule,
        FileUploadModule,
        LayoutsModule,
        
        // TemaModule,
        // ComponentsModule,
        // AppModule,        
    ],
    exports: [
        //ModalRotinaCadastroComponent,
        // modalVersionamentoCadastroComponent,
    ]
})
export class ModaisModule { }
