import { DadosDefaultService } from './../../../../services/dados-default.service';
import { getUrlImplante } from './../../../../controller/staticValues';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MessageService } from "primeng/api";
import { Util } from 'src/app/controller/Util';
import { NetworkService } from 'src/app/services/network.service';
import { ThrowStmt } from '@angular/compiler';

@Component({
    selector: 'app-modal-upload-arquivo',
    templateUrl: './modal-upload-arquivo.component.html',
    styleUrls: ['./modal-upload-arquivo.component.css']
})
export class ModalUploadArquivoComponent implements OnInit {

    @Input() modalVisible = false;
    @Input() version;
    labelModal = 'Upload Arquivo'
    senha = '';
    @Output() closeModal = new EventEmitter()
    @Output() dadosSalvos = new EventEmitter()
    @ViewChild('uploadInput') uploadInput: ElementRef;

    arquivoSelecionado = null;
    filename = ''

    exibirLoader = this.dadosDefault.exibirLoader
    exibirLoaderNetwork = this.networkService.exibirLoader

    files: Set<File>

    constructor(private networkService: NetworkService, private messageService: MessageService, private dadosDefault: DadosDefaultService) { }

    ngOnInit() {
    }

    uploadCertificado(e) {
        if (!e.files) return
        const file = e.files[0];        
        this.filename = file.name.replace('.exe', '')
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.arquivoSelecionado = reader.result.toString().split(',')[1]
        };
    }

    uploadArquivoExe(e) {
        if (!e.files) return
        this.files = new Set()
        this.arquivoSelecionado = <FileList>e.files;        
        this.filename = this.arquivoSelecionado[0].name.replace('.exe', '')
        this.files.add(this.arquivoSelecionado[0])        

    }

    enviar() {
        // if(this.senha.length === 0) {
        //     this.messageService.add(Util.pushErrorMsg('A senha é Obrigatória'))
        //     return
        // }
        if (this.arquivoSelecionado === null) {
            this.messageService.add(Util.pushErrorMsg('Selecione o Arquivo'))
            return
        }        
        this.dadosDefault.exibirLoader.next(true)
        this.networkService.uploadArquidoHeader(getUrlImplante(), 'adm/uploadapp',
            this.files
            // this.arquivoSelecionado
            , this.filename.replace(/\s/g, ''), this.version).subscribe(v => {
                this.dadosSalvos.emit(v)
                this.fecharModal()
                this.messageService.add(Util.pushSuccessMsg('Arquivo Enviado com Sucesso!'))
            }, error => this.messageService.add(Util.pushErrorMsg(error))).add(() => this.dadosDefault.exibirLoader.next(false))
    }

    fecharModal() {
        this.reset()
        this.closeModal.emit(false)
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.reset()
    }

    reset() {
        this.senha = ''
        this.arquivoSelecionado = null;
        // @ts-ignore
        if (this.uploadInput) this.uploadInput.clear()
    }


}
