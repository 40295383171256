import {Component, OnDestroy, OnInit} from '@angular/core';
import {NetworkService} from "../../../services/network.service";
import {Contador} from '../../../model/cadastro/contador.model';
import {ActivatedRoute ,Router} from "@angular/router";
import {getUrlImplante,URL_BASE} from "../../../controller/staticValues";
import {MessageService} from "primeng/api";
import {Endereco, ErroCep, NgxViacepService} from "@brunoc/ngx-viacep";
import { Subscription } from 'rxjs'
import {Formulario} from "../../../controller/Formulario";
import {DadosDefaultService} from "../../../services/dados-default.service";
import { FormGroup, FormBuilder } from '@angular/forms';
import { Util } from 'src/app/controller/Util';

@Component({
  selector: 'app-editar-contador',
  templateUrl: './editar-contador.component.html',
  styleUrls: ['./editar-contador.component.css']
})
export class EditarContadorComponent implements OnInit{

  entObj = new Contador()
  public lista: any[] = []
  form: FormGroup;
  usuario;
  filtro = ''
  id;

  modalPesquisaNcm = false;
  ncmParcialParaPesquisar = undefined;
  modalCepVisible = false;

  $subscriptionVerificaNcmValido: Subscription
  $subscription1: Subscription;
  $subscription2: Subscription;

    constructor(private viaCep: NgxViacepService ,private fb: FormBuilder,public route: ActivatedRoute, private networkService: NetworkService, private router: Router, private messageService: MessageService, private dadosDefaultService: DadosDefaultService) {
      this.form = Formulario.createForm(this.entObj, this.fb)
    }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
          this.id = params.get('id');
            if (this.id) {
              this.$subscription2 = this.networkService.buscar('CONTADOR_TOQSYS', this.id, '', getUrlImplante()).subscribe(value => {
                const data = Formulario.prepareValueToForm(this.entObj, value);
                Object.keys(data).forEach(key => this.form.controls[key].setValue(data[key]))
              })
            }
        })
    }

    verificaNcmValido(e) {
        if (e.key !== 'Enter' && e.type !== 'blur' || this.form.get('Ncms').value === '') return
        const ncm = this.form.get('Ncms').value
        this.$subscriptionVerificaNcmValido = this.dadosDefaultService.verificaNcmValido(ncm).subscribe(res => {
        }, error1 => {
            this.ncmParcialParaPesquisar = ncm
            this.messageService.add({severity: 'error', summary: 'Ncm Invalido'})
            this.modalPesquisaNcm = true
        })
    }

    verificaCepValido(event) {
        if (event.key === 'Enter' || event.type === 'blur') {
            let result = {};
            let cep = this.form.get('CEP').value.toString().match(/\d/g).join('');
            if (cep.length === 8) {
                this.viaCep.buscarPorCep(cep)
                    .then((endereco: Endereco) => {
                        result = endereco;
                        this.form.get('CEP').setValue(cep)
                        this.form.get('COMPLEMENTO').setValue(endereco.complemento);
                        this.form.get('ENDERECO').setValue(endereco.logradouro);
                        this.form.get('BAIRRO').setValue(endereco.bairro);
                        this.form.get('CIDADE').setValue(endereco.localidade);
                        this.form.get('UF').setValue(endereco.uf)
                    }).catch((error: ErroCep) => {
                    this.messageService.add(Util.pushErrorMsg(error))
                })
            }
        }
    }

    processarFormulario(){
      /*let inv = false
      if(this.form.invalid) {
        Object.keys(this.form.controls).forEach(c => {
          if (this.form.get(c).invalid) {
            let v = Cfop.validacoes().filter(x => x.campo === c)
            this.messageService.add(Util.pushErrorMsg(`O campo ${v.length && v[0].nome ? v[0].nome : c} é obrigatorio`))
            inv = true
          }
        })
        if (inv) return
      }*/
      const data = Formulario.parseForm(this.entObj, Object.assign(this.form.value), null, Contador.mascaras(), null, null, null, null, null);
      this.networkService.salvarPost(getUrlImplante(), 'adm/ContadorToqSys', data).subscribe(value => {
        this.messageService.add(Util.pushSuccessMsg("Cadastro feito com sucesso!"))
        this.router.navigate(['contador'])
      })
    }

    cancelar(){
      this.router.navigate(['contador']);
    }
}
