<div class="container" [formGroup]="form">
    <div class="p-fluid p-formgrid p-grid container-grid" >
        <h1 class="p-field p-col-12 p-md-12">Editar Cliente</h1>
        <div class="p-field p-col-12 p-md-4">
            <label for="firstname">CNPJ</label>
            <app-input-cpf-cnpj formControlName="CPF_CNPJ" [row]="true"></app-input-cpf-cnpj>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label for="lastname">Razão social</label>
            <input id="lastname" formControlName="RAZAO_SOCIAL" type="text" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-2">
            <label for="state">Status</label>
            <app-select [icon]="true" [options]="status" formControlName="STATUS"></app-select>
        </div>
        <div class="p-field p-col-12 p-md-8">
            <label for="nomefantasia">Nome de Fantasia</label>
            <input id="nomefantasia" formControlName="NOME_FANTASIA" type="text" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label for="inscEstadual">Insc. Estadual</label>
            <input id="inscEstadual" formControlName="IE" type="text" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-5">
            <label for="revenda">Revenda</label>
            <app-select [icon]="true" [options]="selectedRevenda" formControlName="ID_REVENDA"></app-select>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label for="regimetributario">Regime Tributário</label>
            <app-select [icon]="true" [options]="selectedRegimeTributario" formControlName="REGIME_TRIBUTARIO"></app-select>
        </div>
        <div class="p-field p-col-12 p-md-3">
            <label for="versaoSistema">Versão Sistema</label>
            <app-select [icon]="true" [options]="selectedVersaoSistema" formControlName="VERSAO_SISTEMA"></app-select>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label for="uri">Uri</label>
            <input id="uri" type="text" formControlName="URI" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-3">
            <label for="nomebanco">Nome Banco</label>
            <input id="nomebanco" type="text" formControlName="NOME_BANCO" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-3">
            <label for="urldominio">Url Domínio</label>
            <input id="urldominio" type="text" formControlName="UrlDominio" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-2">
            <label for="cep">CEP</label>
            <p-inputMask id="cep" formControlName="CEP" mask="99999-999"></p-inputMask>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label for="logradouro">Logradouro</label>
            <input id="logradouro" type="text" formControlName="ENDERECO" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label for="holding">Holding</label>
            <app-select [icon]="true" [options]="selectedHolding" formControlName="ID_HOLDING"></app-select>
        </div>
        <div class="p-field p-col-12 p-md-2">
            <label for="numero">Número</label>
            <input id="numero" type="text" formControlName="NUMERO" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label for="complemento">Complemento</label>
            <input id="complemento" type="text" formControlName="COMPLEMENTO" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label for="contador">Contador</label>
            <app-select [icon]="true" [options]="selectedContador" formControlName="IdContador"></app-select>
        </div>
        <div class="p-field p-col-12 p-md-5">
            <label for="bairro">Bairro</label>
            <input id="bairro" type="text" formControlName="BAIRRO" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-1">
            <label for="uf">UF</label>
            <input id="uf" type="text" formControlName="UF" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-3">
            <label for="cidade">Cidade</label>
            <input id="cidade" type="text" formControlName="CIDADE" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-3">
            <label for="idempresa">IdEmpresa</label>
            <input id="idempresa" type="text" formControlName="ID_EMPRESA" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label for="email">Email</label>
            <input id="email" type="text" formControlName="EMAIL" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-3">
            <label for="contato">Contato</label>
            <app-input-telefone formControlName="CELULAR_CONTATO"></app-input-telefone>
        </div>
        <div class="p-field p-col-12 p-md-3">
            <label for="qtdestacoes">Qtd Estações</label>
            <input id="qtdestacoes" type="text" formControlName="QTD_ESTACOES" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label for="telefone">Telefone</label>
            <app-input-telefone formControlName="TELEFONE"></app-input-telefone>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label for="celular">Celular</label>
            <app-input-telefone formControlName="CELULAR"></app-input-telefone>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label for="qtdcaixas">Qtd Caixas</label>
            <input id="qtdcaixas" type="text" formControlName="QTD_CAIXAS" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-3">
            <label for="dataativacao">Data Ativação</label>
            <app-date formControlName="DATA_ATIVACAO"></app-date>
        </div>
        <div class="p-field p-col-12 p-md-3">
            <label for="datadesativacao">Data Desativação</label>
            <app-date formControlName="DATA_DESATIVACAO"></app-date>
        </div>
        <div class="p-field p-col-12 p-md-3">
            <label for="databloqueio">Data Bloqueio</label>
            <app-date formControlName="DATA_BLOQUEIO"></app-date>
        </div>
        <div class="p-field p-col-12 p-md-3 container-button" style="margin-bottom: 0px;height: 40px;margin-top: 20px;">
            <button style="width: 150px;height: 40px;background-color: #ee736c;border: 0px;"  pButton pRipple type="button" label="Cancelar" class="p-button-danger" (click)="cancelar()"></button>
            <button style="width: 150px;margin-left: 15px;height: 40px;background-color: #91c02d;border: 0px;" pButton pRipple type="button" label="Salvar" class="p-button-success" (click)="processarFormulario()"></button>
        </div>
    </div>

    <div class="p-fluid p-formgrid p-grid container-grid-2"  style="display:flex;gap:15px;justify-content: space-around;">
        <div class="p-field p-col-12 p-md-4">            
            <app-select-button formControlName="ControleEstoque" [label]="'Controle de estoque'" [options]="stateOptions"></app-select-button>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <app-select-button formControlName="ControleFinanceiro" [label]="'Controle financeiro'" [options]="stateOptions"></app-select-button>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <app-select-button formControlName="Planejamento" [label]="'Planejamento'" [options]="stateOptions"></app-select-button>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <app-select-button formControlName="AnalisadorTributario" [label]="'Analisador tributario'" [options]="stateOptions"></app-select-button>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <app-select-button formControlName="SimuladorPromocao" [label]="'Simulador promoção'" [options]="stateOptions"></app-select-button>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <app-select-button formControlName="ConciliadorCartao" [label]="'Conciliador de cartão'" [options]="stateOptions"></app-select-button>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <app-select-button formControlName="HubIntegracao" [label]="'Hub Integração'" [options]="stateOptions"></app-select-button>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <app-select-button formControlName="Bi"  [label]="'Bi'"[options]="stateOptions"></app-select-button>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <app-select-button formControlName="Scanntech" [label]="'Scanntech'" [options]="stateOptions"></app-select-button>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <app-select-button formControlName="Contabil" [label]="'Contabil'"  [options]="stateOptions"></app-select-button>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <app-select-button formControlName="AppCCI" [label]="'AppCCI'" [options]="stateOptions"></app-select-button>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <app-select-button formControlName="BalancoPatrimonial" [label]="'Balanço Patrimonial'" [options]="stateOptions"></app-select-button>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <app-select-button formControlName="EmissaoMDFe" [label]="'Emissao MDFe'" [options]="stateOptions"></app-select-button>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <app-select-button formControlName="EmissaoCte" [label]="'Emissao Cte'" [options]="stateOptions"></app-select-button>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <app-select-button formControlName="IntegradoImendes" [label]="'Imendes'" [options]="stateOptions"></app-select-button>
        </div>
        <div class="p-field p-col-12 p-md-4">
            
        </div>
    </div>
</div>
