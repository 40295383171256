import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'telefone'
})
export class TelefonePipe implements PipeTransform {

  transform(value: any, args?: any): any {
      if(value === undefined || value === null) return  ''
      const valor = value.toString().match(/\d/g)
      if(valor.length === 8 || valor.length === 9) {
          valor.splice(5, 0, '-')
      } else if(valor.length === 10) {
          valor.splice(0, 0, '(')
          valor.splice(3, 0, ') ')
          valor.splice(8, 0, '-')
      } else if(valor.length === 11) {
          valor.splice(0, 0, '(')
          valor.splice(3, 0, ') ')
          valor.splice(9, 0, '-')
      }
      return valor.join('').replace(',','')
  }

}
