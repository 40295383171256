import {Component, OnDestroy, OnInit} from '@angular/core';
import {NetworkService} from "../../../services/network.service";
import {Ncm} from '../../../model/ncm.model';
import {ActivatedRoute ,Router} from "@angular/router";
import {getUrlImplante,URL_BASE} from "../../../controller/staticValues";
import {MessageService} from "primeng/api";
import {Endereco, ErroCep, NgxViacepService} from "@brunoc/ngx-viacep";
import { Subscription } from 'rxjs'
import {Formulario} from "../../../controller/Formulario";
import {DadosDefaultService} from "../../../services/dados-default.service";
import { FormGroup, FormBuilder } from '@angular/forms';
import { Util } from 'src/app/controller/Util';

@Component({
  selector: 'app-editar-ncm',
  templateUrl: './editar-ncm.component.html',
  styleUrls: ['./editar-ncm.component.css']
})
export class EditarNcmComponent implements OnInit{

  entObj = new Ncm()
  public lista: any[] = []
  form: FormGroup;
  usuario;
  id;

  $subscription1: Subscription;
  $subscription2: Subscription;

  origem: any[] = [
    {label: 'Dentro Estado', value: 'D'},
    {label: 'Fora Estado', value: 'F'},
    {label: 'Exterior', value: 'E'},
  ];

  tipoOperacao: any[] = [
    {label: 'Entrada', value: 'E'},
    {label: 'Saida', value: 'S'}
  ]

  selectCstIpi = []
  selectCstCofins = []
  selectCstPis = []

  onLabel = 'Sim'
  offLabel = 'Não'

    constructor(private fb: FormBuilder,public route: ActivatedRoute, private networkService: NetworkService, private router: Router, private messageService: MessageService, private dadosDefaultService: DadosDefaultService) {
      this.form = Formulario.createForm(this.entObj, this.fb)
    }

    ngOnInit() {

      this.dadosDefaultService.ncm().subscribe(v => {
        this.selectCstIpi = v[0]
        this.selectCstCofins = v[1]
        this.selectCstPis = v[2]
      })

        this.route.paramMap.subscribe(params => {
          this.id = params.get('id');
            if (this.id) {
              this.$subscription2 = this.networkService.buscar('Ncm', this.id, '', URL_BASE).subscribe(value => {
                const data = Formulario.prepareValueToForm(this.entObj, value, Ncm.datas(), null, Ncm.checkbox(), null);
                Object.keys(data).forEach(key => this.form.controls[key].setValue(data[key]))
              })
            }
        })
    }

    processarFormulario(){
      /*let inv = false
      if(this.form.invalid) {
        Object.keys(this.form.controls).forEach(c => {
          if (this.form.get(c).invalid) {
            let v = Cfop.validacoes().filter(x => x.campo === c)
            this.messageService.add(Util.pushErrorMsg(`O campo ${v.length && v[0].nome ? v[0].nome : c} é obrigatorio`))
            inv = true
          }
        })
        if (inv) return
      }*/
      const data = Formulario.parseForm(this.entObj, Object.assign(this.form.value), null, null, Ncm.datas(), Ncm.checkbox(), null, null, null);
      this.route.url.subscribe(v => {
        if(v[0].path == 'editar-ncm'){
          this.networkService.atualizar(URL_BASE, 'Ncm', data).subscribe(value => {
            this.router.navigate(['ncm'])
          })
        }
        if(v[0].path == 'incluir-ncm'){
          this.networkService.salvarPost(URL_BASE, 'Ncm', data).subscribe(value => {
            this.router.navigate(['ncm'])
          })
        }
      })
    }

    cancelar(){
      this.router.navigate(['ncm']);
    }
}
