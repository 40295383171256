import {Component, Input, OnInit, EventEmitter, Output} from '@angular/core';
import {SelectItem} from "primeng/api";
import {FormBuilder, FormGroup} from "@angular/forms";
import {NetworkService} from "../../services/network.service";
import {DadosDefaultService} from "../../services/dados-default.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MessageService} from "primeng/api";
import {Formulario} from "../../controller/Formulario";
import {getUrlCad, EMPRESA_COMPLETA_STORAGE_KEY, URL_BASE} from "../../controller/staticValues";
import {Util} from "../../controller/Util";
import {Validators} from "@angular/forms";
import {Subscription} from 'rxjs';
import { RotinaToqWeb } from '../../model/rotina-toqweb.model';


@Component({
  selector: 'app-modal-rotina-cadastro',
  templateUrl: './modal-rotina-cadastro.component.html',
  styleUrls: ['./modal-rotina-cadastro.component.css']
})
export class ModalRotinaCadastroComponent implements OnInit {

    @Input() modalVisible = false;
    @Output() closeModal = new EventEmitter()
    @Output() dadosSalvos = new EventEmitter()   

    selectGrupo: SelectItem[] = [];

    entidade = 'Cadastrar Rotina';
    entObj = new RotinaToqWeb();
    form: FormGroup;

    constructor(public networkService: NetworkService, public dadosDefault: DadosDefaultService, public router: Router, private route: ActivatedRoute, private fb: FormBuilder, public messageService: MessageService) {
        this.form = Formulario.createForm(this.entObj, this.fb, RotinaToqWeb.validacoes())
    }

    ngOnInit() {

    }

    salvar() {
        let inv = false
        if(this.form.invalid) {
            Object.keys(this.form.controls).forEach(c => {
                if(this.form.get(c).invalid) {
                    let v = RotinaToqWeb.validacoes().filter(x => x.campo === c)
                    this.messageService.add(Util.pushErrorMsg(`O campo ${v.length && v[0].nome ? v[0].nome : c} é obrigatorio`))
                    inv = true
                }
            })
            if (inv) return
        }

        const data = Formulario.parseForm(this.entObj, Object.assign(this.form.value), null, null, null, null, null, null);
        this.dadosSalvos.emit(true);
        this.closeModal.emit({Modal: false, Nome: 'Gabriel'});
        console.log(Object.assign({}, data));

        this.networkService.salvarPost(URL_BASE, 'RotinaToqweb', data).subscribe((v: any) => {
            let dados = {label: v.Nome, value: v.Id, modal: false}
            this.closeModal.emit(dados);
            this.messageService.add(Util.pushSuccessMsg("Rotina cadastrada"))
        })
    }

    salvarContinuar(){
        
    }

    fecharModal() {
        this.closeModal.emit({modal: false})        
        this.form.reset()
    }
}