import { URL_BASE } from './../../../controller/staticValues';
import { Util } from 'src/app/controller/Util';
import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from "primeng/api";
import { Router } from "@angular/router";
import { NetworkService } from 'src/app/services/network.service';
import { DadosDefaultService } from 'src/app/services/dados-default.service';

@Component({
    selector: 'app-produto-lista',
    templateUrl: './produto-lista.component.html',
    styleUrls: ['./produto-lista.component.css'],
})
export class ProdutoListaComponent implements OnInit {

    textoPesquisa;
    grupo = 0
    categoria = 0
    subCategoria = 0
    ncm = ''
    cstPis = ''
    cstIpi = ''
    natPis = ''
    marca = 0
    contem = ''
    naoContem = ''
    iniciaCom = ''

    selectGrupo = []
    selectCategoria = []
    selectSubCategoria = []
    selectMarca = []
    selected = [];
    selectDepartamento = [];

    modalUpdateProduto = false

    filtro: Filtro = {}

    jaPesquisou = false
    pagina = 0;
    public first: number = 0
    public loading: boolean
    public top: number = 100
    qtdLinhas = 100
    public totalItens: number
    public lista: Object[] = []

    type = ''
    listaSelected = []

    opcoesTable = [
        { label: 'Validar Gtin', icon: 'fa fa-check-square-o', command: (e) => {
            this.dadosDefault.exibirLoader.next(true)
            this.networkService.getSimples(URL_BASE, 'Produtos/ValidarGtinCestViaGtin').subscribe(v => {
                this.messageService.add(Util.pushSuccessMsg('Validado com sucesso!'))
            }).add(this.dadosDefault.exibirLoader.next(false))
        }},
        { label: 'Extrair Grupo', icon: 'fa fa-share-square-o', command: (e) => {
            this.dadosDefault.exibirLoader.next(true)
            this.networkService.getSimples(URL_BASE, 'Produtos/ExtrairGrupo').subscribe(v => {
                this.messageService.add(Util.pushSuccessMsg('Extraido com Sucesso!'))
            }).add(this.dadosDefault.exibirLoader.next(false))
         } },
        {
            label: 'Extrair Marca', icon: 'fa fa-share-square-o', command: (e) => {
                this.dadosDefault.exibirLoader.next(true)
                this.networkService.getSimples(URL_BASE, 'Produtos/ExtrairMarca').subscribe(v => {
                    this.messageService.add(Util.pushSuccessMsg('Extraido com Sucesso!'))
                }).add(this.dadosDefault.exibirLoader.next(false))
            }
        },        
    ]

    items = [
        {
            label: 'Atualizar Grupo', icon: 'fa fa-arrow-circle-right', command: (e) => {
                         

            }
        },
        {
            label: 'Atualizar Marca', icon: 'fa fa-arrow-circle-right', command: (e) => {
                   
            }
        },
        {
            label: 'Outras Atualizações', icon: 'fa fa-arrow-circle-right', command: (e) => {
                   
            }
        },
    ];

    constructor(public confirmationService: ConfirmationService, public networkService: NetworkService, public router: Router, private messageService: MessageService, private dadosDefault: DadosDefaultService) {

    }

    ngOnInit() {
        this.dadosDefault.produtoBase().subscribe(value => {
            this.selectGrupo = value[0],
                this.selectCategoria = value[1],
                this.selectSubCategoria = value[2],
                this.selectMarca = value[3],
                this.selectDepartamento = value[4]
        })
        this.carregarLista()
    }


    pressionaEnter(e) {
        if (e.key === 'Enter') this.carregarLista(0)
    }

    updateOutros(){
        if (this.selected.length < 1) {
            this.messageService.add(Util.pushInfoMessage('Favor selecionar pelo menos um produto!'))
            return
        }
        this.type = 'Outros'
        this.modalUpdateProduto = true 
    }

    updateMarca(){
        if (this.selected.length < 1) {
            this.messageService.add(Util.pushInfoMessage('Favor selecionar pelo menos um produto!'))
            return
        }
        this.type = 'Marca'
        this.modalUpdateProduto = true
    }

    updateGrupo(){
        if (this.selected.length < 1) {
            this.messageService.add(Util.pushInfoMessage('Favor selecionar pelo menos um produto!'))
            return
        }
        this.type = 'Grupo'
        this.modalUpdateProduto = true
    }

    modal() {
        this.modalUpdateProduto = false

    }

    public lazyLoad(event): void {
        this.pagina = event.first / event.rows
        if (!this.jaPesquisou) return
        this.loading = true
        if (this.lista) {
            if (this.top !== event.rows && event.rows !== undefined) {
                this.top = event.rows
                event.first = 0
            }
            this.carregarLista(this.pagina + 1)
            this.loading = false
        }
    }

    etiqueta() {
        this.router.navigate(['etiqueta-produto']);
    }

    public carregarLista(pag?): void {
        this.loading = false
        // this.jaPesquisou = true
        let v;

        const filtro: Filtro = {}

        if (this.grupo) filtro.IdGrupo = this.grupo
        if (this.categoria) filtro.IdCategoria = this.categoria
        if (this.subCategoria) filtro.IdSubCategoria = this.subCategoria
        if (this.marca) filtro.IdMarca = this.marca
        if (this.ncm) filtro.Ncm = this.ncm
        if (this.cstPis) filtro.CstPis = this.cstPis
        if (this.cstIpi) filtro.CstIpi = this.cstIpi
        if (this.natPis) filtro.CodNaturePis = this.natPis
        if (this.textoPesquisa) filtro.Texto = "" + this.textoPesquisa
        if (this.contem) filtro.Contem = this.contem
        if (this.naoContem) filtro.NaoContem = this.naoContem
        if (this.iniciaCom) filtro.IniciaCom = this.iniciaCom

        // filtro.Pagina = page

        this.filtro = filtro

        this.carregarDados(filtro, pag)
    }

    public carregarDados(parametros, pag): void {        
        this.networkService.salvarPostComHeaders(URL_BASE, 'Produtos/Buscar', parametros, pag, this.top).subscribe((listaSec: any) => {
            this.totalItens = Util.toNumber(listaSec.headers.get('total'))
            this.lista = listaSec['body'].value
            this.jaPesquisou = true
        }, error1 => {
            this.messageService.add(Util.pushErrorMsg(error1))
        });

    }


    // public deletar(rowData) {
    //     this.confirmationService.confirm({
    //         message: `Voce tem certeza que deseja deletar?`,
    //         acceptLabel: `Sim`,
    //         rejectLabel: `Nao`,
    //         accept: () => {
    //             this.networkService.deletar(getUrlCad(), this.entidade, rowData.Id).subscribe(res => {
    //                 this.carregarLista()
    //             },
    //                 error1 => {
    //                     this.messageService.add(Util.pushErrorMsg(error1))
    //                 })
    //         }
    //     })
    // }

}

interface Filtro {
    Texto?: string;
    Contem?: string;
    NaoContem?: string;
    IniciaCom?: string;
    IdGrupo?: number;
    IdCategoria?: number;
    IdSubCategoria?: number;
    Ncm?: string;
    CstPis?: string;
    CstIpi?: string;
    Gtin?: string;
    Nome?: string;
    Revisadp?: string;
    IdDepartamento?: number;
    CodNaturePis?: string;
    IdMarca?: number;
}