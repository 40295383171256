import { Component, OnDestroy, OnInit } from '@angular/core';
import { NetworkService } from "../../../services/network.service";
import { UsuarioWeb } from '../../../model/usuario-web/usuario-web.model';
import { ActivatedRoute, Router } from "@angular/router";
import { getUrlImplante } from "../../../controller/staticValues";
import { MessageService } from "primeng/api";
import { Endereco, ErroCep, NgxViacepService } from "@brunoc/ngx-viacep";
import { Subscription } from 'rxjs'
import { Formulario } from "../../../controller/Formulario";
import { DadosDefaultService } from "../../../services/dados-default.service";
import { FormGroup, FormBuilder } from '@angular/forms';
import { Util } from 'src/app/controller/Util';

@Component({
  selector: 'app-editar-usuario-web',
  templateUrl: './editar-usuario-web.component.html',
  styleUrls: ['./editar-usuario-web.component.css']
})
export class EditarUsuarioWebComponent implements OnInit {

  entObj = new UsuarioWeb()
  public lista: any[] = []
  form: FormGroup;
  usuario;
  id;

  $subscription1: Subscription;
  $subscription2: Subscription;

  tipoSelect: any[] = [
    { label: 'Normal', value: 'N' },
    { label: 'Suporte', value: 'S' },
    { label: 'Contador', value: 'C' },
  ];

  confirmarSenha = '';
  selectedRevenda;
  selectedRegimeTributario;
  selectedHolding;
  selectedContador;


  modalVisible = false

  constructor(private fb: FormBuilder, public route: ActivatedRoute, private networkService: NetworkService, private router: Router, private messageService: MessageService, private dadosDefaultService: DadosDefaultService) {
    this.form = Formulario.createForm(this.entObj, this.fb, UsuarioWeb.validacoes());
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      if (this.id) {
        // Util.expandedQuery(UsuarioWeb.expanded()
        this.networkService.buscar('USUARIO_WEB', this.id, null, getUrlImplante()).subscribe((value: any) => {
          const data = Formulario.prepareValueToForm(this.entObj, value, null, null, null, null);
          Object.keys(data).forEach(key => this.form.controls[key].setValue(data[key]))
        })
      }
    })
  }

  senha(v) {
    console.log(v)
  }

  processarFormulario() {
    let inv = false
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach(c => {
        if (this.form.get(c).invalid) {
          let v = UsuarioWeb.validacoes().filter(x => x.campo === c)
          this.messageService.add(Util.pushErrorMsg(`O campo ${v.length && v[0].nome ? v[0].nome : c} é obrigatorio`))
          inv = true
        }
      })
      if (inv) return
    }

    // if(this.form.get('SENHA').value.toLowerCase() !== this.confirmarSenha.toLowerCase() || this.confirmarSenha === ''){
    //   this.messageService.add(Util.pushErrorMsg(`O campo "Confirmar senha" esta invalido`));        
    //   return
    // }

    const usuario = { ...Formulario.parseForm(new UsuarioWeb(), Object.assign(this.form.value), null, null, null, null, null, null) };
    usuario.CPF_CNPJ = usuario.CPF_CNPJ.replace(/\./g, '')
    usuario.CPF_CNPJ = usuario.CPF_CNPJ.replace(/\//g, '')
    usuario.CPF_CNPJ = usuario.CPF_CNPJ.replace(/-/g, '')
    if (usuario.CELULAR !== null) {
      usuario.CELULAR = usuario.CELULAR.replace(/[{()}]/g, '')
      usuario.CELULAR = usuario.CELULAR.replace(/\./g, '')
      usuario.CELULAR = usuario.CELULAR.replace(/-/g, '')
      usuario.CELULAR = usuario.CELULAR.replace(/\s/g, '');
    }
    this.dadosDefaultService.exibirLoader.next(true)
    this.networkService.salvarPost(getUrlImplante(), 'adm/UsuarioToqWeb', usuario).subscribe(value => {
      if (value) this.router.navigate(['usuario-web'])
    }).add(() => this.dadosDefaultService.exibirLoader.next(false))
  }

  cancelar() {
    this.router.navigate(['usuario-web']);
  }
}
