<p-dialog [modal]="true" [responsive]="true" [contentStyle]="{'width':'600px', 'overflow':'visible'}"
    [maximizable]="true" [header]="'Enviar de Arquivo'" [(visible)]="modalVisible" (onHide)="fecharModal()">
    <div class="p-grid p-fluid">
        <!-- <div class="p-col-8">
            <app-input [(ngModel)]="senha" label="Senha do Certificado" type="password"></app-input>
        </div> -->
        <div class="p-col-4" style="margin-top: 25px;">
            <p-fileUpload #uploadInput mode="basic" maxFileSize="10000000000000000000000000000000000"
                (onSelect)="uploadArquivoExe($event)" [chooseLabel]="'Selecione o Arquivo'"></p-fileUpload>
        </div>
        <div class="p-col-12">
            <p-footer>
                <app-footer-page [end]="true" (cancelar)="fecharModal()" (confirmar)="enviar()" labelConfirmar="Enviar">
                </app-footer-page>
            </p-footer>
        </div>
    </div>

    <div *ngIf="exibirLoader | async"
        style="position: fixed; width: 44vw; height: 43vh; top: 0; left: 0; z-index: 100; display: flex; align-items: center; justify-content: center; background-color: rgba(255, 255, 255, .4);">
        <p-progressSpinner></p-progressSpinner>
    </div>

    <div *ngIf="exibirLoaderNetwork | async"
        style="position: fixed; width: 44vw; height: 43vh; top: 0; left: 0; z-index: 100; display: flex; align-items: center; justify-content: center; background-color: rgba(255, 255, 255, .4);">
        <p-progressSpinner></p-progressSpinner>
    </div>
</p-dialog>