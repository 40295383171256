<app-page-header [urlName]="'Cep'" urlPath="cep"></app-page-header>
<div class="container">   
    <div class="p-fluid p-formgrid p-grid container-grid" [formGroup]="form">
        <h1 class="p-field p-col-12 p-md-12">Editar/Incluir Cep</h1>
        <div class="p-field p-col-12 p-md-4">
            <label>Cep</label>
            <div style="display: flex;justify-content: flex-start;">
                <p-inputMask class="p-col-11" (onBlur)="verificaCepValido($event)" formControlName="Cep" mask="99999-999"></p-inputMask>
                <button pButton type="button" icon="fa fa-search" (click)="modalCepVisible = true"></button>
            </div>
        </div>
        <div class="p-field p-col-12 p-md-8">
            <label for="endereco">Endereço</label>
            <input id="endereco" type="text" pInputText formControlName="Logradouro">
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label for="bairro">Bairro</label>
            <input id="bairro" type="text" pInputText formControlName="Bairro">
        </div>
        <div class="p-field p-col-12 p-md-8">
            <label for="complemento">Complemento</label>
            <input id="complemento" type="text" pInputText formControlName="Complemento">
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label>Estado</label>
            <app-select [icon]="true" formControlName="Uf" [options]="selectEstado"></app-select>
        </div>
        <div class="p-field p-col-12 p-md-8">
            <label for="cidade">Cidade</label>
            <input id="cidade" type="text" pInputText formControlName="Cidade">
        </div>
        <div class="p-field p-col-12 p-md-12 container-button" style="margin-bottom: 0px;height: 40px;margin-top: 20px;">
            <button style="width: 150px;height: 40px;background-color: #ee736c;border: 0px;"  pButton pRipple type="button" label="Cancelar" class="p-button-danger" (click)="cancelar()"></button>
            <button style="width: 150px;margin-left: 15px;height: 40px;background-color: #91c02d;border: 0px;" pButton pRipple type="button" label="Salvar" class="p-button-success" (click)="processarFormulario()"></button>
        </div>
    </div>
</div> 