import { BaseListComTodosSortEData } from './../../controller/BaseListComTodosSortEData';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NetworkService } from "../../services/network.service";
import { Router } from "@angular/router";
import {
    getUrlImplante,
    qtdLinhas,
} from "../../controller/staticValues";
import { ConfirmationService, MessageService } from "primeng/api";
import { Subscription } from "rxjs";
import { DadosDefaultService } from "../../services/dados-default.service";
import { Util } from 'src/app/controller/Util';

@Component({
    selector: 'app-versionamento',
    templateUrl: './versionamento.component.html',
    styleUrls: ['./versionamento.component.css']
})
export class VersionamentoComponent extends BaseListComTodosSortEData implements OnInit {

    public first: number = 0
    public top: number = qtdLinhas()
    qtdLinhas = qtdLinhas();
    public totalItens: number
    public lista: any[] = []
    public dadosFixos: any[] = []
    usuario;

    dataInit = Util.getDateComUmMesAntes();
    dataFim = Util.getLastDayDate();

    modalVisible = false

    $subscription1: Subscription;
    $subscription2: Subscription;

    opcoesTable = [
        {
            label: 'Editar', icon: 'fa fa-plus', command: (e) => {
                this.router.navigate([`versionamento/cadastro/${e.ID}`]);
            }
        },

    ]
    filtro = '';

    constructor(public networkService: NetworkService, public router: Router, public messageService: MessageService, private dadosDefaultService: DadosDefaultService, public confirmationService: ConfirmationService) {
        super(messageService, confirmationService, networkService, router, 'versionamento', getUrlImplante(), null, [
        //     {campo: 'Descricao', tipo: 'string'},            
        ])
        this.sortField = 'Data'
        this.atributoFiltroComData = 'Data'
        // this.filtroFixo = " and Operacao eq 'E'"
    }

    ngOnInit() {
        this.carregarLista()
    }

    // carregarLista() {
    //     this.dadosDefaultService.exibirLoader.next(true)
    //     this.networkService.getSimples(getUrlImplante(), 'Versionamento').subscribe((e: any) => {
    //         this.lista = e.value
    //     }).add(() => this.dadosDefaultService.exibirLoader.next(false))
    // }

    get versao() {
        return this.lista.filter(v => {
            if (v.Descricao === null) {
                v.Descricao = ''
            }

            return v.Descricao.toLowerCase().includes(this.filtro.toLowerCase())
        })
    }

    incluir() {
        this.router.navigate([`versionamento/cadastro`]);
        // this.modalVisible = true
    }

    alterouData(e) {
        this.dataInit = new Date(e.dataInicial.getFullYear(), e.dataInicial.getMonth(), e.dataInicial.getDate())
        this.dataFim = new Date(e.dataFinal.getFullYear(), e.dataFinal.getMonth(), e.dataFinal.getDate())
        this.carregarLista()

        // @ts-ignore
        if (this.mudouDataLocal) {
            // @ts-ignore
            this.mudouDataLocal()
        }
    }
}
