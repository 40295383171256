import { VirgulaPipe } from './virgula.pipe';
import { DataPipe } from './data.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CpfCnpjPipe} from "./cpf-cnpj.pipe";
import { TelefonePipe } from './telefone.pipe';

@NgModule({
  declarations: [
      CpfCnpjPipe,
      TelefonePipe,
      DataPipe,
      VirgulaPipe,
  ],
  imports: [
    CommonModule,
  ],
    exports: [
        CpfCnpjPipe,
        TelefonePipe,
        DataPipe,
        VirgulaPipe,
    ]
})
export class PipesModule { }
