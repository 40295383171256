import {Validators} from "@angular/forms";

export class Cfop {
    Id: number = 0;
    Cfop: number = 0;
    Descricao: string = '';
    Aplicacao: string = '';
    MovimentaEstoque: boolean = false;
    MovimentaFinanceiro: boolean = false;
    TipoOperacao: string = '';
    Origem: string = '';
    AlteraCustoProduto: boolean = false;
    PermiteCreditoIcms: boolean = false;
    PermiteCreditoIpi: boolean = false;
    PermiteCreditoPis: boolean = false;
    PermiteCreditoCofins: boolean = false;
    TipoFiscal: number = 0;
    GeraDiferencialAliq: boolean = false;
    IndNfe: number = 0;
    IndTransporte: number = 0;
    IndComunicacao: number = 0;
    IndDevolucao: number = 0;
    IndCombustivel: number = 0;
    IndRemessa: number = 0;
    IndRetorno: number = 0;
    IndAnulacao: number = 0;

    static checkbox01() {
        return ['IndNfe', 'IndTransporte', 'IndComunicacao', 'IndDevolucao', 'IndCombustivel', 'IndRemessa', 'IndRetorno', 'IndAnulacao']
    }

    static checkboxNovo() {
        return ['MovimentaEstoque', 'MovimentaFinanceiro', 'AlteraCustoProduto', 'PermiteCreditoIcms', 'PermiteCreditoIpi', 'PermiteCreditoPis', 'PermiteCreditoCofins', 'GeraDiferencialAliq']
    }

    static validacoes() {
        return [
            {campo: 'Cfop', nome: 'Cfop', validation: Validators.compose([Validators.required])},
            {campo: 'Descricao', nome: 'Descrição', validation: Validators.compose([Validators.required])},
            {campo: 'Aplicacao', nome: 'Aplicação', validation: Validators.compose([Validators.required])},
            {campo: 'Origem', nome: 'Origem', validation: Validators.compose([Validators.required])},
            {campo: 'TipoOperacao', nome: 'Tipo de Operação', validation: Validators.compose([Validators.required])},
        ]
    }
}