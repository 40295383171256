import {Component, OnDestroy, OnInit} from '@angular/core';
import {NetworkService} from "../../services/network.service";
import {Router} from "@angular/router";
import {
    getUrlImplante,
    qtdLinhas,
    URL_BASE
} from "../../controller/staticValues";
import {MessageService} from "primeng/api";
import {Subscription} from "rxjs";
import {DadosDefaultService} from "../../services/dados-default.service";

@Component({
  selector: 'app-cfop',
  templateUrl: './cfop.component.html',
  styleUrls: ['./cfop.component.css']
})
export class CfopComponent implements OnInit{

    public first: number = 0
    public top: number = qtdLinhas()
    qtdLinhas = qtdLinhas();
    public totalItens: number
    public lista: any[] = []
    public dadosFixos: any[] = []
    filtro = ''

    opcoesTable = [
        {label: 'Editar', icon: 'fa fa-plus', command: (e) => {
            this.router.navigate([`editar-cfop/${e.Id}`])
        }},
        {label: 'Incluir', icon: 'fa fa-plus', command: (e) => {
            this.router.navigate(['incluir-cfop'])
        }},
    ]

    constructor(private networkService: NetworkService, private router: Router, private messageService: MessageService, private dadosDefaultService: DadosDefaultService) {

    }

    ngOnInit() {
        this.networkService.getSimples(URL_BASE, 'Cfop').subscribe((v: any) => {
            this.lista = v.value
            this.dadosFixos = v.value
            this.totalItens = v.length
        })
    }

    incluir(){
        this.router.navigate(['incluir-cfop'])
    }

    get usuarios() {
        return this.lista.filter(v => {
            if (v.Descricao === null) {
                v.Descricao = ''
            }
            return  v.Descricao.toLowerCase().includes(this.filtro.toLowerCase())
        })
    }
}
