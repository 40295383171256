<!-- <ng-template #conteudo>
    <app-page-divided [formGroup]="form" [padrao]="false">
        <div principal>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <app-select [label]="'Solução'" [options]="selectSolucao" formControlName="IdSolucao"></app-select>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-3">
                    <app-input-double [label]="'Número da Versão'" formControlName="VersaoNumero"></app-input-double>
                </div>
                <div class="p-field p-col-12 p-md-3">
                    <app-input [label]="'Versão'" formControlName="VersaoString"></app-input>
                </div>
                <div class="p-field p-col-12 p-md-3">
                    <p-checkbox [label]="'Obrigatório'" binary="true" inputId="binary" formControlName="Obrigatoria">
                    </p-checkbox>
                </div>
                <div class="p-field p-col-12 p-md-3">
                    <p-checkbox [label]="'Com Atualização'" binary="true" inputId="binary"
                        formControlName="ComAtualizador"></p-checkbox>
                </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-4">
                    <app-input [label]="'Descrição'" formControlName="Descricao"></app-input>
                </div>
            </div>
            <app-footer-page [end]="true" (confirmar)="processarFormulario()" (cancelar)="cancelar()"
                labelConfirmar="Confirmar"></app-footer-page>
        </div>
    </app-page-divided>
</ng-template> -->



<p-dialog [modal]="true" [responsive]="true" [contentStyle]="{'min-width':'800px', 'overflow':'visible'}"
    [maximizable]="true" [header]="'Cadastro da Versão'" [(visible)]="modalVisible">
    <div class="p-col-12 p-fluid" [formGroup]="form">
        <!-- <p-card> -->
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-6 p-md-6">
                    <app-select label="Solução" [options]="selectSolucao" formControlName="IdSolucao">
                    </app-select>
                </div>
            </div>

            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-3 p-md-3">
                    <app-input-double [label]="'Número da Versão'" formControlName="VersaoNumero"></app-input-double>
                </div>
                <div class="p-field p-col-3 p-md-3">
                    <app-input [label]="'Versão'" formControlName="VersaoString"></app-input>
                </div>
                <div class="p-field p-col-3 p-md-3" style="margin-top: 18px;">
                    <p-checkbox [label]="'Obrigatório'" binary="true" inputId="binary" formControlName="Obrigatoria">
                    </p-checkbox>
                </div>
                <div class="p-field p-col-3 p-md-3" style="margin-top: 18px;">
                    <p-checkbox [label]="'Com Atualização'" binary="true" inputId="binary"
                        formControlName="ComAtualizador">
                    </p-checkbox>
                </div>
            </div>

            <div class="p-fluid">
                <div class="p-field p-col-6 p-md-6">
                    <app-input [label]="'Descrição'" formControlName="Descricao"></app-input>
                </div>            
            <!-- </div> -->
        </div>
            &nbsp;
        <!-- </p-card> -->
    </div>

    <app-footer-page [end]="true" (confirmar)="processarFormulario()" (cancelar)="fecharModal()" labelConfirmar="Gerar">
    </app-footer-page>

    <div *ngIf="exibirLoader | async"
        style="position: fixed; width: 100vw; height: 100vh; top: 0; left: 0; z-index: 100; display: flex; align-items: center; justify-content: center; background-color: rgba(255, 255, 255, .4);">
        <p-progressSpinner></p-progressSpinner>
    </div>

    <div *ngIf="exibirLoaderNetwork | async"
        style="position: fixed; width: 100vw; height: 100vh; top: 0; left: 0; z-index: 100; display: flex; align-items: center; justify-content: center; background-color: rgba(255, 255, 255, .4);">
        <p-progressSpinner></p-progressSpinner>
    </div>
</p-dialog>