export class Ncm {
    Id: number = 0
    Ncm: string = ''
    AliquotaPis: number = 0
    CstPis: string = ''
    AliquotaCofins: number = 0
    CstCofins: string = ''
    Descricao: string = ''
    MvaOriginal: number = 0
    BaseLegal: string = ''
    Reducao: string = ''
    Categoria: string = ''
    VigenciaInicial: any = ''
    VigenciaFinal: any = ''
    UnidTributaria: string = ''
    DescricaoUnidTrib: string = ''
    CstIpi: string = ''
    AliqIpi: number = 0
    SujeitaST: string = ''

    constructor() {}

    static datas() {
        return ['VigenciaInicial', 'VigenciaFinal']
    }

    static checkbox() {
        return ['Reducao', 'SujeitaST']
    }
}
