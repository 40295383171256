import { ComponentsModule } from './components/components.module';
import { FieldsetModule } from 'primeng/fieldset';
import { PickListModule } from 'primeng/picklist';
import { TooltipModule } from 'primeng/tooltip';
import { ToolbarModule } from 'primeng/toolbar';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TabViewModule } from 'primeng/tabview';
import { TabMenuModule } from 'primeng/tabmenu';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgModule } from '@angular/core';
import {AccordionModule} from "primeng/accordion";
import {AutoCompleteModule} from "primeng/autocomplete";
import {BreadcrumbModule} from "primeng/breadcrumb";
import {ButtonModule} from "primeng/button";
import {CalendarModule} from "primeng/calendar";
import {CardModule} from "primeng/card";
import {CarouselModule} from "primeng/carousel";
import {ChartModule} from "primeng/chart";
import {CheckboxModule} from "primeng/checkbox";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {DataViewModule} from "primeng/dataview";
import {DialogModule} from "primeng/dialog";
import {DropdownModule} from "primeng/dropdown";
import {FileUploadModule} from "primeng/fileupload";
import {FullCalendarModule} from "primeng/fullcalendar";
import {InputMaskModule} from "primeng/inputmask";
import {InputSwitchModule} from "primeng/inputswitch";
import {InputTextModule} from "primeng/inputtext";
import {InputTextareaModule} from "primeng/inputtextarea";
import {ListboxModule} from "primeng/listbox";
import {MegaMenuModule} from "primeng/megamenu";
import {MenuModule} from "primeng/menu";
import {MenubarModule} from "primeng/menubar";
import {MessageModule} from "primeng/message";
import {MessagesModule} from "primeng/messages";
import {MultiSelectModule} from "primeng/multiselect";
import {OrderListModule} from "primeng/orderlist";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {PaginatorModule} from "primeng/paginator";
import {PanelModule} from "primeng/panel";
import {PanelMenuModule} from "primeng/panelmenu";
import {PasswordModule} from "primeng/password";
import {TableModule} from "primeng/table";
import {ToastModule} from "primeng/toast";
import {VirtualScrollerModule} from "primeng/virtualscroller";

@NgModule({
  declarations: [],
  imports: [
      AccordionModule,
      AutoCompleteModule,
      BreadcrumbModule,
      ButtonModule,
      CalendarModule,
      CardModule,
      CarouselModule,
      ChartModule,
      CheckboxModule,
      ConfirmDialogModule,
      DataViewModule,
      DialogModule,
      DropdownModule,
      FileUploadModule,
      FullCalendarModule,
      InputMaskModule,
      InputSwitchModule,
      InputTextModule,
      InputTextareaModule,
      ListboxModule,
      MegaMenuModule,
      MenuModule,
      MenubarModule,
      MessageModule,
      MessagesModule,
      MultiSelectModule,
      OrderListModule,
      OverlayPanelModule,
      PaginatorModule,
      PanelModule,
      PanelMenuModule,
      PasswordModule,
      ProgressSpinnerModule,
      RadioButtonModule,
      ScrollPanelModule,
      SelectButtonModule,
    //   SpinnerModule,
      SplitButtonModule,
      TableModule,
      TabMenuModule,
      TabViewModule,
      ToastModule,
      ToggleButtonModule,
      ToolbarModule,
      TooltipModule,
      VirtualScrollerModule,
      PickListModule,
      FieldsetModule,
      ComponentsModule,
  ], exports: [
        AccordionModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        ChartModule,
        CheckboxModule,
        ConfirmDialogModule,
        DataViewModule,
        DialogModule,
        DropdownModule,
        FileUploadModule,
        FullCalendarModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        ProgressSpinnerModule,
        RadioButtonModule,
        ScrollPanelModule,
        SelectButtonModule,
        // SpinnerModule,
        SplitButtonModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        VirtualScrollerModule,
        PickListModule,
        FieldsetModule,
    ]
})
export class TemaModule { }
