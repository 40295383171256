<app-page-header urlPath="/Versionamento"></app-page-header>
<p-card [header]="'Versionamento'" [formGroup]="form">
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6">
            <app-select label="Solução" [options]="selectSolucao" formControlName="IdSolucao">
            </app-select>
        </div>
    </div>
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-3">
            <app-input-double [label]="'Número da Versão'" formControlName="VersaoNumero"></app-input-double>
        </div>
        <div class="p-field p-col-12 p-md-3">
            <app-input [label]="'Versão'" formControlName="VersaoString"></app-input>
        </div>
        <div class="p-field p-col-12 p-md-3" style="margin-top: 25px; margin-left: 10px;">
            <p-checkbox [label]="'Obrigatório'" binary="true" inputId="binary" formControlName="Obrigatoria">
            </p-checkbox>
        </div>
        <div class="p-field p-col-12 p-md-3" style="margin-top: 25px; margin-left: -10px;">
            <p-checkbox [label]="'Com Atualização'" binary="true" inputId="binary" formControlName="ComAtualizador">
            </p-checkbox>
        </div>

        <div class="p-field p-col-12 p-md-6">
            <app-input [label]="'Descrição'" formControlName="Descricao"></app-input>
        </div>
    </div>
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-3 container-button" style="margin-bottom: 0px;height: 40px;margin-top: 20px;">
            <button *ngIf="form.get('VersaoString').value !== ''" style="width: 150px;height: 40px;border: 0px;" pButton pRipple type="button" label="Selecione o Arquivo"
                (click)="modalUpload = true"></button>
            <button *ngIf="temLink" style="width: 150px;margin-left: 15px;height: 40px;border: 0px;" pButton
                pRipple type="button" label="Baixe o Arquivo" (click)="baixarArquivo()"></button>
        </div>
    </div>
</p-card>
<app-footer-page [end]="true" (confirmar)="processarFormulario()" (cancelar)="cancelar()"
labelConfirmar="Confirmar"></app-footer-page>

<app-modal-upload-arquivo (closeModal)="modalUpload = false" [modalVisible]="modalUpload" [version]="this.form.get('VersaoString').value" (dadosSalvos)="receberLink($event)"></app-modal-upload-arquivo>