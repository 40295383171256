<app-page-header [urlName]="'Cest'" urlPath="cest"></app-page-header>
<div class="container">   
    <div class="p-fluid p-formgrid p-grid container-grid" [formGroup]="form">
        <h1 class="p-field p-col-12 p-md-12">Editar/Incluir Cest</h1>
        <div class="p-field p-col-12 p-md-10">
            <label for="descricao">Descrição</label>
            <input id="descricao" type="text" formControlName="Descricao" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-2">
            <label for="segmento">Segmento</label>
            <input id="segmento" type="text" formControlName="Segmento" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label for="segmento">Item Segmento</label>
            <input id="segmento" type="text" formControlName="ItemSegmento" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label for="segmento">Especificação</label>
            <input id="segmento" type="text" formControlName="Especificacao" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label for="segmento">Código Cest</label>
            <input id="segmento" type="text" formControlName="CodigoCest" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-8">
            <label>Ncm</label>
            <div style="display: flex;justify-content: flex-start;">
                <app-input-icon primeiro (onBlur)="verificaNcmValido($event)" (onKeypress)="verificaNcmValido($event)" formControlName="Ncms"></app-input-icon>
                <button pButton type="button" icon="fa fa-search" (click)="modalPesquisaNcm = true"></button>
            </div>
        </div>
        <div class="p-field p-col-12 p-md-12 container-button" style="margin-bottom: 0px;height: 40px;margin-top: 20px;">
            <button style="width: 150px;height: 40px;background-color: #ee736c;border: 0px;"  pButton pRipple type="button" label="Cancelar" class="p-button-danger" (click)="cancelar()"></button>
            <button style="width: 150px;margin-left: 15px;height: 40px;background-color: #91c02d;border: 0px;" pButton pRipple type="button" label="Salvar" class="p-button-success" (click)="processarFormulario()"></button>
        </div>
    </div>
</div> 