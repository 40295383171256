import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from "../service/auth.service";
import {NetworkService} from "../../services/network.service";
import {Router} from "@angular/router";
import {
    VERSAO_SISTEMA,
    PERMISSOES,
    API_AUTH,
    URL_API_STORAGE_KEY,
    URL_BANCO_STORAGE_KEY,
    qtdLinhas,
    EMPRESA_STORAGE_KEY,
    TOKEN_STORAGE_KEY, TOKEN_TEMP_STORAGE_KEY, EMPRESA_COMPLETA_STORAGE_KEY, USUARIO_STORAGE_KEY
} from "../../controller/staticValues";
import {MessageService} from "primeng/api";
import {Subscription} from "rxjs";
import {DadosDefaultService} from "../../services/dados-default.service";

@Component({
  selector: 'app-intermediaria',
  templateUrl: './intermediaria.component.html',
  styleUrls: ['./intermediaria.component.css']
})
export class IntermediariaComponent implements OnInit, OnDestroy {

    public first: number = 0
    public top: number = qtdLinhas()
    qtdLinhas = qtdLinhas();
    public totalItens: number
    public lista: any[] = []
    usuario;

    modalVisible = false

    opcoesTable = [
        {label: 'Mais Opcoes', icon: 'fa fa-plus', command: (e) => {}},
    ]
    filtro = '';

    $listarEmpresaSubscribe: Subscription;
    $quartaEtapaSubscribe: Subscription;
    $buscarClienteSubscribe: Subscription;
    $buscarEmpresaSubscribe: Subscription;

  constructor(private authService: AuthService, private networkService: NetworkService, private router: Router, private messageService: MessageService, private dadosDefaultService: DadosDefaultService) {

  }

  ngOnInit() {
      this.usuario = JSON.parse(sessionStorage.getItem(USUARIO_STORAGE_KEY))
      this.dadosDefaultService.exibirLoader.next(true)
        this.$listarEmpresaSubscribe = this.authService.terceiraAuthenticacao(this.usuario).subscribe((res: any) => {
             this.lista = res
                this.totalItens = res.length
            }).add(() => this.dadosDefaultService.exibirLoader.next(false))
  }

    get empresas() {

        return this.lista.filter(v => {
            if (v.RAZAO_SOCIAL === null) {
                v.RAZAO_SOCIAL = ''
            }
            if (v.NOME_FANTASIA === null) {
                v.NOME_FANTASIA = ''
            }
            if (v.CPF_CNPJ === null) {
                v.CPF_CNPJ = ''
            }
            return v.RAZAO_SOCIAL.toLowerCase().includes(this.filtro.toLowerCase()) || v.NOME_FANTASIA.toLowerCase().includes(this.filtro.toLowerCase()) || v.CPF_CNPJ.toString().includes(this.filtro)
        })
    }

    quartaAuthenticacao(v) {
        this.$quartaEtapaSubscribe = this.authService.quartaAuthenticacao({Usuario: this.usuario, IdCli: v.ID}).subscribe(res => {
            sessionStorage.setItem(URL_BANCO_STORAGE_KEY, v['NOME_BANCO'])
            sessionStorage.setItem(VERSAO_SISTEMA,v['VERSAO_SISTEMA'])
            sessionStorage.setItem(URL_API_STORAGE_KEY, v['URI'])
            sessionStorage.setItem('time', new Date().toString())
            sessionStorage.setItem(EMPRESA_STORAGE_KEY, JSON.stringify(this.lista.find(x => x['ID'] === v.ID)))
            sessionStorage.setItem(TOKEN_STORAGE_KEY, res)
            sessionStorage.removeItem(TOKEN_TEMP_STORAGE_KEY)
            this.$buscarClienteSubscribe = this.networkService.buscar('cliente_toqsys',  JSON.parse(sessionStorage.getItem(EMPRESA_STORAGE_KEY)).ID, null, API_AUTH + '/security').subscribe(val => {
                this.$buscarEmpresaSubscribe = this.networkService.buscar('empresa',  JSON.parse(sessionStorage.getItem(EMPRESA_STORAGE_KEY)).ID_EMPRESA).subscribe((res: any) => {
                    sessionStorage.setItem(EMPRESA_COMPLETA_STORAGE_KEY, JSON.stringify(val))
                    this.networkService.salvarPost(
                        API_AUTH,
                        "/security/SecurityUserService/UsuarioAcesso/", 
                        {IdCli: JSON.parse(sessionStorage.getItem(EMPRESA_COMPLETA_STORAGE_KEY)).ID, 
                        IdUsr: JSON.parse(sessionStorage.getItem(USUARIO_STORAGE_KEY)).ID}).subscribe((e: string) => {
                            sessionStorage.setItem(PERMISSOES, e);
                            this.authService.usuarioLogado.next(true)
                            this.router.navigate(['/home'], {replaceUrl: true})
                        })
                })
            })
        })
    }

    fecharModal() {
        this.modalVisible = false
    }

    ngOnDestroy() {
        if(this.$listarEmpresaSubscribe) this.$listarEmpresaSubscribe.unsubscribe()
        if(this.$quartaEtapaSubscribe) this.$quartaEtapaSubscribe.unsubscribe()
        if(this.$buscarClienteSubscribe) this.$buscarClienteSubscribe.unsubscribe()
        if(this.$buscarEmpresaSubscribe) this.$buscarEmpresaSubscribe.unsubscribe()
    }

}
