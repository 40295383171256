export class Versionamento {
    ID: number = 0
    Link: string = ''
    VersaoString: string = ''
    Descricao: string = ''
    IdSolucao: number = 0
    ComAtualizador: boolean = false
    VersaoNumero: number = 0
    Obrigatoria: boolean = false

    static checkbox() {
        return ['Obrigatoria', 'ComAtualizador']
    }

}