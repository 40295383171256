<app-page-header urlPath="/ncm"></app-page-header>
<div class="container">   
    <div class="p-fluid p-formgrid p-grid container-grid" [formGroup]="form">
        <h1 class="p-field p-col-12 p-md-12">Editar/Incluir Ncm</h1>
        <div class="p-field p-col-12 p-md-4">
            <label for="ncm">Ncm</label>
            <input id="ncm" type="text" formControlName="Ncm" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label>Mva original %</label>
            <app-input-money formControlName="MvaOriginal"></app-input-money>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label>ipi %</label>
            <app-input-money formControlName="AliqIpi"></app-input-money>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label>Inicio da vigencia</label>
            <app-date formControlName="VigenciaInicial"></app-date>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label>Fim da vigencia</label>
            <app-date formControlName="VigenciaFinal"></app-date>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label>Unidade tributaria</label>
            <input type="text" formControlName="UnidTributaria" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-8">
            <label>Descrição</label>
            <input type="text" formControlName="Descricao" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label>Categoria do ncm</label>
            <input type="text" formControlName="Categoria" pInputText>
        </div>
        <app-painel class="p-field p-col-12 p-md-12" [title]="'Informacoes adicionais'">
            <div style="display: flex;flex-wrap: wrap; justify-content: space-between;">
                <div class="p-col-8">
                    <label>Ncm</label>
                    <app-select formControlName="CstPis" [options]="selectCstPis"></app-select>
                </div>
                <div class="p-col-4">
                    <label>Aliquota pis</label>
                    <app-input-money formControlName="AliquotaPis"></app-input-money>
                </div>
                <div class="p-col-8">
                    <label>Cst ipi</label>
                    <app-select formControlName="CstIpi" [options]="selectCstIpi"></app-select>
                </div>
                <div class="p-col-4">
                    <label>Aliquota Ipi</label>
                    <app-input-money formControlName="AliqIpi"></app-input-money>
                </div>
                <div class="p-col-8">
                    <label>Cst Cofins</label>
                    <app-select formControlName="CstCofins" [options]="selectCstCofins"></app-select>
                </div>
                <div class="p-col-4">
                    <label>Aliquota cofins</label>
                    <app-input-money formControlName="AliquotaCofins"></app-input-money>
                </div>
                <div class="p-col-12">
                    <label>Base Legal</label>
                    <input type="text" formControlName="BaseLegal" pInputText>
                </div>
                <div class="p-col-12">
                    <label >Descrição da unidade tributaria</label>
                    <textarea formControlName="DescricaoUnidTrib" pInputTextarea></textarea>
                </div>
            </div>
        </app-painel>

        <div class="p-field p-col-12 p-md-12 container-button" style="margin-bottom: 0px;height: 40px;margin-top: 20px;">
            <button style="width: 150px;height: 40px;background-color: #ee736c;border: 0px;"  pButton pRipple type="button" label="Cancelar" class="p-button-danger" (click)="cancelar()"></button>
            <button style="width: 150px;margin-left: 15px;height: 40px;background-color: #91c02d;border: 0px;" pButton pRipple type="button" label="Salvar" class="p-button-success" (click)="processarFormulario()"></button>
        </div>
    </div>
    <div class="p-fluid p-formgrid p-grid container-grid-2" [formGroup]="form">
        <div class="p-field p-col-12 p-md-6">
            <label>Sujeito a redução</label>
            <app-toogle formControlName="Reducao"></app-toogle>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label>Sujeito a st</label>
            <app-toogle formControlName="SujeitaST"></app-toogle>
        </div>
    </div>
</div> 