import { getUrlCad } from './staticValues';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { DadosDefaultService } from './../services/dados-default.service';
import { NetworkService } from './../services/network.service';
import { Subscription } from 'rxjs';
import { OnDestroy, EventEmitter, Output, ViewChild } from '@angular/core';

export class BaseForm implements OnDestroy {

    @Output() dadosSalvos = new EventEmitter()
    @ViewChild('conteudo') content
    hash = ''
    modal = false
    urlCadastro

    $subscriptionSalvar: Subscription;
    $subscriptionAtualizar: Subscription;

    constructor(public networkService: NetworkService, public dadosDefault: DadosDefaultService, public router: Router, public entidade, public messageService: MessageService) {
    }

    public save(endpoint, value, modal?, url = getUrlCad()) {
        this.clear()
        if (!value.Id || value.Id === 0) {
            this.$subscriptionSalvar = this.networkService.salvar(url, endpoint, value).subscribe(v => {
                this.sair(v);
            })
        } else {
            this.$subscriptionAtualizar = this.networkService.atualizar(url, endpoint, value).subscribe(v => {
                this.sair(v);
            })
        }

    }

    public cancelar() {
        this.sair()
    }

    addSingleErrorMessage(msg) {
        this.messageService.add(msg);
    }

    clear() {
        this.messageService.clear();
    }

    private sair(value?) {
        if (this.modal) {
            this.dadosSalvos.emit({type: this.entidade, payload: value})
            this.dadosDefault.closeModal(this.hash);

        } else {
            this.router.navigate([`/${this.urlCadastro !== undefined ? this.urlCadastro : this.entidade}`])
        }
    }

    ngOnDestroy() {
        if(this.$subscriptionSalvar) this.$subscriptionSalvar.unsubscribe()
        if(this.$subscriptionAtualizar) this.$subscriptionAtualizar.unsubscribe()
    }

}
