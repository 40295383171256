<app-page-header urlPath="lista-tutorial"></app-page-header>
<div class="container">   
    <div class="p-fluid p-formgrid p-grid container-grid" [formGroup]="form">
        <h1 class="p-field p-col-12 p-md-12">Cadastrar/Editar Tutorial</h1>
        <div class="p-field p-col-12 p-md-12">
            <label for="descricao">Descrição</label>
            <input id="descricao" type="text" formControlName="Descricao" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-12">
            <label for="link">Link</label>
            <input id="link" type="text" formControlName="Link" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-12">
            <label for="link">Classificação</label>
            <input id="link" type="text" formControlName="Classificacao" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-12">
            <label for="textodetalhe">Texto Detalhe</label>
            <textarea id="textodetalhe" pInputTextarea formControlName="Texto"></textarea>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <div style="margin-left: 7px;">Rotina</div>
            <div class="p-d-flex p-jc-flex-start" style="display: flex;justify-content: flex-start;height: 30px;padding-right: 0;">
                <div class="p-col-8" style="padding-right: 0px;">
                    <app-select [icon]="true" [options]="rotinaSelect" formControlName="IdRotina"></app-select>
                </div>
                <button #rotina pButton type="button" icon="fa fa-plus" (click)="modalRotina = true"></button>
            </div>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label for="state">Tipo Conteudo</label>
            <app-select [icon]="true" [options]="tipoConteudo" formControlName="TipoConteudo"></app-select>
        </div>
        <div class="p-field p-col-12 p-md-12 container-button" style="margin-bottom: 0px;height: 40px;margin-top: 20px;">
            <button style="width: 150px;height: 40px;background-color: #ee736c;border: 0px;"  pButton pRipple type="button" label="Cancelar" class="p-button-danger" (click)="cancelar()"></button>
            <button style="width: 150px;margin-left: 15px;height: 40px;background-color: #91c02d;border: 0px;" pButton pRipple type="button" label="Salvar" class="p-button-success" (click)="processarFormulario()"></button>
        </div>
    </div>

</div>

<app-modal-rotina-cadastro [modalVisible]="modalRotina" (closeModal)="modal($event)"></app-modal-rotina-cadastro>
