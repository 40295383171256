import {Component, OnInit} from '@angular/core';
import {AppComponent} from './app.component';
import {Router} from "@angular/router";
import {AuthService} from "./auth/service/auth.service";
import {
    EMPRESA_STORAGE_KEY,
    TOKEN_STORAGE_KEY,
    TOKEN_TEMP_STORAGE_KEY,
    USUARIO_STORAGE_KEY,
} from "./controller/staticValues";

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit {

    public usuario = ''
    public empresa = ''

    constructor(public app: AppComponent, private router: Router, private authService: AuthService) {
    }

    ngOnInit(): void {
            this.usuario = JSON.parse(sessionStorage.getItem(USUARIO_STORAGE_KEY))['NOME']
    }

    trocarEmpresa() {
        // sessionStorage.removeItem(TOKEN_STORAGE_KEY)
        // sessionStorage.removeItem('postergar')
        // sessionStorage.setItem(TOKEN_TEMP_STORAGE_KEY, sessionStorage.getItem(TOKEN_TEMP_STORAGE_KEY_2))
        const token = sessionStorage.getItem(TOKEN_TEMP_STORAGE_KEY)
        const usuario = sessionStorage.getItem(USUARIO_STORAGE_KEY)
        sessionStorage.clear()
        sessionStorage.setItem(TOKEN_TEMP_STORAGE_KEY, token)
        sessionStorage.setItem(TOKEN_TEMP_STORAGE_KEY, token)
        sessionStorage.setItem(USUARIO_STORAGE_KEY, usuario)
        sessionStorage.setItem(USUARIO_STORAGE_KEY, usuario)
        this.router.navigate(['/selecao-empresa'])
        // this.router.navigate(['/login'], {replaceUrl: true})
    }

}
