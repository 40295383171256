import { Component, OnInit } from '@angular/core';
import { AppComponent } from './app.component';
import { EMPRESA_STORAGE_KEY, TOKEN_STORAGE_KEY, URL_BANCO_STORAGE_KEY, PERMISSOES, VERSAO_SISTEMA, USUARIO_STORAGE_KEY } from "./controller/staticValues";
import { Util } from "./controller/Util";
import { environment } from "../environments/environment";

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[];
    arrayAcesso = JSON.parse(sessionStorage.getItem(USUARIO_STORAGE_KEY)).ACESSO.split('');
    arrayMenu = this.arrayAcesso.slice(1, 9);

    arrayEstatico = [
        {
            label: 'Cadastros', icon: 'fa fa-fw fa-book',
            items: [
                { label: 'Clientes', icon: 'fa fa-fw fa-wrench', routerLink: ['lista-cliente-toqsys'] },
                { label: 'Usuarios', icon: 'fa fa-fw fa-wrench', routerLink: ['usuario-web'] },
                { label: 'Revendas', icon: 'fa fa-fw fa-wrench', routerLink: ['revenda'] },
                { label: 'Holdings', icon: 'fa fa-fw fa-wrench', routerLink: ['holding'] },
                { label: 'Versões', icon: 'fa fa-fw fa-wrench', routerLink: ['versionamento'] },
                { label: 'Contador', icon: 'fa fa-fw fa-wrench', routerLink: ['contador'] },
                { label: 'Cadastro Tutorial', icon: 'fa fa-fw fa-wrench', routerLink: ['lista-tutorial'] },
            ]
        },
        {
            label: 'Cadastros Base', icon: 'fa fa-fw fa-wrench',
            items: [
                { label: 'Produtos', icon: 'fa fa-fw fa-wrench', routerLink: ['produtos'] },
                { label: 'Grupos', icon: 'fa fa-fw fa-wrench', routerLink: ['grupos'] },
                { label: 'Categorias', icon: 'fa fa-fw fa-wrench', routerLink: ['categorias']},
                { label: 'Departamentos', icon: 'fa fa-fw fa-wrench', routerLink: ['departamentos']},
                { label: 'Sub Categorias', icon: 'fa fa-fw fa-wrench', routerLink: ['subcategorias']},
                { label: 'Ncm', icon: 'fa fa-fw fa-wrench', routerLink: ['ncm'] },
                { label: 'CEST', icon: 'fa fa-fw fa-wrench', routerLink: ['cest'] },
                { label: 'Cad. Motivo Desoneração', icon: 'fa fa-fw fa-wrench', routerLink: ['motivo-desoneracao'] },
                { label: 'CFOP', icon: 'fa fa-fw fa-wrench', routerLink: ['cfop'] },
                { label: 'Bancos', icon: 'fa fa-fw fa-wrench', routerLink: ['bancos'] },
                { label: 'CEP', icon: 'fa fa-fw fa-wrench', routerLink: ['cep'] },
                { label: 'Nfe Tipo Pagamento', icon: 'fa fa-fw fa-wrench', routerLink: [] },
            ]
        },
        {
            label: 'Suporte', icon: 'fa fa-fw fa-book',
            items: [
                { label: 'Lista de Empresas', icon: 'fa fa-fw fa-wrench', routerLink: ['lista-empresas'] },
                { label: 'Email', icon: 'fa fa-fw fa-wrench', routerLink: ['email'] }
            ]
        }
    ];

    constructor(public app: AppComponent) { }

    ngOnInit() {
        this.model = this.arrayEstatico;
    }

    menuDinamico() {
        this.arrayMenu.push(this.arrayAcesso[19]);
        let menuPermissoes = [];
        this.arrayMenu.forEach((x, i) => {
            if (x === 'S') menuPermissoes.push(this.arrayEstatico[i])
        });
        let versaoSistema = Util.toNumber(sessionStorage.getItem(VERSAO_SISTEMA));
        if (versaoSistema < 4) {
            menuPermissoes = menuPermissoes.slice(0, 5);
        }
        return menuPermissoes;
    }

    changeTheme(theme) {
        const themeLink: HTMLLinkElement = document.getElementById('theme-css') as HTMLLinkElement;
        const href = 'assets/theme/theme-' + theme + '.css';

        this.replaceLink(themeLink, href);
    }
    changeLayout(layout) {
        const layoutLink: HTMLLinkElement = document.getElementById('layout-css') as HTMLLinkElement;
        const href = 'assets/layout/css/layout-' + layout + '.css';

        this.replaceLink(layoutLink, href);
    }

    isIE() {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    }

    replaceLink(linkElement, href) {
        if (this.isIE()) {
            linkElement.setAttribute('href', href);
        } else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
            });
        }
    }

    onMenuClick() {
        this.app.onMenuClick();
    }
}
