<app-page-header [urlName]="'Motivo Desoneração'" urlPath="motivo-desoneracao"></app-page-header>
<div class="container">   
    <div class="p-fluid p-formgrid p-grid container-grid" [formGroup]="form">
        <h1 class="p-field p-col-12 p-md-12">Editar/Incluir Motivo Desoneração</h1>
        <div class="p-field p-col-12 p-md-12">
            <label for="ncm">Descrição</label>
            <input id="ncm" type="text" formControlName="Descricao" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-12">
            <label>Código</label>
            <app-select formControlName="CodigoNfe" [options]="selectCodigo"></app-select>
        </div>
        <div class="p-field p-col-12 p-md-12 container-button" style="margin-bottom: 0px;height: 40px;margin-top: 20px;">
            <button style="width: 150px;height: 40px;background-color: #ee736c;border: 0px;"  pButton pRipple type="button" label="Cancelar" class="p-button-danger" (click)="cancelar()"></button>
            <button style="width: 150px;margin-left: 15px;height: 40px;background-color: #91c02d;border: 0px;" pButton pRipple type="button" label="Salvar" class="p-button-success" (click)="processarFormulario()"></button>
        </div>
    </div>
</div> 