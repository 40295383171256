import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {Util} from "../../controller/Util";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {center} from "../../controller/staticValues";

const FIELD_VALUE_ACESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ToogleComponent),
    multi: true
}

@Component({
  selector: 'app-toogle',
  templateUrl: './toogle.component.html',
  styleUrls: ['./toogle.component.css'],
    providers: [FIELD_VALUE_ACESSOR]
})
export class ToogleComponent implements ControlValueAccessor {

    @Input() label: string;
    @Input() id: string = Util.randomId();
    @Input() isReadOnly = false;
    @Input() size = '12'
    @Input() onLabel = 'Sim'
    @Input() offLabel = 'Nao'
    @Input() center = center
    @Input() row = false;
    @Input() inline = false

    private innerValue: any;

    get value() {
        return this.innerValue;
    }

    set value(v: any) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.onChange(v)
        }
    }

    constructor() {
    }

    onChange: (_: any) => void = () => {
    }
    onTouched: (_: any) => void = () => {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isReadOnly = isDisabled;
    }

    writeValue(v: any): void {
        this.value = v;
    }


    get gridClass() {
        return this.inline ? {...Util.defaultInputClass(this.size), inline: true} : Util.defaultInputClass(this.size)
    }

    changeValue($event: any) {
        this.value = $event.checked
    }

}
