import { Component, OnInit } from '@angular/core';
import {ConfirmationService, MessageService} from "primeng/api";
import {NetworkService} from "../../../services/network.service";
import {Router} from "@angular/router";
import {URL_BASE, qtdLinhas, opcoesLinhas} from "../../../controller/staticValues";
import {Util} from "../../../controller/Util";
import {DadosDefaultService} from "../../../services/dados-default.service";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-lista-tutorial-video',
  templateUrl: './lista-tutorial-video.component.html',
  styleUrls: ['./lista-tutorial-video.component.css']
})
export class ListaTutorialVideoComponent implements OnInit {

    public first: number = 0
    public top: number = qtdLinhas()
    public opcoesLinha = opcoesLinhas()
    qtdLinhas = qtdLinhas();
    public totalItens: number
    public lista: any[] = []
    public dadosFixos: any[] = []
    row = 10;
    filtro = '';

    opcoesTable = [
        {label: 'Editar', icon: 'fa fa-plus', command: (e) => {
            this.router.navigate([`cadastrar-tutorial/${e.Id}`])
        }},
        {label: 'Excluir', icon: 'fa fa-plus', command: (e) => {
            this.networkService.deletar(URL_BASE, 'LinkAjuda', e.Id).subscribe(v => {
                this.messageService.add(Util.pushSuccessMsg('Exclusão realizada com sucesso!'))
            })
        }},
    ]

    constructor(private networkService: NetworkService, private router: Router, private messageService: MessageService, private dadosDefaultService: DadosDefaultService) {

    }

    ngOnInit() {
        this.networkService.exibirLoader.next(true);
        this.networkService.getSimples(URL_BASE, 'LinkAjuda?$inlinecount=allpages&$top=0').pipe(map((res: Response) => res['@xdata.count'])).subscribe((itens: any) => {
            this.totalItens = itens
            this.networkService.getSimples(URL_BASE, 'LinkAjuda?$top=10').subscribe((v: any) => {
                this.lista = v.value;
            })
        }).add(() => this.networkService.exibirLoader.next(false))
    }

    filtrar(){
        let filtrar = this.filtro.toLowerCase()
        this.networkService.getSimples(URL_BASE, `LinkAjuda?$filter=contains(lower(Descricao), lower('${filtrar}'))&$top=${this.row}`).subscribe((e: any) => {
            this.lista = e.value;
        })
    }

    paginate(event){
        this.networkService.getSimples(URL_BASE, `LinkAjuda?$top=${event.rows}&$skip=${event.first}`).subscribe((e: any) => {
            this.lista = e.value;
            this.row = event.rows;
        })
    }

    incluir(){
        this.router.navigate(['cadastrar-tutorial'])
    }
}