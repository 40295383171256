<app-page-header [urlName]="'Contador'" urlPath="contador"></app-page-header>
<div class="container">   
    <div class="p-fluid p-formgrid p-grid container-grid" [formGroup]="form">
        <h1 class="p-field p-col-12 p-md-12">Editar/Incluir Contador</h1>
        <div class="p-field p-col-12 p-md-4">
            <label>CPF_CNPJ</label>
            <app-input-cpf-cnpj formControlName="CPF_CNPJ" [row]="true"></app-input-cpf-cnpj>
        </div>
        <div class="p-field p-col-12 p-md-8">
            <label>Nome</label>
            <input id="lastname" formControlName="NOME" type="text" pInputText> 
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label>Email</label>
            <input id="lastname" formControlName="EMAIL" type="text" pInputText> 
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label for="contato">Telefone</label>
            <app-input-telefone formControlName="FONE"></app-input-telefone>  
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label>CRC</label>
            <input id="lastname" formControlName="CRC" type="text" pInputText> 
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label>Cep</label>
            <div style="display: flex;justify-content: flex-start;">
                <p-inputMask class="p-col-11" (onBlur)="verificaCepValido($event)" formControlName="CEP" mask="99999-999"></p-inputMask>
                <button pButton type="button" icon="fa fa-search" (click)="modalCepVisible = true"></button>
            </div>
        </div>
        <div class="p-field p-col-12 p-md-8">
            <label>Endereço</label>
            <input id="lastname" formControlName="ENDERECO" type="text" pInputText> 
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label>Bairro</label>
            <input id="lastname" formControlName="BAIRRO" type="text" pInputText> 
        </div>
        <div class="p-field p-col-12 p-md-8">
            <label>Complemento</label>
            <input id="lastname" formControlName="COMPLEMENTO" type="text" pInputText> 
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label>UF</label>
            <input id="lastname" formControlName="UF" type="text" pInputText> 
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label>Cidade</label>
            <input id="lastname" formControlName="CIDADE" type="text" pInputText> 
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label>Número</label>
            <input id="lastname" formControlName="NUMERO" type="text" pInputText> 
        </div>
        
        <div class="p-field p-col-12 p-md-12 container-button" style="margin-bottom: 0px;height: 40px;margin-top: 20px;">
            <button style="width: 150px;height: 40px;background-color: #ee736c;border: 0px;"  pButton pRipple type="button" label="Cancelar" class="p-button-danger" (click)="cancelar()"></button>
            <button style="width: 150px;margin-left: 15px;height: 40px;background-color: #91c02d;border: 0px;" pButton pRipple type="button" label="Salvar" class="p-button-success" (click)="processarFormulario()"></button>
        </div>
    </div>
</div> 