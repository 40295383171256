import {Component, OnDestroy, OnInit} from '@angular/core';
import {NetworkService} from "../../services/network.service";
import {Banco} from '../../model/banco.model';
import {ActivatedRoute ,Router} from "@angular/router";
import {getUrlImplante,URL_BASE} from "../../controller/staticValues";
import {MessageService} from "primeng/api";
import {Endereco, ErroCep, NgxViacepService} from "@brunoc/ngx-viacep";
import { Subscription } from 'rxjs'
import {Formulario} from "../../controller/Formulario";
import {DadosDefaultService} from "../../services/dados-default.service";
import { FormGroup, FormBuilder } from '@angular/forms';
import { LinkAjuda } from '../../model/link-ajuda.model'
import { Util } from 'src/app/controller/Util';

@Component({
  selector: 'app-cadastrar-tutorial-video',
  templateUrl: './cadastrar-tutorial-video.component.html',
  styleUrls: ['./cadastrar-tutorial-video.component.css']
})
export class CadastrarTutorialVideoComponent implements OnInit{

  entObj = new LinkAjuda()
  public lista: any[] = []
  form: FormGroup;
  modalRotina = false;
  usuario;
  id;

  tipoConteudo: any[] = [
    {label: 'Texto', value: 'T'},
    {label: 'Vídeo', value: 'V'},
  ];
  rotinaSelect : any[]

  $subscription1: Subscription;
  $subscription2: Subscription;

    constructor(private fb: FormBuilder,public route: ActivatedRoute, private networkService: NetworkService, private router: Router, private messageService: MessageService, private dadosDefaultService: DadosDefaultService) {
      this.form = Formulario.createForm(this.entObj, this.fb, LinkAjuda.validacoes())
    }

    ngOnInit() {
      this.route.paramMap.subscribe(params => {
        this.id = params.get('id');
          if (this.id) {
            this.$subscription2 = this.networkService.buscar('LinkAjuda', this.id, '', URL_BASE).subscribe(value => {
              const data = Formulario.prepareValueToForm(this.entObj, value);
              Object.keys(data).forEach(key => this.form.controls[key].setValue(data[key]))
            })
          }
      })
        this.dadosDefaultService.rotinaLink().subscribe(v => {
          this.rotinaSelect = v[0];
        })
    }

    processarFormulario(){
      let inv = false
      if(this.form.invalid) {
        Object.keys(this.form.controls).forEach(c => {
          if (this.form.get(c).invalid) {
            let v = LinkAjuda.validacoes().filter(x => x.campo === c)
            this.messageService.add(Util.pushErrorMsg(`O campo ${v.length && v[0].nome ? v[0].nome : c} é obrigatorio`))
            inv = true
          }
        })
        if (inv) return
      }
      
      const data = Formulario.parseForm(this.entObj, Object.assign(this.form.value), null, null, null, null, null, null, null);
      this.networkService.salvarPost(URL_BASE, 'LinkAjuda', data).subscribe(v => {
        this.messageService.add(Util.pushSuccessMsg("Tutorial cadastrado com sucesso"));
      })
    }

    rotina(v: any){
      console.log(v)
    }

    modal(event: any){
      if(event.label != undefined){
        let dados = {label: event.label, value: event.value}
        this.rotinaSelect.push(dados)
        this.form.get('IdRotina').setValue(event.value)
      }
      console.log(event.label);
      this.modalRotina = event.modal
    }

    cancelar(){
      this.router.navigate(['lista-tutorial']);
    }
}
