import {Validators} from "@angular/forms";

export class UsuarioWeb {
    ID: number = 0;
    NOME: string = '';
    CPF_CNPJ: string = '';
    EMAIL: string = '';
    LOGIN: string = '';
    SENHA: string = '';
    CELULAR: string = '';
    ATIVO: string = '';
    ID_PAPEL: number = 0;
    ACESSO: string = '';
    CHAVE_ATIVACAO: string = '';
    DATA_CADASTRO: string = '';
    TIPO: string = '';
    
    static data() {
        return ['DATA_CADASTRO']
    }

    static expanded() {
        return ['ID_USUARIO']
    }

    static validacoes() {
        return [
            {campo: 'NOME', nome: 'Nome', validation: Validators.compose([Validators.required])},
            {campo: 'CPF_CNPJ', nome: 'CPF ou CNPJ', validation: Validators.compose([Validators.required])},
            {campo: 'LOGIN', nome: 'Login', validation: Validators.compose([Validators.required])},
            {campo: 'EMAIL', nome: 'Email', validation: Validators.compose([Validators.required])},
            {campo: 'SENHA', nome: 'Senha', validation: Validators.compose([Validators.required])},
            // {campo: 'CELULAR', nome: 'Celular', validation: Validators.compose([Validators.required])},
            {campo: 'ATIVO', nome: 'Ativo', validation: Validators.compose([Validators.required])},
            {campo: 'ACESSO', nome: 'Acesso', validation: Validators.compose([Validators.required])},
        ]
    }
}