<div class="container">   
    <div [formGroup]="form">
        <div class="p-fluid p-formgrid p-grid container-grid">
            <h1 class="p-field p-col-12 p-md-12">Editar Usuário</h1>
            <div class="p-field p-col-12 p-md-6">
                <label for="firstname">CPF ou CNPJ</label>
                <app-input-cpf-cnpj [row]="true" formControlName="CPF_CNPJ"></app-input-cpf-cnpj>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="nome">Nome</label>
                <input id="nome"  type="text" pInputText formControlName="NOME"> 
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="email">Email</label>
                <input id="email"  type="text" pInputText formControlName="EMAIL"> 
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="login">Login</label>
                <input id="login"  type="text" pInputText formControlName="LOGIN"> 
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="senha">Senha</label>
                <input id="senha" [disabled]="true" type="password" pInputText formControlName="SENHA"> 
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="confirmarsenha">Confirmar a senha</label>
                <input id="confirmarsenha" type="password" [(ngModel)]="confirmarSenha" [ngModelOptions]="{standalone: true}" pInputText> 
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="celular">Celular</label>
                <app-input-telefone formControlName="CELULAR"></app-input-telefone> 
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="acesso">Acesso</label>
                <input id="acesso"  type="text" pInputText formControlName="ACESSO"> 
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="chaveativacao">Chave ativação</label>
                <input id="chaveativacao"  type="text" pInputText formControlName="CHAVE_ATIVACAO"> 
            </div>
            <div class="p-field p-col-12 p-md-4">
                <label for="tipo">Tipo</label>
                <app-select [icon]="true" [options]="tipoSelect" formControlName="TIPO"></app-select>
            </div>
            <div class="p-field p-col-12 p-md-3 container-button" style="margin-bottom: 0px;height: 40px;margin-top: 20px;">
                <button style="width: 150px;height: 40px;background-color: #ee736c;border: 0px;"  pButton pRipple type="button" label="Cancelar" class="p-button-danger" (click)="cancelar()"></button>
                <button style="width: 150px;margin-left: 15px;height: 40px;background-color: #91c02d;border: 0px;" pButton pRipple type="button" label="Salvar" class="p-button-success" (click)="processarFormulario()"></button>
            </div>
        </div>
    </div>
</div> 