export class Contador {
    ID: number = 0
    NOME: string = ''
    CPF_CNPJ: string = ''
    EMAIL: string = ''
    FONE: string = ''
    CRC: string = ''
    ENDERECO: string = ''
    COMPLEMENTO: string = ''
    NUMERO: string = ''
    BAIRRO: string = ''
    CEP: string = ''
    CIDADE: string = ''
    UF: string = ''

    static mascaras() {
        return ['FONE', 'CEP', 'CPF_CNPJ']
    }
}
