import {Validators} from "@angular/forms";

export class LinkAjuda {
    Id: number = 0
    Descricao: string = ''
    Texto: string = ''
    Link: string = ''
    TipoConteudo: string = ''
    Classificacao: string = ''
    IdRotina: number= 0

    static validacoes() {
        return [
            {campo: 'Descricao', nome: 'Descricao', validation: Validators.compose([Validators.required])},
            {campo: 'Texto', nome: 'Texto', validation: Validators.compose([Validators.required])},
            {campo: 'Link', nome: 'Link', validation: Validators.compose([Validators.required])},
            {campo: 'TipoConteudo', nome: 'TipoConteudo', validation: Validators.compose([Validators.required])},
            {campo: 'Classificacao', nome: 'Classificação', validation: Validators.compose([Validators.required])},
            {campo: 'IdRotina', nome: 'IdRotina', validation: Validators.compose([Validators.required])},
        ]
    }
}
