
import { getUrlImplante } from './../../controller/staticValues';
import { BaseForm } from './../../controller/BaseForm';
import { Formulario } from './../../controller/Formulario';
import { Versionamento } from './../../model/cadastro/versionamento.model';
import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { NetworkService } from "../../services/network.service";
import { DadosDefaultService } from "../../services/dados-default.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { Subscription } from "rxjs";
import { Util } from "../../controller/Util";

@Component({
    selector: 'app-modal-versionamento-cadastro',
    templateUrl: './modal-versionamento-cadastro.component.html',
    styleUrls: ['./modal-versionamento-cadastro.component.css']
})
export class modalVersionamentoCadastroComponent extends BaseForm implements OnInit, OnDestroy {

    entidade = new Versionamento()
    id;
    form: FormGroup;
    @Input() data;
    @Input() modalVisible = false;
    @Output() closeModal = new EventEmitter()
    selectContaCaixa = []


    $subscription1: Subscription;
    $subscription2: Subscription;

    selectSolucao = []

    exibirLoader = this.dadosDefault.exibirLoader
    exibirLoaderNetwork = this.networkService.exibirLoader

    constructor(public networkService: NetworkService, public dadosDefault: DadosDefaultService, public router: Router, private route: ActivatedRoute, private fb: FormBuilder, public messageService: MessageService) {
        super(networkService, dadosDefault, router, 'Versionamento', messageService);
        this.form = Formulario.createForm(this.entidade, this.fb);


    }

    ngOnInit() {
        // this.networkService.getSimples(getUrlImplante(), 'Solucao').pipe(map((res: Response) => res['value'])).subscribe((v: any) => {
        //     this.selectSolucao = v.map((valor: any) => ({ label: Util.up(valor.NOME), value: valor.ID }))
        // })

    }

    fecharModal() {

    }

    public processarFormulario() {

    }


    isSingle() {
        return !Array.isArray(this.data)
    }


    ngOnDestroy() {
        super.ngOnDestroy();
        if (this.$subscription1) this.$subscription1.unsubscribe()
        if (this.$subscription2) this.$subscription2.unsubscribe()
    }
}
