import { Component, OnInit } from '@angular/core';
import {ConfirmationService, MessageService} from "primeng/api";
import {NetworkService} from "../../services/network.service";
import {Router} from "@angular/router";
import {URL_BASE, qtdLinhas, opcoesLinhas} from "../../controller/staticValues";
import {DadosDefaultService} from "../../services/dados-default.service";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-ncm',
  templateUrl: './ncm.component.html',
  styleUrls: ['./ncm.component.css']
})
export class NcmComponent implements OnInit {

    public first: number = 0
    public top: number = qtdLinhas()
    qtdLinhas = qtdLinhas();
    public opcoesLinha = opcoesLinhas()
    public totalItens: number
    public lista: any[] = []
    public dadosFixos: any[] = []
    filtro = '';

    opcoesTable = [
        {label: 'Editar', icon: 'fa fa-plus', command: (e) => {
            this.router.navigate([`editar-ncm/${e.Id}`])
        }},
        {label: 'Incluir', icon: 'fa fa-plus', command: (e) => {
            this.router.navigate(['incluir-ncm'])
        }},
    ]

    constructor(private networkService: NetworkService, private router: Router, private messageService: MessageService, private dadosDefaultService: DadosDefaultService) {

    }

    ngOnInit() {
        this.networkService.exibirLoader.next(true);
        this.networkService.getSimples(URL_BASE, `Ncm?$inlinecount=allpages&$top=0`).subscribe((itens: any) => {
            this.totalItens = itens["@xdata.count"];
            this.networkService.getSimples(URL_BASE, 'Ncm?$top=10&$skip=0').subscribe((v: any) => {
                this.lista = v.value
                this.dadosFixos = v.value
            })
        }).add(() => this.networkService.exibirLoader.next(false))
    }

    filtrar(){
        this.networkService.getSimples(URL_BASE, `Ncm?$top=10&$skip=0&$filter=(contains(lower(Ncm), lower('${this.filtro}')) or contains(lower(Descricao), lower('${this.filtro}')))`).subscribe((v: any) => {
            this.lista = v.value
        })
    }

    get usuarios() {
        return this.lista.filter(v => {
            if (v.Descricao === null) {
                v.Descricao = ''
            }
            if (v.Ncm === null) {
                v.Ncm = ''
            }
            return  v.Descricao.toLowerCase().includes(this.filtro.toLowerCase()) || v.Ncm.toLowerCase().includes(this.filtro.toLowerCase())
        })
    }

    paginate(event){
        this.networkService.getSimples(URL_BASE, `Ncm?$top=${event.rows}&$skip=${event.first}`).subscribe((v: any) => {
            this.lista = v.value
            this.dadosFixos = v.value
        })
    }

    incluir(){
        this.router.navigate(['incluir-ncm'])
    }
}
