<div class="p-grid">
    <div style="margin-top: 30px;">
        <div class="p-col-12">
            <div style="display: flex;justify-content: space-between;">
                <h1 style="margin: 0xp;font-size: 24px;">Lista de Versões</h1>
                <button style="width: 125px;margin-left: 15px;height: 30px;background-color: #91c02d;border: 0px;"
                    pButton pRipple type="button" label="Incluir" class="p-button-success" (click)="incluir()"></button>
            </div>
            <div class="p-col-12" style="margin-top: 5px">
                <label>Filtrar</label><br>
                <div class="p-grid">
                    <div class="p-col-5 p-inputgroup">
                        <input type="text" pInputText size="30" placeholder="Filtrar Versão" style="width:auto"
                            [(ngModel)]="filtro" #inputPesquisa>
                        <button style="width: 40px;" pButton type="button" icon="fa fa-search" (click)="carregarLista()"></button>
                    </div>
                    <div class="p-grid p-col-5" style="margin-top: 8px; margin-left: 25px;">
                        <div class="p-col-4">
                            <app-date #dataInicial [(ngModel)]="dataInit"></app-date>
                        </div>
                        <div class="p-col-4">
                            <app-date #dataFinal [(ngModel)]="dataFim"></app-date>
                        </div>
                    </div>
                    <div class="p-col-2" style="margin-top: 15px; margin-left: -60px;">
                        <app-date-navigator (alterouData)="alterouData($event)"></app-date-navigator>
                    </div>
                </div>
            </div>
            <!-- <div class="p-col-6" style="margin-bottom: 5px;">
                <input la class="p-col-5" pInputText [(ngModel)]="filtro">
                <button class="p-col-1" pButton icon="fa fa-search"></button>
            </div> -->

            
            <p-table [paginator]="true" [rows]="qtdLinhas || 7" [totalRecords]="versao.length" [value]="versao"
                [lazy]="false">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th [ngStyle]="{width: '29%'}">Descrição</th>
                        <th [ngStyle]="{width: '29%'}">Data</th>
                        <th [ngStyle]="{width: '29%'}">Versão</th>
                        <th [ngStyle]="{width: '29%'}">Obrigatória</th>
                        <th [ngStyle]="{width: '12%'}">Ações</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td>{{rowData.Descricao}}</td>
                        <td>{{rowData.Data | data}}</td>
                        <td>{{rowData.VersaoNumero}}</td>
                        <td>{{rowData.Obrigatoria}}</td>
                        <td>
                            <app-opcoes-table [value]="rowData" [opcoes]="opcoesTable"></app-opcoes-table>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<app-modal-versionamento-cadastro [modalVisible]="modalVisible" [data]="null" (closeModal)="modalVisible = false">
</app-modal-versionamento-cadastro>