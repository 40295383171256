<div class="p-grid">
    <div  style="margin-top: 30px;">
        <div class="p-col-10 p-offset-1">
            <div style="display: flex;justify-content: space-between;">
                <h1 style="margin: 0xp;font-size: 24px;">Contador</h1>
            </div>
            <div>Pesquisar Contador</div>
            <div style="display: flex;justify-content: space-between;">
                <div class="p-col-6" style="margin-bottom: 5px;display: flex;justify-self: start;padding: 0px;">
                    <input class="p-col-11" pInputText (keydown.enter)="filtrar()" [(ngModel)]="filtro">
                    <button class="p-col-1" pButton icon="fa fa-search" (click)="filtrar()"></button>
                </div>
                <button style="width: 125px;height: 30px;background-color: #91c02d;border: 0px;" pButton pRipple type="button" label="Incluir" class="p-button-success" (click)="incluir()"></button>
            </div>
            <p-table (onPage)="paginate($event)" [paginator]="true" [rowsPerPageOptions]="opcoesLinha" [(rows)]="qtdLinhas" [totalRecords]="totalItens" [value]="lista" [lazy]="true">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th [ngStyle]="{width: '70%'}">Nome</th>
                        <th [ngStyle]="{width: '20%'}">Cpf</th>
                        <th [ngStyle]="{width: '10%'}">Ações</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td>{{rowData.NOME}}</td>
                        <td appCpfCnpjMask>{{rowData.CPF_CNPJ | cpfCnpj}}</td>
                        <td>
                            <app-opcoes-table [value]="rowData"
                                            [opcoes]="opcoesTable"></app-opcoes-table>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
