<div [ngClass]="{'center-content': center}" style="color: #000;">
    <div [ngClass]="{'ui-g-12': row, 'ui-g-11 ui-md-10': icon, 'ui-g-12 ui-md-12': !icon}">
        <div [ngClass]="gridClass" class="ui-g-nopad">
            <div class="ui-g-nopad" *ngIf="label"><label [attr.for]="id">{{label}}</label></div>
                <p-dropdown [placeholder]="placeholder" [options]="options" [id]="id"
                            filter="true" (onChange)="changeValue($event)" [(ngModel)]="value" [readonly]="isReadOnly" [style]="{backgroundColor: '#fff'}"></p-dropdown>
        </div>
    </div>
</div>
<!--, overflowX: 'hidden'-->
