import {Component, OnDestroy, OnInit} from '@angular/core';
import {NetworkService} from "../../services/network.service";
import {Router} from "@angular/router";
import {
    getUrlImplante,
    opcoesLinhas,
    qtdLinhas,
} from "../../controller/staticValues";
import {MessageService} from "primeng/api";
import {Subscription} from "rxjs";
import {DadosDefaultService} from "../../services/dados-default.service";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-usuario-web',
  templateUrl: './usuario-web.component.html',
  styleUrls: ['./usuario-web.component.css']
})
export class UsuarioWebComponent implements OnInit{

    public first: number = 0
    public top: number = qtdLinhas()
    qtdLinhas = qtdLinhas();
    public opcoesLinha = opcoesLinhas()
    public totalItens: number
    public lista: any[] = []
    public dadosFixos: any[] = []
    usuario;

    modalVisible = false

    $subscription1: Subscription;
    $subscription2: Subscription;

    status: any[] = [
        {label: 'Inativo', value: '0'},
        {label: 'Ativo', value: '1'},
        {label: 'Expirado', value: '2'},
        {label: 'Bloqueado', value: '3'},
        {label: 'Cancelado', value: '4'}
    ];

    opcoesTable = [
        {label: 'Editar', icon: 'fa fa-plus', command: (e) => {
            this.router.navigate([`editar-usuario-web/${e.ID}`]);
        }},
        {label: 'Incluir', icon: 'fa fa-plus', command: (e) => {
            this.router.navigate(['incluir-usuario-web'])
        }},
        {label: 'Desativar', icon: 'fa fa-plus', command: (e) => {}},
    ]
    filtro = '';

    constructor(private networkService: NetworkService, private router: Router, private messageService: MessageService, private dadosDefaultService: DadosDefaultService) {

    }

    ngOnInit() {
        this.networkService.getSimples(getUrlImplante(), 'USUARIO_WEB?$inlinecount=allpages&$top=0').pipe(map((res: Response) => res['@xdata.count'])).subscribe((itens: any) => {
            this.totalItens = itens
            this.networkService.getSimples(getUrlImplante(), 'USUARIO_WEB?$top=10').subscribe((e: any )=> {
                this.lista = e.value
            })
        })
    }

    paginate(event){        
        this.networkService.getSimples(getUrlImplante(), `USUARIO_WEB?$top=${event.rows}&$skip=${event.first}`).subscribe((e: any) => {
            this.lista = e.value
        })
    }

    filtrar(){
        let filtrar = this.filtro.toLowerCase()
        this.networkService.getSimples(getUrlImplante(), `USUARIO_WEB?$filter=(contains(lower(NOME), lower('${filtrar}')) or contains(lower(CPF_CNPJ), lower('${filtrar}')))&$top=10`).subscribe((e: any) => {
            this.lista = e.value;
        })
    }

    incluir(){
        this.router.navigate(['incluir-usuario-web'])
    }
}
