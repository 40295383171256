import {Component, OnDestroy, OnInit} from '@angular/core';
import {NetworkService} from "../../../services/network.service";
import {Banco} from '../../../model/banco.model';
import {ActivatedRoute ,Router} from "@angular/router";
import {getUrlImplante,URL_BASE} from "../../../controller/staticValues";
import {MessageService} from "primeng/api";
import {Endereco, ErroCep, NgxViacepService} from "@brunoc/ngx-viacep";
import { Subscription } from 'rxjs'
import {Formulario} from "../../../controller/Formulario";
import {DadosDefaultService} from "../../../services/dados-default.service";
import { FormGroup, FormBuilder } from '@angular/forms';
import { Util } from 'src/app/controller/Util';

@Component({
  selector: 'app-editar-bancos',
  templateUrl: './editar-bancos.component.html',
  styleUrls: ['./editar-bancos.component.css']
})
export class EditarBancosComponent implements OnInit{

  entObj = new Banco()
  public lista: any[] = []
  form: FormGroup;
  usuario;
  id;

  $subscription1: Subscription;
  $subscription2: Subscription;

    constructor(private fb: FormBuilder,public route: ActivatedRoute, private networkService: NetworkService, private router: Router, private messageService: MessageService, private dadosDefaultService: DadosDefaultService) {
      this.form = Formulario.createForm(this.entObj, this.fb)
    }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
          this.id = params.get('id');
            if (this.id) {
              this.$subscription2 = this.networkService.buscar('Banco', this.id, '', URL_BASE).subscribe(value => {
                const data = Formulario.prepareValueToForm(this.entObj, value);
                Object.keys(data).forEach(key => this.form.controls[key].setValue(data[key]))
              })
            }
        })
    }

    processarFormulario(){
      /*let inv = false
      if(this.form.invalid) {
        Object.keys(this.form.controls).forEach(c => {
          if (this.form.get(c).invalid) {
            let v = Cfop.validacoes().filter(x => x.campo === c)
            this.messageService.add(Util.pushErrorMsg(`O campo ${v.length && v[0].nome ? v[0].nome : c} é obrigatorio`))
            inv = true
          }
        })
        if (inv) return
      }*/
      const data = Formulario.parseForm(this.entObj, Object.assign(this.form.value), null, null, null, null, null, null, null);
      this.route.url.subscribe(v => {
        if(v[0].path == 'editar-bancos'){
          this.networkService.atualizar(URL_BASE, 'Banco', data).subscribe(value => {
            this.messageService.add(Util.pushSuccessMsg("Edição feita com sucesso!"))
            this.router.navigate(['bancos'])
          })
        }
        if(v[0].path == 'incluir-bancos'){
          this.networkService.salvarPost(URL_BASE, 'Banco', data).subscribe(value => {
            this.messageService.add(Util.pushSuccessMsg("Cadastro feito com sucesso!"))
            this.router.navigate(['bancos'])
          })
        }
      })
    }

    cancelar(){
      this.router.navigate(['bancos']);
    }
}
