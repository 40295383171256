<div class="p-grid">
    <div  style="margin-top: 30px;">
        <div class="p-col-10 p-offset-1">
            <div style="display: flex;justify-content: space-between;">
                <h1 style="margin: 0xp;font-size: 24px;">Tutoriais</h1>
            </div>
            <div>Pesquisar Tutorial</div>
            <div style="display: flex;justify-content: space-between;">
                <div class="p-col-6" style="margin-bottom: 5px;display: flex;justify-self: start;padding: 0px;">
                    <input class="p-col-11" pInputText [(ngModel)]="filtro" (keydown.enter)="filtrar()">
                    <button class="p-col-1" pButton icon="fa fa-search" (click)="filtrar()"></button>
                </div>
                <button style="width: 125px;height: 30px;background-color: #91c02d;border: 0px;" pButton pRipple type="button" label="Incluir" class="p-button-success" (click)="incluir()"></button>
            </div>
            <p-table (onPage)="paginate($event)" [paginator]="true" [(rows)]="qtdLinhas" [rowsPerPageOptions]="opcoesLinha" [totalRecords]="totalItens" [value]="lista" [lazy]="true">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th [ngStyle]="{width: '40%'}">Descrição</th>
                        <th [ngStyle]="{width: '15%'}">Tipo de Conteudo</th>
                        <th [ngStyle]="{width: '10%'}">Ações</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td>{{rowData.Descricao}}</td>
                        <td>{{rowData.TipoConteudo}}</td>
                        <td>
                            <app-opcoes-table [value]="rowData"
                                            [opcoes]="opcoesTable"></app-opcoes-table>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
