import { Email } from './../../../model/cadastro/email.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NetworkService } from "../../../services/network.service";
import { UsuarioWeb } from '../../../model/usuario-web/usuario-web.model';
import { ActivatedRoute, Router } from "@angular/router";
import { getUrlImplante } from "../../../controller/staticValues";
import { MessageService } from "primeng/api";
import { Endereco, ErroCep, NgxViacepService } from "@brunoc/ngx-viacep";
import { Subscription } from 'rxjs'
import { Formulario } from "../../../controller/Formulario";
import { DadosDefaultService } from "../../../services/dados-default.service";
import { FormGroup, FormBuilder } from '@angular/forms';
import { Util } from 'src/app/controller/Util';


@Component({
  selector: 'app-email-cadastro',
  templateUrl: './email-cadastro.component.html',
  styleUrls: ['./email-cadastro.component.css']
})
export class EmailCadastroComponent implements OnInit {

  //   email: string;
  // text: string;

  entObj = new Email()
  public lista: any[] = []
  form: FormGroup;
  usuario;
  id;

  $subscription1: Subscription;
  $subscription2: Subscription;

//   tipoSelect: any[] = [
//     { label: 'Normal', value: 'N' },
//     { label: 'Suporte', value: 'S' },
//     { label: 'Contador', value: 'C' },
//   ];

  modalVisible = true

  constructor(private fb: FormBuilder, public route: ActivatedRoute, private networkService: NetworkService, private router: Router, private messageService: MessageService, private dadosDefaultService: DadosDefaultService) {
    this.form = Formulario.createForm(this.entObj, this.fb);
    this.form.get('DataEnvio').setValue(new Date)
  }

  ngOnInit() {
    // this.route.paramMap.subscribe(params => {
    //   this.id = params.get('id');
    //   if (this.id) {
    //     // Util.expandedQuery(UsuarioWeb.expanded()
    //     this.networkService.buscar('USUARIO_WEB', this.id, null, getUrlImplante()).subscribe((value: any) => {
    //       const data = Formulario.prepareValueToForm(this.entObj, value, null, null, null, null);
    //       Object.keys(data).forEach(key => this.form.controls[key].setValue(data[key]))
    //     })
    //   }
    // })
  }

//   processarFormulario() {
//     if(this.form.get('SENHA').value.toLowerCase() !== this.confirmarSenha.toLowerCase() || this.confirmarSenha === ''){
//       this.messageService.add(Util.pushErrorMsg(`O campo "Confirmar senha" esta invalido`));
//       return
//     }

//     this.dadosDefaultService.exibirLoader.next(true)
//     this.networkService.getSimples('GravarEmail', 'Email').subscribe(value => {
//       if (value) this.router.navigate(['email-cadastro'])
//     }).add(() => this.dadosDefaultService.exibirLoader.next(false))
//   }

  salvarEmail() {
    const email = { ...Formulario.parseForm(new Email(), Object.assign(this.form.value), null, null, Email.datas(), null, Email.checkbox())};
    this.networkService.exibirLoader.next(true)
    this.networkService.salvarPost(getUrlImplante(), 'adm/GravarEmail', email).subscribe(value => {
      this.router.navigate(['email'])
    }, error1 => {
        this.messageService.add(Util.pushErrorMsg(error1))
    }).add(() => this.networkService.exibirLoader.next(false));
  }

  cancelar() {
    this.router.navigate(['email']);
  }
}
