import {Validators} from "@angular/forms";

export class RotinaToqWeb {
    Id: number = 0;
    Nome: string = '';

    static validacoes() {
        return [
            {campo: 'Nome', nome: 'Nome', validation: Validators.compose([Validators.required])},
        ]
    }
}