export class Email {
    Id: number = 0
    DataCadastro: string = ''
    DataEnvio: string = ''
    Titulo: string = ''
    EnviaCliente: boolean = false
    EnviaUsuario: boolean = false
    IdVersao: number = 0
    EnviaContador: boolean = false
    IdRevenda: number = 0
    Assunto: string = ''
    TextoSimples: string = ''
    ListaRevendas: string = ''
    TextoBlob: string = ''

    static datas() {
        return ['DataCadastro', 'DataEnvio' ]
    }

    static checkbox() {
        return ['EnviaCliente', 'EnviaUsuario', 'EnviaContador']
    }
}
