<div class="container">   
    <div class="p-fluid p-formgrid p-grid container-grid" [formGroup]="form">
        <h1 class="p-field p-col-12 p-md-12">Editar/Incluir CFOP</h1>
        <div class="p-field p-col-12 p-md-12">
            <label for="descricao">Descrição</label>
            <input id="descricao" type="text" formControlName="Descricao" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-12">
            <label for="aplicacao">Aplicação</label>
            <textarea id="aplicacao" formControlName="Aplicacao" pInputTextarea></textarea>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label for="cfop">CFOP</label>
            <input id="cfop" type="number" formControlName="Cfop" pInputText>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label for="origem">Origem</label>
            <app-select [icon]="true" formControlName="Origem" [options]="origem"></app-select>
        </div>
        <div class="p-field p-col-12 p-md-4">
            <label for="tipoOperacao">Tipo Operação</label>
            <app-select [icon]="true" formControlName="TipoOperacao" [options]="tipoOperacao"></app-select>
        </div>
        <div class="p-field p-col-12 p-md-12 container-button" style="margin-bottom: 0px;height: 40px;margin-top: 20px;">
            <button style="width: 150px;height: 40px;background-color: #ee736c;border: 0px;"  pButton pRipple type="button" label="Cancelar" class="p-button-danger" (click)="cancelar()"></button>
            <button style="width: 150px;margin-left: 15px;height: 40px;background-color: #91c02d;border: 0px;" pButton pRipple type="button" label="Salvar" class="p-button-success" (click)="processarFormulario()"></button>
        </div>
    </div>
    <div class="p-fluid p-formgrid p-grid container-grid-2" [formGroup]="form">
        <div class="p-field p-col-12 p-md-6">
            <label>Permitir Crédito Ipi</label>
            <p-toggleButton [style]="{'width':'100%'}" formControlName="PermiteCreditoIpi" [onLabel]="onLabel" [offLabel]="offLabel"></p-toggleButton>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label>Permitir Crédito Icms</label>
            <p-toggleButton [style]="{'width':'100%'}" formControlName="PermiteCreditoIcms" [onLabel]="onLabel" [offLabel]="offLabel"></p-toggleButton>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label>Permitir Crédito Cofins</label>
            <p-toggleButton [style]="{'width':'100%'}" formControlName="PermiteCreditoCofins" [onLabel]="onLabel" [offLabel]="offLabel"></p-toggleButton>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label>Permitir Crédito Pis</label>
            <p-toggleButton [style]="{'width':'100%'}" formControlName="PermiteCreditoPis" [onLabel]="onLabel" [offLabel]="offLabel"></p-toggleButton>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label>Gera Diferencial Aliq</label>
            <p-toggleButton [style]="{'width':'100%'}" formControlName="GeraDiferencialAliq" [onLabel]="onLabel" [offLabel]="offLabel"></p-toggleButton>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label>Ind Nfe</label>
            <p-toggleButton [style]="{'width':'100%'}" formControlName="IndNfe" [onLabel]="onLabel" [offLabel]="offLabel"></p-toggleButton>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label>Ind Transporte</label>
            <p-toggleButton [style]="{'width':'100%'}" formControlName="IndTransporte" [onLabel]="onLabel" [offLabel]="offLabel"></p-toggleButton>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label>Ind Comunicação</label>
            <p-toggleButton [style]="{'width':'100%'}" formControlName="IndComunicacao" [onLabel]="onLabel" [offLabel]="offLabel"></p-toggleButton>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label>Ind Devolução</label>
            <p-toggleButton [style]="{'width':'100%'}" formControlName="IndDevolucao" [onLabel]="onLabel" [offLabel]="offLabel"></p-toggleButton>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label>Ind Combustível</label>
            <p-toggleButton [style]="{'width':'100%'}" formControlName="IndCombustivel" [onLabel]="onLabel" [offLabel]="offLabel"></p-toggleButton>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label>Ind Remessa</label>
            <p-toggleButton [style]="{'width':'100%'}" formControlName="IndRemessa" [onLabel]="onLabel" [offLabel]="offLabel"></p-toggleButton>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label>Ind Retorno</label>
            <p-toggleButton [style]="{'width':'100%'}" formControlName="IndRetorno" [onLabel]="onLabel" [offLabel]="offLabel"></p-toggleButton>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label>Ind Anulação</label>
            <p-toggleButton [style]="{'width':'100%'}" formControlName="IndAnulacao" [onLabel]="onLabel" [offLabel]="offLabel"></p-toggleButton>
        </div>
    </div>
</div> 