<p-dialog [modal]="true" [responsive]="true" [contentStyle]="{'width':'700px', 'overflow':'visible'}"
    [maximizable]="true" [header]="'Cadastro de Rotina'" [(visible)]="modalVisible" (onHide)="fecharModal()">
        <div class="p-grid" [formGroup]="form">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-12">
                    <label for="nome">Nome</label>
                    <input id="nome" formControlName="Nome" type="text" pInputText> 
                </div>
                <div class="p-field p-col-12 p-md-12" style="margin-bottom: 0px;height: 30px;margin-top: 20px;display: flex;">
                    <button style="margin-right: 15px;width: 80px;height: 30px;" pButton pRipple type="button" label="Cancelar" class="p-button-danger" (click)="fecharModal()"></button>
                    <button style="width: 80px;height: 30px;" pButton pRipple type="button" label="Salvar" class="p-button-success" (click)="salvar()"></button>
                </div>
            </div>
        </div>
</p-dialog>

