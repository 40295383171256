export class Cest {
    Id: number = 0
    Descricao: string = ''
    Segmento: string = ''
    ItemSegmento: string = ''
    Especificacao: string = ''
    CodigoCest: string = ''
    Ncms: string = ''
    MvaOriginal: number = 0
}
