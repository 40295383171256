<div class="p-grid">
    <div  style="margin-top: 30px;">
        <div class="p-col-8 p-offset-2">
            <div style="display: flex;justify-content: space-between;">
                <h1 style="margin: 0xp;font-size: 24px;">Cidades</h1>
            </div>
            <div>Pesquisar Cidade</div>
            <div style="display: flex;justify-content: space-between;">
                <div class="p-col-6" style="margin-bottom: 5px;display: flex;justify-self: start;padding: 0px;">
                    <input class="p-col-11" pInputText [(ngModel)]="filtro">
                    <button class="p-col-1" pButton icon="fa fa-search"></button>
                </div>
                <button style="width: 125px;height: 30px;background-color: #91c02d;border: 0px;" pButton pRipple type="button" label="Incluir" class="p-button-success" (click)="incluir()"></button>
            </div>
            <p-table [paginator]="true" [rows]="qtdLinhas || 7" [totalRecords]="usuarios.length" [value]="usuarios" [lazy]="false">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th [ngStyle]="{width: '15%'}">Cidade</th>
                        <th [ngStyle]="{width: '75%'}">Cod Municipio</th>
                        <th [ngStyle]="{width: '10%'}">Codigo Uf</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td>{{rowData.Cidade}}</td>
                        <td>{{rowData.CodMunicipio}}</td>
                        <td>{{rowData.CodigoUf}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
