<div class="p-grid">
    <div  style="margin-top: 30px;">
        <div class="p-col-10 p-offset-1">
            <div>Pesquisar Empresa</div>
            <div style="margin-bottom: 5px;">
                <input class="p-col-11" pInputText [(ngModel)]="filtro" (keydown.enter)="filtrar()">
                <button class="p-col-1" pButton icon="fa fa-search"  (click)="filtrar()"></button>
            </div>
            <p-table [paginator]="true" [rows]="qtdLinhas || 7" (onPage)="paginator($event)" [rowsPerPageOptions]="opcoesLinhas" [totalRecords]="totalItens" [value]="lista" [lazy]="true">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th [ngStyle]="{width: '30%'}" > Empresa</th>
                        <th [ngStyle]="{width: '20%'}" > CNPJ</th>
                        <th [ngStyle]="{width: '23%'}" > Nome Fantasia</th>
                        <th [ngStyle]="{width: '15%'}" > </th>
                        <th [ngStyle]="{width: '12%'}" > Ações</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td>{{rowData.RAZAO_SOCIAL}}</td>
                        <td>{{rowData.CPF_CNPJ | cpfCnpj}}</td>
                        <td>{{rowData.NOME_FANTASIA}}</td>
                        <td><button pButton label="Acessar" (click)="acessarToqWeb(rowData)"></button></td>
                        <td>
                            <app-opcoes-table [value]="rowData"
                                            [opcoes]="opcoesTable"></app-opcoes-table>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
