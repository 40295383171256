<div [ngClass]="{'center-content': center}">
    <div [ngClass]="{'ui-g-12 ui-g-nopad': row}">
        <div [ngClass]="gridClass">
            <label [attr.for]="id">{{label}}</label>
            <div class="ui-inputgroup">
            <input [ngClass]="{'invalid': tentativa}" [type]="type" [id]="id" [placeholder]="placeholder" [readOnly]="!!isReadOnly" [(ngModel)]="value"
                  (blur)="onBlur.emit($event)" (keypress)="onKeypress.emit($event)" autocomplete="off" pInputText/>
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>
