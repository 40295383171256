import {Directive, ElementRef, OnInit} from '@angular/core'
import {fromEvent} from "rxjs";

@Directive({
    selector: '[appCpfCnpjMask]'
})
export class CpfCnpjMaskDirective implements OnInit {

    $aplicarMascara: any
    $validaDigito: any

    constructor(private el: ElementRef) {
    }

    ngOnInit(): void {
        this.$aplicarMascara = fromEvent(this.el.nativeElement, 'blur')
        this.$validaDigito = fromEvent(this.el.nativeElement, 'keypress')

        this.$aplicarMascara.subscribe(e => {
            let value = this.el.nativeElement.value.toString()
            if (value.length) value.match(/\d/g).length === 11 ? this.aplicarMascaraCpf() : value.match(/\d/g).length === 14 ? this.aplicarMascaraCnpj() : console.log('nada')
        })
        this.$validaDigito.subscribe(e => {
            if (!e.key.toString().match(/\d|\.|-|\//)) e.preventDefault()
        })
    }

    aplicarMascaraCpf() {
        let loc = this.el.nativeElement.value
        loc = loc.toString().match(/[0-9]/g)
        let data = loc
        this.el.nativeElement.value = `${data.slice(0, 3).join('')}.${data.slice(3, 6).join('')}.${data.slice(6, 9).join('')}-${data.slice(9, 11).join('')}`
    }

    aplicarMascaraCnpj() {
        let loc = this.el.nativeElement.value
        loc = loc.toString().match(/[0-9]/g)
        let data = loc
        this.el.nativeElement.value = `${data.slice(0, 2).join('')}.${data.slice(2, 5).join('')}.${data.slice(5, 8).join('')}/${data.slice(8, 12).join('')}-${data.slice(12, 14).join('')}`
    }


}
