export class Banco {
    public Id: number = 0
    public Nome: string = ''
    public Codigo: string = ''
    public Url: string = ''

    constructor() {
    }

    static listUrl() {
        return 'bancointerface/consultabanco'
    }

    static deleteUrl() {
        return 'bancointerface/excluirbanco'
    }

    static salveUrl() {
        return 'bancointerface/gravarbanco';
    }
}
